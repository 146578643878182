angular.module('deitz').controller('depositionsInsightsController', [
    '$scope','$state','$rootScope','$http','apiUrl','Notification','commonFactory', 'apiUrl', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$window','$filter','$uibModal',
    function(
        $scope,$state,$rootScope,$http,apiUrl,Notification,commonFactory, apiUrl, DTOptionsBuilder, DTColumnBuilder, $compile, $window,$filter, $uibModal,
    ){

    $scope.dtInstance = {};
    $scope.submitDate = '';
    $scope.title = "Deposition Insights Summary Dashboard";   
    $scope.todayunits = '0';
    $scope.todayrevenue = '0';

    $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
    };

    $scope.reloadData = function() {    
        var table = $('#deposition_insights_list').DataTable();
        table.ajax.reload();
    };
    $scope.changeDate = function (model,value) {
        $scope.submitDate = moment(value).format('YYYY-MM-DD');
        var table = $('#deposition_insights_list').DataTable();
        table.ajax.reload();
    };


    $scope.dtOptions = DTOptionsBuilder.newOptions()
        // .withOption('stateSave', false)
        .withDataProp('data')
        .withOption('processing', true)
        .withOption('serverSide', true)
        .withFnServerData(function(sSource, aoData, fnCallback, oSettings) {
            var options = {
                skip: aoData[3].value,
                limit: aoData[4].value,
                order: aoData[2].value,
                search: aoData[5].value,
                status:  $scope.status ? $scope.status : false,
                submitDate: $scope.submitDate,
            };
            $rootScope.showLoader(true);
            commonFactory.getWithPaginate('/api/deposition-insights-data', options)
                .success(function(response) {
                    if (response) {
                        $rootScope.showLoader(false);
                        fnCallback({
                            recordsTotal: response.recordsTotal,
                            recordsFiltered: response.count,
                            data: response.result
                        });
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
        })
        .withPaginationType('simple_numbers')
        .withOption('lengthMenu', [
            [10, 15, 20, 25, 50, 100],
            [10, 15, 20, 25, 50, 100]
        ])
        .withOption('createdRow', $scope.compileDatatable)
        .withOption('fnPreDrawCallback', function(settings) {
            $scope.inProgress = true;
        }).withOption('fnDrawCallback', function() {
            $scope.inProgress = false;
        }).withOption('order', [[3, 'desc']]);

    $scope.dtColumns = [
        
        DTColumnBuilder.newColumn('job_document_no').withOption('defaultContent', '').withTitle('Lexitas ID').renderWith(function(data, type, full) {
            if (full.job_document_no) {
               return 'OD'+full.job_document_no;
            } 
        }),
        DTColumnBuilder.newColumn('AMT').withOption('defaultContent', '').withTitle('Revenue').renderWith(function(data, type, full) {
            if (full.AMT) {
                return '$' + $filter('number')(full.AMT, 2);
            } else {
                return '$' + $filter('number')(0, 2);
            }
        }),,
        DTColumnBuilder.newColumn('job_num').withOption('defaultContent', '').withTitle('Job ID').renderWith(function(data, type, full) {
            if (full.document_filename) {
                // Extract the part after the last '/' and remove the extension
                var filename = full.document_filename.split('/').pop().split('.')[0];
                return filename;
            } else {
                return full.job_num;
            }
        }),
        DTColumnBuilder.newColumn('created_date').withOption('defaultContent', '').withTitle('Submitted').renderWith(function(data, type, full) {
            if (full.created_date) {
                return moment(full.created_date).format('MM/DD/YYYY hh:mm A');
            } else {
                return '';
            }
        }),
        DTColumnBuilder.newColumn('received_date').withOption('defaultContent', '').withTitle('Received').renderWith(function(data, type, full) {
            if (full.received_date) {
                return moment(full.received_date_ny).format('MM/DD/YYYY hh:mm A');
            } else {
                return '';
            }
        }),
        DTColumnBuilder.newColumn('business_unit').withOption('defaultContent', '').withTitle('Business Unit'),
        DTColumnBuilder.newColumn(null).withTitle('Status').notSortable().renderWith(function(data, type, full) {
            return full.received_date ? '<button class="btn btn-success"> Completed </button>' : '<button class="btn btn-danger"> Processing </button>';
        }),
        DTColumnBuilder.newColumn(null).withTitle('Actions').notSortable()
        .renderWith(function(data, type, full, meta) {
            if (data && data.id) {
                var host = $rootScope.depoHost;
                var auth_user = $rootScope.auth_user;
                var send_mail = true;
                if (full.mail_send_at) {
                    var mailSendAt = moment(full.mail_send_at);
                    var currentDateTime = moment();
                    if (currentDateTime.diff(mailSendAt, 'hours') < 24) {
                        send_mail = false;
                    }
                }
                return  '<div class="oursuit-popover">'+
                            '<button class="btn btn-secondary btn-circle" uib-tooltip="Resend" ui-sref="" ng-click="update(' + full.id + ','+ full.summary +')" >' +
                                '<i class="fa fa-repeat"></i>' +
                            '</button> &nbsp;<button class="btn btn-info btn-circle" uib-tooltip="Invoice" ui-sref=""  ng-click="showJobInvoices('+ full.invoiceID +')" >' +
                                '<i class="fa fa-file"></i>' +
                            '</button> &nbsp;'+
                            ( full.received_date ? '<a class="btn btn-warning btn-circle" uib-tooltip="Depositions Insights" href="'+ host +'/autologin.php?userid='+auth_user.email+'&password='+auth_user.depo_psd+'&script=serve_repository.php?document_no='+full.summary+'" target="_blank" >' +
                                '<i class="fa fa-download"></i></a>&nbsp;'  : '' )+
                            ( full.received_date ? '<a class="btn btn-success btn-circle" uib-tooltip="ASCII txt" href="'+ host +'/autologin.php?userid='+auth_user.email+'&password='+auth_user.depo_psd+'&script=serve_repository.php?document_no='+full.txt+'" target="_blank" >' +
                                    '<i class="fa fa-file-text"></i></a>&nbsp;'  : '' )+
                            ( full.received_date ? '<a class="btn btn-danger btn-circle" uib-tooltip="Transcript PDF" href="'+ host +'/autologin.php?userid='+auth_user.email+'&password='+auth_user.depo_psd+'&script=serve_repository.php?document_no='+full.pdf+'" target="_blank" >' +
                                '<i class="fa fa-file-pdf-o"></i></a>&nbsp;'  : '' )+
                            ( full.received_date ? '' :  (send_mail ? '<button class="btn btn-primary btn-circle" uib-tooltip="Send Email" ui-sref="" ng-click="email('+ full.id +',\'OD' + full.job_document_no +'\')" >' +
                                '<i class="fa fa-envelope"></i></button>' : '<button class="btn btn-primary btn-circle" disabled uib-tooltip="Send Email" ui-sref="" ng-click="email('+ full.id +',\'OD' + full.job_document_no +'\')" >' +
                                '<i class="fa fa-envelope"></i></button>') )+                                    
                        '</div>';
            }
        }),
    ];
    $scope.update = function (id, summary) {
        var page = $('#deposition_insights_list').DataTable().page();
        swal({
            title: "Alert!",
            text: "ARE YOU SURE YOU WISH TO DELETE?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#09375f",
            confirmButtonText: "Yes",
            cancelButtonText: "No!",
            closeOnConfirm: true,
            closeOnCancel: true
        },
        function (isConfirm) {
            if (isConfirm) {
                $rootScope.showLoader(true);
                var data = {id : id, summary:summary };

                commonFactory.post('/api/update-deposition-insights-data', data).success(function(res){
                    $rootScope.showLoader(false); 
                    if(res.error){
                        Notification.error(res.result.message);    
                    }else{
                        Notification.success(res.result.message);
                    }
                    $('#deposition_insights_list').DataTable().page(page).draw(false);
                }).error(function (err) {
                    $rootScope.showLoader(false);
                    Notification.error(err.result.message);
                    $('#deposition_insights_list').DataTable().page(page).draw(false);
                });
            }
        });

    };
    $scope.email = function(id, lexitasId){
        var page = $('#deposition_insights_list').DataTable().page();
        swal({
            title: "Alert!",
            text: "Are you sure you want to submit an issue request email?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#09375f",
            confirmButtonText: "Yes",
            cancelButtonText: "No!",
            closeOnConfirm: true,
            closeOnCancel: true
        },
        function (isConfirm) {
            if (isConfirm) {
                $rootScope.showLoader(true);
                var data = {id : id, lexitasId : lexitasId };
                commonFactory.post('/api/send-email-deposition-insights', data).success(function(res){
                    $rootScope.showLoader(false); 
                    if(res.error){
                        Notification.error(res.result.message);    
                    }else{
                        Notification.success(res.result.message);
                    }
                    $('#deposition_insights_list').DataTable().page(page).draw(false);
                }).error(function (err) {
                    $rootScope.showLoader(false);
                    Notification.error(err.result.message);
                    $('#deposition_insights_list').DataTable().page(page).draw(false);
                });
            }
        });
    };
    $scope.showJobInvoices = function(invoiceID){  
        $state.go('invoice.edit', {invoice_id: invoiceID});
    }
    var metricsData = { 
        download: false, 
        filter: new Date().toISOString().split('T')[0] // today's date in 'YYYY-MM-DD' format
    };
    
    // Function to get the metrics data and handle the response
    function getMetricsData(downloadFlag = false, filterdate = new Date().toISOString().split('T')[0]) {
        $rootScope.showLoader(true);
        metricsData.download = downloadFlag;
        metricsData.filter = filterdate;

        commonFactory.post('/api/deposition-insights-metrics', metricsData).success(function(res){
                         
            // Handle Excel download if the download flag is true
            if (downloadFlag) {
                var url= res.result.path;
                var save = document.createElement('a');
                save.href = url;
                save.target = '_blank';
                save.download = res.result.name;
                var event = document.createEvent('Event');
                event.initEvent('click', true, true);
                save.dispatchEvent(event);
                save.click()
                window.URL.revokeObjectURL(url);

            }else{ 

                // Update scope variables with the API response
                $scope.todayunits = res.result.units;
                $scope.todayrevenue = $filter('number')(res.result.revenue, 2) ;
                $scope.todaycosts = $filter('number')(res.result.costs, 2);
                $scope.todayprofits = $filter('number')(res.result.profits, 2) 
                //difference data
                $scope.difference_units = res.result.units - res.result.yesterdayUnits;
                $scope.difference_revenue = $filter('number')(res.result.revenue - res.result.yesterdayRevenue, 2) ;
                $scope.difference_costs = $filter('number')(res.result.costs - res.result.yesterdayCosts, 2);
                $scope.difference_profits = $filter('number')(res.result.profits - res.result.yesterdayProfits, 2) 

                // Percentage difference calculations
                $scope.percentage_difference_units = $filter('number')((($scope.difference_units / res.result.yesterdayUnits) * 100 ), 1 );
                $scope.percentage_difference_revenue = $filter('number')((($scope.difference_revenue / res.result.yesterdayRevenue) * 100 ),1 );
                $scope.percentage_difference_costs = $filter('number')(( ($scope.difference_costs / res.result.yesterdayCosts) * 100 ), 1 );
                $scope.percentage_difference_profits = $filter('number')( ( ($scope.difference_profits / res.result.yesterdayProfits) * 100 ), 1 );
                
                // Update graph data
                $scope.x_axis = res.result.graph.x_axis;
                $scope.revenue = res.result.graph.revenue;
                $scope.cost = res.result.graph.cost;
            }
            $rootScope.showLoader(false);
        }).error(function (err) {
            $rootScope.showLoader(false);
            console.error(err);
        });
    };
    // Initial call to get metrics data without download
    getMetricsData();

    // Function to open the modal
    $scope.metrics = function () {
        $rootScope.showLoader(true);
        
        var modalInstance = $uibModal.open({
            templateUrl: 'modules/code_tables/depositions_insights/metrics.html',
            controller: function($scope, $uibModalInstance, $timeout, todayunits, todayrevenue, todaycosts, todayprofits, difference_units, difference_revenue, difference_costs, difference_profits, percentage_difference_units, percentage_difference_revenue, percentage_difference_costs, percentage_difference_profits, x_axis, revenue, cost) {
                // Initialize modal scope variables
                $scope.todayunits = todayunits;
                $scope.todayrevenue = todayrevenue;
                $scope.todaycosts = todaycosts;
                $scope.todayprofits = todayprofits;
                $scope.difference_units =  difference_units;
                $scope.difference_revenue =  difference_revenue;
                $scope.difference_costs =  difference_costs;
                $scope.difference_profits =  difference_profits;
                $scope.percentage_difference_units = percentage_difference_units;
                $scope.percentage_difference_revenue = percentage_difference_revenue;
                $scope.percentage_difference_costs = percentage_difference_costs;
                $scope.percentage_difference_profits = percentage_difference_profits;
                $scope.x_axis = x_axis;
                $scope.revenue = revenue;
                $scope.cost = cost;
                            
                // Modal-specific scope variables and functions
                $scope.closeModal = function () {
                    $uibModalInstance.close();
                }; 
                
                // Function to open month/year picker modal
                $scope.openMonthYearPicker = function () {
                    var modalInstance = $uibModal.open({
                        templateUrl: 'modules/code_tables/depositions_insights/monthYearPicker.html',
                        controller: function ($scope, $uibModalInstance) {
                            
                            $scope.ok = function () {
                                
                                if ($scope.selectedMonthYear) {
                                    $('#btnDownload').text('Downloading...');
                                    $('#btnDownload').prop('disabled', true);  
                                    var selectedDate = moment($scope.selectedMonthYear).format('YYYY-MM');
                                    getMetricsData(true, selectedDate);
                                    $uibModalInstance.close();
                                } else {
                                    alert('Please select a month and year.');
                                }
                            };
                            $scope.cancel = function () {
                                $uibModalInstance.dismiss('cancel');
                            };
                        },
                        size: 'md'
                    });
                };   
                //For Graph             
                $timeout(function() {
                    var colorObj = {
                        'Revenue': '#5DB3B6',
                        'Cost': '#104A7B' 
                    };
                    var chart = c3.generate({ 
                        bindto: '#top-ten-firm', 
                        data: {
                            x: 'x',
                            columns: [
                                $scope.x_axis,
                                $scope.revenue,
                                $scope.cost
                            ],
                            type: 'bar',
                            colors: colorObj
                        },
                        tooltip: {
                            contents: function (data) {  
                                var index = data[0].index;
                                return '<div style="background:#EBEBEB;width:auto;min-width:150px;color:black;">' +
                                    '<table class="table" style="padding:12px;">' +
                                    '<tr><td style="display:flex"><div style="width:5px; height:5px; background: #5DB3B6; border-radius: 5px; margin: auto 8px; "></div>  Revenue</td><td>$' + $scope.revenue[index + 1] + '</td></tr>' +
                                    '<tr><td style="display:flex"><div style="width:5px; height:5px; background: #104A7B; border-radius: 5px; margin: auto 8px; "></div>  Cost</td><td>$' + $scope.cost[index + 1] + '</td></tr>' +
                                    '</table>' +
                                    '</div>';           
                            }  
                        },
                        legend: { 
                            show: true,
                            position: 'right',
                            inset: { 
                                anchor: 'top',
                                x: 5,  
                                y: 15, 
                                step: 1
                            }
                        },
                        bar: {
                            width: {
                                ratio: 0.9
                            } 
                        },
                        axis: {
                            x: {
                                type: 'category',
                                tick: {
                                    rotate: 75,
                                    multiline: false
                                },
                                height: 130
                            }
                        },
                        size: {
                            height: 450,
                            width: 900,
                        } 
                    });
                },100);
            },
            resolve: {
                todayunits: function() {
                    return $scope.todayunits;
                },
                todayrevenue: function() {
                    return $scope.todayrevenue;
                },
                todaycosts: function() {
                    return $scope.todaycosts;
                },
                todayprofits: function() {
                    return $scope.todayprofits;
                },
                difference_units :  function() {
                    return $scope.difference_units;
                },
                difference_revenue:  function() {
                    return $scope.difference_revenue;
                },
                difference_costs:  function() {
                    return $scope.difference_costs;
                },
                difference_profits:  function() {
                    return $scope.difference_profits;
                },
                percentage_difference_units : function() {
                    return $scope.percentage_difference_units;
                },
                percentage_difference_revenue : function() {
                    return $scope.percentage_difference_revenue;
                },
                percentage_difference_costs : function() {
                    return $scope.percentage_difference_costs;
                },
                percentage_difference_profits : function() {
                    return $scope.percentage_difference_profits;
                },
                x_axis: function() {
                    return $scope.x_axis;
                },
                revenue: function() {
                    return $scope.revenue;
                },
                cost: function() {
                    return $scope.cost;
                }
            },  
            size: 'lg',
            backdrop: false,
            keyboard: false
        });

        modalInstance.opened.then(function () {
            $rootScope.showLoader(false);
        });
    };
    
}]);
