angular.module('deitz')
    .controller('addattorneytofirmController', [
        '$scope',
        '$rootScope',
        'attorneyFactory',
        'commonFactory',
        'Notification',
        '$http',
        'apiUrl',
        '$state',
        '$timeout',
        'attorneyFirmId',
        '$uibModalInstance',
        'email',
        function(
            $scope,
            $rootScope,
            attorneyFactory,
            commonFactory,
            Notification,
            $http,
            apiUrl,
            $state,
            $timeout,
            attorneyFirmId,
            $uibModalInstance,
            email) {

        $scope.attorneydata = {};
        $scope.attorneyoffice = [];
        $scope.btn_name = "Add"; 
        $scope.title = $state.current.data.pageTitle; 
        $scope.validations = attorneyFactory.validations;
        $scope.messages = attorneyFactory.messages;
        $scope.formSubmit = false;
        $scope.title = 'Add Attorney';
        $scope.attorneydata.Email_Address = email ? email : '';
        $scope.isFirmSelected = false;
        $scope.attorneydata.attorney_type = 'AO';


        $scope.attorneydata.send_job_confirmation = 'N';
        $scope.attorneydata.scheduled_by = 'N';
        if (($state.params && $state.params.from && $state.params.from == 'diary')) {
            $scope.attorneydata.scheduled_by = 'Y';
        }
        $scope.attorneydata.send_job_confirmation1 = 'N';
        $scope.attorneydata.send_job_confirmation_to = 'single';
        $scope.attorneydata.view_invoice = 'N';
        $scope.attorneydata.see_pending = 'N';
        $scope.attorneydata.allow_notification = 'N';
        $scope.attorneydata.auto_attach = 'N';
        $scope.attorneydata.auto_attach_invoice = 'N';
        $scope.attorneydata.view_statement = 'N';
        $scope.attorneydata.pay_cc = 'N';
        $scope.attorneydata.pay_check = 'N';



        commonFactory.get('/api/get-attorney-type')
            .success(function (response) {
                $scope.attorney_type = response.result.attorneyType || '';
                $rootScope.showLoader(false);
            })
            .error(function (err) {
                $rootScope.showLoader(false);
            });

        if (($state.params && $state.params.firm_id) || attorneyFirmId) {
            $timeout(function () {
                var data = {attorney_id: attorneyFirmId || $state.params.firm_id};
                commonFactory.post('/api/getAttorneyfirmdata', data)
                    .success(function (response) {
                        $scope.attorneydata.attorney_id = response.result.attorney_data || '';


                        $rootScope.showLoader(false);
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }, 100);
        }else{
            $rootScope.showLoader(false);
        }

        //Getting Attorney Firm office_code
        $scope.attorneyofficedata = function(val) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: val
                }
            }).then(function(response) {
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        
        
        //exist email in other firm
        $scope.emailExists = false;
        $scope.existingEmails = [];

            
        $scope.checkEmailExistence = function() {        
            var data = {};
            data.Email_Address = $scope.attorneydata.Email_Address;
            if ( data.Email_Address) {               
                $rootScope.showLoader(true);   
                commonFactory.post('/api/addattorneytofirm_emailexist', data)
                .success(function(res) {
                    $rootScope.showLoader(false);                   
                    if (res.status == 200 && res.result && res.result.Email_Address) {           
                        $scope.emailExists = true;
                        $scope.existingEmails = res.result.Email_Address;
                    } else {
                        $scope.emailExists = false;
                        $scope.existingEmails = [];
                    }
                })  
                .error(function(err) {
                    $rootScope.showLoader(false);
                    var message = res.result.message;
                    Notification.error(message);
                });
            }
        };

        //Add Attorney to Firm
        $scope.addAttorneyToFirmSubmit = function(isValid) {

            $scope.formSubmit = true;
            if (isValid) {
                $rootScope.showLoader(true);
                var data = {};

                if($scope.attorneydata.middlename && $scope.attorneydata.middlename != null){
                    data.name = $scope.attorneydata.firstname + ' ' + $scope.attorneydata.middlename + ' ' + $scope.attorneydata.lastname;
                }
                else{
                    data.name = $scope.attorneydata.firstname + ' ' + $scope.attorneydata.lastname;
                }
                data.attorney_id = $scope.attorneydata.attorney_id ? $scope.attorneydata.attorney_id.id : '';
                data.ATTORNEY = $scope.attorneydata.attorney_id ? $scope.attorneydata.attorney_id.NAME_KEY : '';
                data.Email_Address = $scope.attorneydata.Email_Address;
                data.attorney_type = $scope.attorneydata.attorney_type;              

                

                commonFactory.post('/api/addattorneytofirm', data)
                    .success(function(res) {
                        $rootScope.showLoader(false);
                        if (res.status == 200 && res.result && res.result.attorney) {
                            $scope.attorneydata = res.result.attorney;
                            Notification.success("Attorney successfully Added into Attorney Firm.");
                            if (($state.params && $state.params.from && $state.params.from == 'firms')) {
                                $state.go('firms.manage.ebt', { id: $state.params.firm_id });
                            }else if ($state.params && $state.params.firm_id){
                                $state.go("attorney.browse",{ firmId: $state.params.firm_id });
                            }else if(angular.equals({}, $uibModalInstance)) {
                                $state.go("attorney.browse");
                            }else{
                                $uibModalInstance.close($scope.attorneydata);
                            }
                        }else{
                            if(res.result.trashed==1){
                                swal({
                                    title: "Are you sure ?",
                                    text: "Are you sure want to recreate?",
                                    type: "success",
                                    showCancelButton: true,
                                    confirmButtonColor: "#09375f",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "No",
                                    closeOnConfirm: true,
                                    closeOnCancel: true
                                },
                                function (isConfirm) {                                   
                                    if (isConfirm) {                                        
                                        $rootScope.showLoader(true);
                                        att_id = res.result.att_id;                                        
                                        data.trashed = res.result.trashed;
                                        att_email = res.result.att_email;
                                        commonFactory.post('/api/updateattorney_att_edit/' + att_id, data)
                                        .success(function(response) {
                                            $rootScope.showLoader(false);
                                            if (response) {
                                                if (response.status == 200) {
                                                    Notification.success("Attorney updated successfully");
                                                    if (($state.params && $state.params.from && $state.params.from == 'firms')) {
                                                        $state.go('firms.manage.ebt', { id: $state.params.firm_id });
                                                    }else if ($state.params && $state.params.firm_id){
                                                        $state.go("attorney.browse",{ firmId: $state.params.firm_id });
                                                    }else if(angular.equals({}, $uibModalInstance)) {
                                                        $state.go("attorney.browse");
                                                    }else{
                                                        $uibModalInstance.close($scope.attorneydata);
                                                    }
                                                }else{
                                                    Notification.error(response.result.message);
                                                }
                                            }
                                        })
                                        .error(function(err) {
                                            $rootScope.showLoader(false);
                                        });
                                        
                                    }else{                                       
                                        Notification.error(res.result.message);
                                    }
                                });
                            }else{
                            Notification.error(res.result.message);
                            }
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                        var message = res.result && res.result.message && res.result.message.attnm_key ? res.result.message.attnm_key : 'There were some Error Occur.'
                        Notification.error(message);
                    });
            }
        };

        $(window).bind('keydown', function(event) {

            if (event.ctrlKey || event.metaKey) {
                switch (String.fromCharCode(event.which).toLowerCase()) {

                    case 's':

                        event.preventDefault();

                        $scope.addAttorneyToFirmForm.$setSubmitted();
                        $scope.$apply();

                        $scope.addAttorneyToFirmSubmit($scope.addAttorneyToFirmForm.$valid);

                        break;
                }
            }
        });

        $scope.closePopupWindow = function(){
            swal({
                title: "Are you sure ?",
                text: "Are you sure want to close this window?",
                type: "success",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {
                    $uibModalInstance.close($scope.attorneydata);
                }
            });
        };

        $scope.onSelectFirm = function($item, $model, $label){
            $scope.isFirmSelected = true;
        }

        $scope.change_callinby = function(){                 
            if($scope.attorneydata.attorney_type == 'SD'){

                $scope.attorneydata.send_job_confirmation = 'Y';
            }else{
                $scope.attorneydata.send_job_confirmation = $scope.attorneydata.send_job_confirmation1;
            }
        }

        $scope.changeFirm = function(){
            if($scope.isFirmSelected){
                $scope.attorneydata.attorney_id = null;
                $scope.isFirmSelected = false;
            }
        };

        $scope.clearFirm = function(){
            if(!$scope.isFirmSelected){
                $scope.attorneydata.attorney_id = null;
            }
        }

    }]);