angular.module('deitz').
	controller('autoSendStatementsController',[
		'$scope',
		'$rootScope',
		'DTOptionsBuilder',
		'DTColumnBuilder',
        '$http', 
        'apiUrl',
		'$compile',
		'$state',
		'commonFactory',
        'Notification',
        '$uibModal',
		function(
			$scope,
			$rootScope,
			DTOptionsBuilder,
			DTColumnBuilder,
            $http,
            apiUrl,
			$compile,
			$state,
			commonFactory,
            Notification,
            $uibModal
				){
 

        $scope.dtInstance = {};   
        $scope.selectedFirmArray = [];
        $scope.totalQueueFirms = 0;
        $scope.formData = { 
            
            excludeSetFreqency : false,
            includeSetFrequencyOnly : false,

            excludeNone : false,
            includeNoneOnly : false,
            
            excludeBTSTNone : false,
            includeBTSTNoneOnly : false,

            excludeNoEmail : false,
            excludeEmail : false,
            
            excludeNoFax : false,
            excludeFax : false,
            
            excludeLastSent : false,
            termsLikeCod: false,
            termsLikeExtend: false,

            lastSentDateRange : {
                endDate : moment(),
                startDate : moment()
            }
        }; 
        $scope.email = {};
        $scope.fax = {};

        $scope.selectedBusinessUnitIDs=[];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));

        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });

        $scope.selectedFirmArray = JSON.parse(localStorage.getItem('batchFirms'));
        if($scope.selectedFirmArray){

            $scope.totalQueueFirms = $scope.selectedFirmArray.length;

        }

        commonFactory.get('/api/get-statement-codes').success(function(res){
            $scope.statement_codes = res.data;
        });
        
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        };

        $scope.reloadData = function() {

            $scope.selectedFirm = {}; 
            $scope.dtInstance.rerender();
        };
        $scope.resetSearch = function(){  
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        }
        $scope.clearBatch = function(){
            $scope.selectedFirmArray = [];
            $scope.totalQueueFirms = 0; 
            localStorage.setItem('batchFirms',JSON.stringify($scope.selectedFirmArray));    
            $scope.reloadData();    
        }
        $scope.currencyFormat = function(data, type, meta, meta){
            if(parseFloat(data) >= 0){
                return '$'+parseFloat(data).toFixed(2);
            }
            if(parseFloat(data) < 0){
                return '-$'+ parseFloat(Math.abs(data)).toFixed(2);
            }
        };

        $scope.matchStatementCode = function(id){
            var email_fax = '';
            var excludeIds = [5,6,7];
            angular.forEach($scope.statement_codes, function (value,key) {       
               
                if(value.id == id && excludeIds.indexOf(Number(id)) == -1){
                    email_fax = (value.email_fax == 'e') ? 'EMAIL' :'FAX';
                }
            
            });

            return email_fax;
        }

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data') 
            .withOption('ajax', function (data, callback, settings) {
                
                data.excludeSetFreqency = $scope.formData.excludeSetFreqency;
                data.includeSetFrequencyOnly = $scope.formData.includeSetFrequencyOnly;

                data.excludeNone = $scope.formData.excludeNone;
                data.includeNoneOnly = $scope.formData.includeNoneOnly;
                
                data.excludeBTSTNone = $scope.formData.excludeBTSTNone;
                data.includeBTSTNoneOnly = $scope.formData.includeBTSTNoneOnly;

                data.excludeNoEmail = $scope.formData.excludeNoEmail;
                data.excludeEmail = $scope.formData.excludeEmail;
                
                data.excludeNoFax = $scope.formData.excludeNoFax;
                data.excludeFax = $scope.formData.excludeFax;

                data.excludeNoContactInfoFound = $scope.formData.excludeNoContactInfoFound;
                data.includeNoContactInfoFoundOnly = $scope.formData.includeNoContactInfoFoundOnly;

                data.excludeLastSent = $scope.formData.excludeLastSent;

                data.termsLikeCod = $scope.formData.termsLikeCod;
                data.termsLikeExtend = $scope.formData.termsLikeExtend;
                
                if($scope.formData.excludeLastSent){

                    data.lastSentStartDate = moment($scope.formData.lastSentDateRange.startDate).format('YYYY-MM-DD');
                    data.lastSentEndDate = moment($scope.formData.lastSentDateRange.endDate).format('YYYY-MM-DD');
                
                }

                data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);;
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean))); 
                
                angular.forEach(data.columns,function(value, key){
                    data.columns[key].search.value = data.columns[key].search.value.replace(/ /g, "%");
                });
                
                commonFactory.showDataTable('/api/get-firm-statements', data)
                .success(function(res) {
                    if (res) {  
                        $scope.selectAll = true;
                        $scope.selectedFirm = {};
                        callback(res);
                    }
                })
                .error(function(err) { 
                });
            })   
            .withOption('stateSave',true) 
            .withOption('processing', true)
            .withOption('serverSide', true) 
            .withOption('createdRow', $scope.compileDatatable)  
            .withLanguage({
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "", 
            }) 
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500) 
            .withOption('lengthMenu', [10, 25, 50, 100, 250])
            .withDisplayLength(250) 
            .withOption('order', [0, 'asc']) 
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            }) 
            .withLightColumnFilter({
                '0': {
                    type: 'text'
                }
            });
        $scope.dtColumns = [];     
        var titleHtml = '<input type="checkbox" class="layout-align-xl-center-center" icheck ng-model="selectAll" ng-change="toggleAction(selectAll)">';

        $scope.dtColumns.push(
            DTColumnBuilder.newColumn('NAME').withTitle('Firm Name').renderWith(function (data, type, full, meta) {
                let html = data;
                let collapseId = 'collapse_' + meta.row;
                if (full.attname?.length) {
                    html = '<span>' + data + '</span> <button class="pull-right" style="padding: 0;border: none;background: none;" data-toggle="collapse" href="#' + collapseId + '" role="button" aria-expanded="false" aria-controls="' + collapseId + '" title="View Users"><i style="font-size: 20px;color: #337ab7;" class="fa fa-caret-down"></i></button>';
                    let users = '';
                    angular.forEach(full.attname, function (value, key) {
                        let self = value.auto_attach_statement && value.auto_attach_statement == 'Y' ? '&nbsp;<b>SELF</b>' : '';
                        let firm = value.firm_attach_statement == 'Y' ? '&nbsp;<b>FIRM</b>' : '';
                        if (!(self == '' && firm == '')) {
                            users += "<span ><br> &nbsp;&nbsp;-&nbsp;" + value.NAME + self + firm + "</span>";
                        }
                    });
                    html +=
                        '<div class="collapse" style="word-break: break-word;" id="' + collapseId + '">\n' +
                        '  <div class="card card-body">\n' +
                        users +
                        '  </div>\n' +
                        '</div>';
                }
                return html;
            }).withOption('searchable',true).withOption('width','20%'),
            DTColumnBuilder.newColumn('ADDR_LINE1').withTitle('Address').renderWith(function(data, type, full, meta){
                return full.ADDR_LINE1 +' '+full.ADDR_LINE2;
            }).withOption('width','15%'),
            DTColumnBuilder.newColumn('EmailAddress').withTitle('Email').renderWith(function(data, type, full, meta){
                
                $scope.email[full.id] = data;
                var type = 'email';
                var editable_email = data ? '' : 'ADD EMAIL ADDRESS';

                return '<a href="javascript:void(0)" editable-text = email[' +full.id +'] onaftersave="updateFirmProfile('+ full.id +',\''+type+'\')" ng-bind="email['+full.id+'] || \''+ editable_email +'\'"></a>';


            }).withOption('width','20%'),
            DTColumnBuilder.newColumn('FAX').withTitle('Fax').renderWith(function(data, type, full, meta){
                
                $scope.fax[full.id] = data;
                var type = 'fax';
                var editable_fax = data ? '' : 'ADD FAX NUMBER';

                return '<a href="javascript:void(0)" editable-text = fax[' +full.id +'] onaftersave="updateFirmProfile('+ full.id +',\''+type+'\')" ng-bind="fax['+full.id+'] || \''+ editable_fax +'\'"></a>';


            }).withOption('width','10%'), 
            DTColumnBuilder.newColumn('BAL_DUE').withOption('defaultContent', '-').withTitle('Bal Due').renderWith($scope.currencyFormat).withOption('width','10%'),
            DTColumnBuilder.newColumn('statement_schedules_id').withOption('defaultContent', '-').withTitle('Send Via').renderWith(function(data, type, full, meta){
                var email_fax = 'SET FREQUENCY';
                var description = '';
                var frequency_id = data;
                var isFrequency = false;

                if(frequency_id == 5){

                    email_fax = 'BTST-NONE';
                
                }else if(frequency_id == 6){

                    email_fax = 'NONE'; 

                }else if(frequency_id == 7){

                    email_fax = 'No Contact Info Found '; 

                }else{

                    angular.forEach($scope.statement_codes, function (value,key) {       
                       
                        if(value.id == frequency_id){

                            email_fax = (value.email_fax == 'e') ? 'EMAIL' :'FAX';
                            description = (value.statement_invoice == 's') ? 'STATEMENT ONLY' :'STATEMENT & INVOICE';
                            isFrequency = true;
                        }
                    
                    });
                }
                 
                var style = isFrequency ? '' : "color:#d14;border-bottom:dashed;border-width:thin;";

                return '<a href="javascript:void(0)" style ="'+style+'" ng-click="changeFrequency('+full.id+','+frequency_id+')">'+email_fax+'</a><br><small>'+description+'<small>';

            }).withOption('width','15%'),
            DTColumnBuilder.newColumn('last_statement_sent_date').withOption('defaultContent', '-').withTitle('Last Sent').renderWith(function(data, type, full, meta){
                
                if(data){

                    return moment(data).format('MM/DD/YYYY hh:mm a');
                
                }
            
            }).withOption('width','10%'), 
            DTColumnBuilder.newColumn(null).withTitle(titleHtml).notSortable().renderWith(function(data, type, full, meta) {  
                 
                    
                var is_checked = false;
                var batchFirm = [];
                $scope.selectedFirmArray =  JSON.parse(localStorage.getItem('batchFirms'));
                 
                if($scope.selectedFirmArray){
                    
                    $scope.totalQueueFirms = $scope.selectedFirmArray.length;
                    batchFirm = $scope.selectedFirmArray.filter(function (item) {
                       return item == full.id ;                  
                    });
                
                }else{

                    $scope.totalQueueFirms = 0;

                } 
                if(batchFirm.indexOf(Number(full.id)) != -1 ){
                    
                    is_checked = true; 

                }    


                var email_fax = $scope.matchStatementCode(full.statement_schedules_id);
             
                if((full.EmailAddress && email_fax == 'EMAIL') || (full.FAX && email_fax == 'FAX')){ 

                    $scope.selectedFirm[full.id] = full;
                    $scope.selectedFirm[full.id].is_checked = is_checked; 

                    if (!$scope.selectedFirm[full.id].is_checked) {
                        $scope.selectAll = false;
                    } 

                    if($scope.selectedFirmArray){ 
                    
                        localStorage.setItem('batchFirms',JSON.stringify($scope.selectedFirmArray));

                    }

                    return  '<toggle ng-disabled="false" size="btn-xs" ng-model="selectedFirm['+full.id+'].is_checked" ng-change="changeFirmAction(selectedFirm['+full.id+'].is_checked,'+full.id+')" on="Yes" off="No" onstyle="btn-success" offstyle="btn-danger"></toggle>';
           
                }else{

                    if(is_checked){ 

                        $scope.selectedFirmArray =  $scope.selectedFirmArray.filter(function (item) {

                            return item != full.id; 
                        
                        });

                        localStorage.setItem('batchFirms',JSON.stringify($scope.selectedFirmArray));    
                    }
                    
                    return '<a class="btn btn-xs btn-primary" ui-sref="statements.print_statements({attId:'+ full.id +'})" uib-tooltip="Send Statements Only"><i class="fa fa-send"></i> &nbsp;Send </a>';
                    
                } 
               

            }).withOption('searchable',false)
            
        );

        $scope.changeFirmAction = function(checked,id){
            
            $scope.selectedFirmArray =  JSON.parse(localStorage.getItem('batchFirms')); 
            if($scope.selectedFirmArray == null){
                $scope.selectedFirmArray = [];
            }
            if(checked){   
                
                if($scope.selectedFirmArray.indexOf(Number(id)) == -1){
  
                    $scope.selectedFirmArray.push(Number(id));

                } 

            }else{ 
              
                $scope.selectedFirmArray =  $scope.selectedFirmArray.filter(function (item) {

                    return item != id; 
                
                }); 
                      
            }
            $scope.selectAll = true;
            angular.forEach($scope.selectedFirm, function(value, key){

                if(!value.is_checked){
                    $scope.selectAll = false;
                }; 
            });
            $scope.totalQueueFirms = $scope.selectedFirmArray.length;
            localStorage.setItem('batchFirms',JSON.stringify($scope.selectedFirmArray)); 
        }

        $scope.toggleAction = function(isSelectAll){

            angular.forEach($scope.selectedFirm, function(value, key){
                
                $scope.selectedFirm[value.id].is_checked = isSelectAll;
                $scope.changeFirmAction(isSelectAll,value.id);

            });    
        }

        $scope.sendSelectedFirm = function(){

            $scope.skipFirmArray =  JSON.parse(localStorage.getItem('batchFirms'));  

            if($scope.skipFirmArray == null){ 
                $scope.skipFirmArray = [];
                
            }
            if(!$scope.skipFirmArray.length > 0){

               Notification.error('THREE ARE NO FIRM SELECTED !');
               return false; 
            
            }else{

                var txt = "THERE ARE <span style='color:red'> "+ $scope.skipFirmArray.length +" </span> FIRMS IN THE QUEUE<br>(IT WILL TAKE 5 TO 10 MINUTES)";
            
            }
            swal({
                    title: txt,
                    text: "Are You Sure Want To Send ?",
                    html:true,
                    type: "success",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) { 

                        $rootScope.showLoader(true);

                        commonFactory.post('/api/queue-statements',$scope.skipFirmArray).success(function(res){
                            $rootScope.showLoader(false);
                            if(res.error){

                                Notification.error(res.result.message);

                            }else{
                                Notification.success(res.result.message);
                                $state.go('index.system_notification');

                            }
                        });
                    }
                }); 
        }
        $scope.changeFrequency = function(firmId, statement_schedules_id){
            
            $rootScope.showLoader(true);
            $scope.old_frequency = { email_fax : 'ef_set_frequency',statement_invoice : 's'};
            $scope.new_statement_schedules_id = null;

            angular.forEach($scope.statement_codes, function (value,key) {       
                   
                if(value.id == statement_schedules_id){ 
                    $scope.old_frequency = value;        
                }
                
            }); 

            var modalInstance = $uibModal.open({

                templateUrl : 'modules/resources/templates/statement_frequency.html',
                controller : function($scope, old_frequency, $uibModalInstance){
                    $scope.frequency = {};

                    $scope.frequency.email_fax = old_frequency.email_fax;
                    $scope.frequency.statement_invoice = old_frequency.statement_invoice;

                    $scope.closeModal = function(){

                        $uibModalInstance.close();
                    
                    }
                    $scope.save = function(){

                        $uibModalInstance.close($scope.frequency);
                    
                    }

                },
                resolve:{

                    old_frequency : function(){

                        return $scope.old_frequency;

                    } 
                },
                backdrop:false
            });

            modalInstance.opened.then(function(){
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function (new_frequency) {
                
                if(new_frequency){ 
    
                    angular.forEach($scope.statement_codes, function (value,key) {   
                        
                        if(value.email_fax == new_frequency.email_fax && value.statement_invoice == new_frequency.statement_invoice){ 
                        
                            $scope.new_statement_schedules_id = value.id; 
                        }
                        
                    });
 
                    if($scope.new_statement_schedules_id != statement_schedules_id){

                        $scope.updateFirmProfile(firmId,'email_fax',$scope.new_statement_schedules_id);  
                    
                    }
                }

            });  
        }

        $scope.updateFirmProfile = function(firmId, type, new_frequency = ''){
        
            $rootScope.showLoader(true); 
            var data = { firmId : firmId };

            if(type == 'email'){

                data.EmailAddress = $scope.email[firmId];

            }else if(type == 'fax'){

                data.FAX = $scope.fax[firmId];
            
            }else{

                data.statement_schedules_id = new_frequency;

            }
            data.type = type; 
            commonFactory.post('/api/update-firm-profile',data).success(function(res){
                $rootScope.showLoader(false);
                if(res.error){
                    
                    Notification.error(res.result.message);
                
                }else{ 
                     
                    $scope.reloadData(); 
            
                }
            });
        }

}]);