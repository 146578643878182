angular.module('deitz')
    .controller('AddCertificatesController', [
        '$scope',
        '$rootScope',
        'codeFactory',
        '$http',
        'Upload',
        'apiUrl',
        '$state',
        'commonFactory',
        'Notification',
        '$stateParams',
        '$uibModal',
        'reporter_certificate_id',
        '$uibModalInstance',
        'configFactory',
        function ($scope,
                  $rootScope,
                  codeFactory,
                  $http,
                  Upload,
                  apiUrl,
                  $state,
                  commonFactory,
                  Notification,
                  $stateParams,
                  $uibModal,
                  reporter_certificate_id,
                  $uibModalInstance,
                  configFactory) {
            $scope.reporterCertificate = {};
            $scope.validations = codeFactory.validations;
            $scope.messages = codeFactory.messages;
            $scope.formSubmit = false;
            $scope.title = 'Add Certificate';
            $scope.reporterCertificate.reporter_id = $state.params.id;
            $scope.reporterCertificate.achieved_date = '';
            $scope.reporterCertificate.expiration_date = '';
            $scope.isPdfUploaded = false;
            
            $scope.reporter_certificate_id = reporter_certificate_id;

            if(reporter_certificate_id){
                $scope.title = 'Edit Certificate';

                var id = reporter_certificate_id;
                commonFactory.get('/api/reporter-certificates/' + id + '/edit')
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if (response.status == 200 && response.result) {
                        $scope.reporterCertificate.certificates_type_id = response.result.certificate.certificates_type_id || '';
                        $scope.reporterCertificate.reporter_id = response.result.certificate.reporter_id || '';
                        $scope.reporterCertificate.expiration_date = response.result.certificate.expiration_date || '';
                        $scope.reporterCertificate.achieved_date = response.result.certificate.achieved_date || '';
                        $scope.reporterCertificate.notes = response.result.certificate.notes || '';
                        $scope.reporterCertificate.certificate_number = response.result.certificate.certificate_number || '';

                        if(response.result.certificate.file_path){
                            $scope.isPdfUploaded = true;
                        }
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
            }
            
            $scope.CertificateLists = [];

            $scope.getCertificateLists = function(){
                $http.get(apiUrl + '/api/getCertificateLists').then(function(response) { 
                    $scope.CertificateLists = response.data.result.data;
                });
            };

            $scope.getCertificateLists();

            $scope.viewCertificatePdf = function(id){
                if(id){
                    $http.get(apiUrl + '/api/get-certificate-url', {
                        params: {
                            id: id
                        }
                    }).then(function(response) {
                        if(!response.data.error){
                            window.open(response.data.result,'_blank');
                        }else{
                            Notification.error(response.data.message);
                        }
                    });
                }
            }

            $scope.removeCertificatePdf = function(id){
                if(id){

                    swal({
                        title: "Are you sure want to remove this PDF?",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes, Do it!",
                        cancelButtonText: "No!",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $http.get(apiUrl + '/api/remove-certificate-file', {
                                params: {
                                    id: id
                                }
                            }).then(function(response) {
                                if(!response.data.error){
                                    $scope.isPdfUploaded = false;
                                    Notification.success(response.data.message);
                                }else{
                                    Notification.error(response.data.message);
                                }
                            });
                        }
                    });

                }
            }
    
            $scope.addReporterCertificate = function(reporterCertificate, isValid) {
                $scope.formSubmit = true;  
                
                if(typeof reporterCertificate.expiration_date != "undefined" && reporterCertificate.expiration_date != ""){
                    reporterCertificate.expiration_date = moment(reporterCertificate.expiration_date).format('YYYY-MM-DD');                    
                }
                if(typeof reporterCertificate.achieved_date != "undefined" && reporterCertificate.achieved_date != ""){
                    reporterCertificate.achieved_date = moment(reporterCertificate.achieved_date).format('YYYY-MM-DD');                    
                }

                if (isValid) {
                    $rootScope.showLoader(true);

                    if(reporter_certificate_id){
                        var id = reporter_certificate_id;

                        Upload.upload({
                            url: apiUrl+'/api/reporter_certificates/' + id + '/update',
                            data: $scope.reporterCertificate
                        }).then(function (response) { 
                            $rootScope.showLoader(false);
                            if (response) {
                                if (response.status == 200) {
                                    Notification.success("Certificate updated successfully");
                                    $scope.closePopupWindowSimple();
                                }else if(response.status == 400){
                                    Notification.error(response.result.message);
                                }
                            }
                        },function(err){
                            $rootScope.showLoader(false);
                        });


                        /*commonFactory.put('/api/reporter-certificates/' + id, $scope.reporterCertificate)
                            .success(function(response) {
                                $rootScope.showLoader(false);
                                if (response) {
                                    if (response.status == 200) {
                                        Notification.success("Certificate updated successfully");
                                        $scope.closePopupWindowSimple();
                                    }else if(response.status == 400){
                                        Notification.error(response.result.message);
                                    }
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                            });*/
                    }else{
                        /*commonFactory.post('/api/reporter-certificates', $scope.reporterCertificate)
                        .success(function(response) {
                            $rootScope.showLoader(false);
                            if (response) { 
                                if (response.status == 200) {
                                    Notification.success("Certificate added successfully");
                                    $scope.closePopupWindowSimple();
                                }else if(response.status == 400){
                                    Notification.error(response.result.message);
                                }
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                        });*/

                        Upload.upload({
                            url: apiUrl+'/api/reporter_certificates/addnew',
                            data: $scope.reporterCertificate
                        }).then(function (response) { 
                            $rootScope.showLoader(false);
                            if (response) {
                                if (response.status == 200) {
                                    Notification.success("Certificate added successfully");
                                    $scope.closePopupWindowSimple();
                                }else if(response.status == 400){
                                    Notification.error(response.result.message);
                                }
                            }
                        },function(err){
                            $rootScope.showLoader(false);
                        });
                    }
                }
            }
            
            $scope.closePopupWindowSimple = function(){
                $uibModalInstance.close(true);
                setTimeout(function () {
                    document.getElementById('reloadCertificates').click();
                }, 400);
            };
            
          
        }]);
