angular.module('deitz')
            .controller('clientDroppedReportController', [
                    '$scope',
                    '$rootScope',
                    '$http',
                    'apiUrl',
                    'DTOptionsBuilder',
                    'DTColumnBuilder',
                    'commonFactory',
                    '$compile',
                    '$state',
                    '$window',
                    'Notification',
                        function (
                            $scope,
                            $rootScope,
                            $http,
                            apiUrl,
                            DTOptionsBuilder,
                            DTColumnBuilder,
                            commonFactory,
                            $compile,
                            $state,
                            $window,
                            Notification
                        ) {
        
        $scope.dtInstance = {};
        $scope.dateRange
        $scope.AttdArray = {};
        

        $scope.lastdays = [
            {'name': '15 Days','value': 15},
            {'name': '20 Days','value': 20},
            {'name': '25 Days','value': 25},
            {'name': '30 Days','value': 30},
            {'name': '35 Days','value': 35},
            {'name': '40 Days','value': 40},
            {'name': '45 Days','value': 45},
            {'name': '50 Days','value': 50},
            {'name': '55 Days','value': 55},
            {'name': '60 Days','value': 60},
            {'name': '65 Days','value': 65},
            {'name': '70 Days','value': 70},
            {'name': '75 Days','value': 75},
            {'name': '80 Days','value': 80},
            {'name': '85 Days','value': 85},
            {'name': '90 Days','value': 90}
          ];
          //Setting first option as selected in configuration select
          $scope.selectedlastdays = $scope.lastdays[3].value;



        $scope.selectedAttdArray= []
        $scope.isSalesPersonLogin = false;
        $scope.selectedSalesPersonsIds = [];       
        $scope.isSalesPersonLogin = $rootScope.isSalesLogin;

        $scope.title = $state.current.data.pageTitle;
        $scope.report = {
			startDate : moment()
		};
        $scope.resetSearch = function(){
            $scope.dateRange = null;
            $scope.dtInstance.DataTable.state.clear();
            $scope.reloadData();
        };

        $scope.currentYear =  moment().format("YYYY");
        $scope.lastFiveYear = [];
        for(i=0;i<5;i++){
            $scope.lastFiveYear.push(String($scope.currentYear-i))
        }

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });

        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
        
        //Getting all resources by type.
        commonFactory.post('/api/resource-list-by-type',{rep_typ:'S'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
            }
            $rootScope.showLoader(false);
        });
        
        $scope.reloadData = function () {
            
            $scope.dtInstance.rerender();
        };
        $scope.createdRow = function (row, data, dataIndex) {
            // Recompiling so we can bind Angular directive to the DT
            $compile(angular.element(row).contents())($scope);

        };
       
        $scope.downloadExcel = function(){  
            
            if($scope.dtInstance.DataTable.order().length){
                var order_column_index = $scope.dtInstance.DataTable.order()[0][0];
                var order_column_order = $scope.dtInstance.DataTable.order()[0][1];
                
                if(!order_column_index && !order_column_order){
                    order_column_index = $scope.dtInstance.DataTable.order()[0];
                    order_column_order = $scope.dtInstance.DataTable.order()[1];
                }
            }else{
                var order_column_index = 0;
                var order_column_order = 'asc';
            }
      
            $rootScope.showLoader(true);
                data= {
                    downloadExcel:true,
                    order: [ 
                        $scope.dtColumns[order_column_index].mData,
                        order_column_order
                    ]
                }
            data.selectedSalesPersonsIds = $scope.selectedSalesPersonsIds;
            data.isSalesPersonLogin = $rootScope.isSalesLogin;
            data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
            data.selectedlastdays = $scope.selectedlastdays;
            commonFactory.post('/api/get-client-dropped-report', data)
            .success(function(response) {
                $rootScope.showLoader(false);
                if(response.result.fileName){
                    // $window.location.href = apiUrl +'/api/download-client-dropped-report';
                    $window.open(apiUrl +'/api/download-client-dropped-report', '_blank');
                }

            })
            .error(function(err) {
                $rootScope.showLoader(false);
                Notification.error('Something went wrong!');
                
            });
        }

  
       
        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                data.isSalesPersonLogin = $rootScope.isSalesLogin;
                data.selectedSalesPersonsIds = $scope.selectedSalesPersonsIds;
                data.selectedlastdays = $scope.selectedlastdays;
                data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));
                // data.year=  moment($scope.report.startDate).format('YYYY');
                // map your server's response to the DataTables format and pass it to
                commonFactory.post('/api/get-client-dropped-report', data)
                    .success(function(response) {
                        if (response.error) {
                            // $scope.reloadData();
                        }
                        else {
                            callback(response);
                        
                        }
                    })
                    .error(function(err) {
                        if (err.error !== "token_not_provided") {
                           // $scope.reloadData();
                        }
                    });
                
            })
            .withOption('processing', true)
            // .withOption('searching', false)
            .withLanguage({

                "sEmptyTable": "NO RECORDS AVAILABLE IN TABLE",
                "sInfo": "SHOWING _START_ TO _END_ OF _TOTAL_ RECORDS",
                "sInfoEmpty": "SHOWING 0 TO 0 OF 0 INVOICES",
                "sInfoFiltered": "(FILTERED FROM _MAX_ TOTAL RECORDS)",
                "sInfoPostFix": "",
                "sInfoThousands": ",",
                "sLengthMenu": "SHOW _MENU_ RECORDS",
                "sLoadingRecords": "<img src='img/loading_bar.gif'/>",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "Search :",
                "sZeroRecords": "NO MATCHING RECORDS FOUND",
                "oPaginate": {
                    "sFirst": "FIRST",
                    "sLast": "LAST",
                    "sNext": "NEXT",
                    "sPrevious": "PREVIOUS"
                },
                "oAria": {
                    "sSortAscending": ": ACTIVATE TO SORT COLUMN ASCENDING",
                    "sSortDescending": ":   ACTIVATE TO SORT COLUMN DESCENDING"
                }
            })
            .withOption('serverSide', true)
           
            .withOption('stateSave', false)
            // .withOption('paging', false)
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            .withDisplayLength(250)
            .withOption('lengthMenu', [
                [10, 25, 50, 100, 200, 250, 300, 500],
                [10, 25, 50, 100, 200, 250, 300, 500]
            ])
            .withOption('order', [6, 'desc'])
            .withOption('createdRow', $scope.createdRow)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            }).withLightColumnFilter({
                '0': {
                    type: 'text',
                },
                '13': {
                    type: 'text',
                }
            });
            

        $scope.dtColumns = [];

        $scope.dtColumns.push(
            DTColumnBuilder.newColumn('account_name').withOption('sortable', true).withOption('defaultContent', '').withTitle('Account Name').withOption('searchable', true).withOption("width", "5%").withOption('name', 'ATTORNEY_tbl.NAME').renderWith(function(data,type,full,meta){
                return '<a ui-sref="firms.manage.step_one({id : ' + full.id + '})" href="javascript:void(0);" target="_blank">'+data+'</a>';
            }),
            DTColumnBuilder.newColumn('master_email_address').withOption('sortable', true).withOption('defaultContent', '').withTitle('Master Email').withOption('searchable', true).withOption("width", "5%"),
            DTColumnBuilder.newColumn('ap_email').withOption('sortable', true).withOption('defaultContent', '').withTitle('AP Email').withOption('searchable', true).withOption("width", "5%"),
            DTColumnBuilder.newColumn('attorney_name').withOption('sortable', true).withOption('defaultContent', '').withTitle('Attorney Name').withOption('searchable', true).withOption("width", "5%"),
            DTColumnBuilder.newColumn('attorney_email').withOption('sortable', true).withOption('defaultContent', '').withTitle('Schedule By').withOption('searchable', true).withOption("width", "5%"),
            DTColumnBuilder.newColumn('bill_to_email').withOption('sortable', true).withOption('defaultContent', '').withTitle('Billed to Attorney Email').withOption('searchable', true).withOption("width", "5%"),
            );
           /* $scope.dtColumns.push(
                DTColumnBuilder.newColumn('job_count_trailing_30').withOption('sortable', true).withOption('defaultContent', '').withTitle('Last Selected Days').withOption('searchable', true).withOption("width", "5%")
            );*/
            
            // var trailing =  moment().subtract(3, 'months').format('YYYY/MM/DD') + '-' + moment().format('YYYY/MM/DD');
           /* $scope.dtColumns.push(
                DTColumnBuilder.newColumn('job_count_trailing').withOption('sortable', true).withOption('defaultContent', '').withTitle('Last 90 Days').withOption('searchable', true).withOption("width", "5%")
            );*/

            $scope.lastFiveYear.forEach(element => {
                column= 'job_count_'+element;
                $scope.dtColumns.push(
                    DTColumnBuilder.newColumn(column).withOption('sortable', true).withOption('defaultContent', 0).withTitle(element).withOption('searchable', true).withOption("width", "5%")
                );
            
            });

            $scope.dtColumns.push(
                DTColumnBuilder.newColumn('one_reporting_salesman').withOption('sortable', true).withOption('defaultContent', '').withTitle('Sales Rep').withOption('searchable', true).withOption("width", "5%").renderWith(function(data,type,full,meta){ 
                    if(full.one_reporting_salesman && full.one_reporting_salesman.reptype){
                        return full.one_reporting_salesman.reptype.name;
                    }else{
                        return '';
                    }
                }).withOption('name', 'oneReportingSalesman.reptype.FIRST_NAME'),
            );

    }
]);
