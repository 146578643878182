angular.module('deitz').controller('viewResourceUnpaidJobsController', [
    '$scope', '$rootScope', '$state', '$http', 'apiUrl', '$uibModal', '$window', 'commonFactory', '$uibModalInstance', 'resource_id', 'resource_type', 'Notification', 'payment_date', 'selectedBusinessUnitIDs',
    function (
        $scope, $rootScope, $state, $http, apiUrl, $uibModal, $window, commonFactory, $uibModalInstance, resource_id, resource_type, Notification, payment_date, selectedBusinessUnitIDs
    ) {

            // $rootScope.showLoader(true);
            $scope.uibModalInstance = $uibModalInstance;
            $scope.is_popup = true;
            $scope.unPaidJobs = [];
            $scope.jobGrandTotal = 0;
            $scope.isLoading = false;
            $scope.isPaymentDateChanged = false;
            $scope.resource_type = resource_type;
            $scope.resource_id = parseInt(resource_id);

            /**
             * @name getJobList
             * @description get the job list which is pending for payment
             *
             */
            $scope.getJobList = function(){

                $scope.isLoading = true;
                $scope.jobGrandTotal = 0;

                commonFactory.post('/api/check/get-unpaid-job-list',{resource_id : resource_id,'resource_type': resource_type,'payment_date':payment_date,'selectedBusinessUnitIDs':selectedBusinessUnitIDs }).success(function(response){
                    $scope.unPaidJobs = [];

                    angular.forEach(response.data, function(value,key){


                        if(resource_type=='R'){

                            value.REP_PAYDT = moment(value.REP_PAYDT).toDate();
                            $scope.jobGrandTotal = $scope.jobGrandTotal + parseFloat(value.REP_PAYAMT);


                        }else if(resource_type=='T'){

                            value.REP_PAYDT = moment(value.TYP_PAYDT).toDate();
                            $scope.jobGrandTotal = $scope.jobGrandTotal + parseFloat(value.TYP_PAYAMT);
                        }
                        else{
                            value.REP_PAYDT = (value.resource_type=='R')? moment(value.REP_PAYDT).toDate() :  moment(value.TYP_PAYDT).toDate();
                            value.resource_use_as = (value.resource_type=='R')? value.additional_resource_type :  'TYPIST';
                            $scope.jobGrandTotal = (value.resource_type=='R') ? $scope.jobGrandTotal + parseFloat(value.REP_PAYAMT) : $scope.jobGrandTotal + parseFloat(value.TYP_PAYAMT);
                        }
                        $scope.unPaidJobs[key] = value;
                    });
                    // $scope.unPaidJobs[key] = angular.copy(value);
                    // $scope.unPaidJobs = response.data;

                    $scope.isLoading = false;

                }).error(function(error){
                    $scope.isLoading = false;
                });
            };

            $scope.opened = {};

            $scope.open = function($event, elementOpened) {
                $event.preventDefault();
                $event.stopPropagation();
                $scope.opened[elementOpened] = !$scope.opened[elementOpened];
            };

            if(resource_id){
                //get the job list which is pending for payment
                $scope.getJobList();
            }
            else{
                $uibModalInstance.close(true);
            }

            $scope.updatePaymentDate = function(data,object,resource_type){
                $rootScope.showLoader(true);
                var JOB_NO = object.JOB_NO;
                var updateData = {};
                if(resource_type == 'R'){
                    updateData = {'REP_PAYDT' : moment(data).format('YYYY-MM-DD') };
                }
                else if(resource_type=='T'){
                    updateData = {'TYP_PAYDT' : moment(data).format('YYYY-MM-DD')};
                }
                else{

                    updateData = (object.resource_type=='R') ? {'REP_PAYDT' : moment(data).format('YYYY-MM-DD')} : {'TYP_PAYDT' : moment(data).format('YYYY-MM-DD')};
                }

                if(object.adjustment_id){
                    updateData.adj_type =  "ADJ_WITH_JOB";
                    updateData.adjustment_id =  object.adjustment_id;
                }
                else{
                    updateData.adj_type = "ADJ_WITHOUT_JOB";
                    updateData.JOB_NO = JOB_NO;
                    updateData.resource_id=object.resource_id;
                }

                console.log(object);

                commonFactory.post('/api/updatePaymentDate', updateData)
                    .success(function (response) {
                        $scope.getJobList();
                        if(!response.error){
                            Notification.success(response.result.message || 'Adjustment has been deleted successfully.');
                        } else{
                            Notification.error(response.result.message || 'Something went wrong!');
                        }
                        $rootScope.showLoader(false);
                    })
                    .error(function (err) {
                        Notification.error(response.result.message | 'Something went wrong!');
                        $rootScope.showLoader(false);
                    });
            };

            $scope.closePopupWindow = function(){
                $uibModalInstance.close($scope.isPaymentDateChanged);
            };

            $scope.getjobresourceid = function(jobres_id){ 
                $rootScope.showLoader(true);
                $http.get(apiUrl + '/api/get-job-resource', {
                    params: {
                        job_no:jobres_id
                    }
                }).then(function(response) {                            
                    if(response.error){
                        Notification.error(response.data.result.message | 'Error while performing this operation.');
                    } else{                              
                        $rootScope.showLoader(false);
                     if(response.data.result.data.length==1){  
                        var url = $state.href('payroll.view',{ id : response.data.result.data[0].id});
                        $window.open(url,'_blank');
                    }
                    else{                              
                        var url = $state.href('payroll.resourceslist',{ job_no : jobres_id});
                        $window.open(url,'_blank');
                    }
                }
                });                        
            }

            
            $scope.editAdjustment = function(ID, adjustmentWithJobNo){
                $rootScope.showLoader(true);
                var editAdjustmentHasNoJobModal = $uibModal.open({
                    templateUrl: "modules/payroll/editAdjustmentHasNoJob.html",
                    controller: 'editAdjustmentController',
                    resolve: {
                        ID: function () {
                            return ID;
                        },
                        ADJ_TYPE : function(){
                            if(adjustmentWithJobNo){
                                return "ADJ_WITH_JOB";
                            }
                            return "ADJ_WITHOUT_JOB";
                        }
                    },
                    keyboard: false,
                    backdrop: false,
                    close: function () {

                    }
                });


                editAdjustmentHasNoJobModal.result.then(function (is_date_changed) {
                }, function (isChanged) {

                    console.log("Modal Dismissed!!!");
                    if(isChanged){
                        $scope.getJobList();
                    }
                });
            };

            $scope.deleteAdjustment= function(ID, adjustmentWithJobNo){

                swal({
                        title: "Are you sure ?",
                        text: "You are going to delete an adjustment.",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes, Do it",
                        cancelButtonText: "No!",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) {
                            $rootScope.showLoader(true);

                            var data= {};

                            if(adjustmentWithJobNo){
                                data.adj_type = "ADJ_WITH_JOB";
                                data.id = ID;
                            } else{
                                data.adj_type = "ADJ_WITHOUT_JOB";
                                data.job_resource_id = ID;
                            }

                            commonFactory.post('/api/deleteAdjustment', data)
                                .success(function (response) {
                                    $scope.getJobList();
                                    if(!response.error){
                                        Notification.success(response.result.message || 'Adjustment has been deleted successfully.');
                                    } else{
                                        Notification.error(response.result.message || 'Something went wrong!');
                                    }
                                    $rootScope.showLoader(false);
                                })
                                .error(function (err) {
                                    Notification.error(response.result.message | 'Something went wrong!');
                                    $rootScope.showLoader(false);
                                });
                        }
                    });

            };

            $scope.deleteWitnessPayroll = function(job_resource_id, forReporter, forTypist){
                $rootScope.deletePayroll(job_resource_id, forReporter, forTypist, function(){
                    $scope.getJobList();
                });
            };

        }]);