angular.module('deitz').controller('rateDetailController', [

    '$scope', '$rootScope', '$state', 'commonFactory', 'Notification',

    function(
        $scope, $rootScope, $state, commonFactory, Notification

    ) {

        $scope.firm_cnt = 0;
        $scope.negotiated_y = 0;
        $scope.negotiated_n = 0;
        $scope.isEditMode = false;
        if($state.params.id){
            $scope.isEditMode = true;
        }
        $scope.change_rate_type =  function(type) { 
            $scope.formData={};
            var params = {};
            params.rate_id =type;
            params.id = $state.params.id
            $rootScope.showLoader(true);

            commonFactory.getWithParams('/api/rates/getRate', params)
                .success(function(response) {
                    if (response && response.result && response.result.rate_detail) {
                        $scope.formData.name = response.result.rate_detail.name;
                        $scope.formData.appear_rate = response.result.rate_detail.appear_rate;
                        $scope.formData.late_appear = response.result.rate_detail.late_appear;
                        $scope.formData.Room = response.result.rate_detail.Room;
                        $scope.formData.word_indexing_page_rate = response.result.rate_detail.word_indexing_page_rate;
                        $scope.formData.rate = response.result.rate_detail.rate;
                        $scope.formData.copy_rate = response.result.rate_detail.copy_rate;
                        $scope.formData.no_min_taken_charge = response.result.rate_detail.no_min_taken_charge;
                        $scope.formData.word_indexing_flat_rate = response.result.rate_detail.word_indexing_flat_rate;
                        $scope.formData.admin_fee = response.result.rate_detail.admin_fee;
                        $scope.formData.mini_script_per_page_rate = response.result.rate_detail.mini_script_per_page_rate;
                        $scope.formData.mini_script_flat_rate = response.result.rate_detail.mini_script_flat_rate;
                        $scope.formData.postage = response.result.rate_detail.postage;
                        $scope.formData.cd_charge = response.result.rate_detail.cd_charge;
                        $scope.formData.min_invoice_charge = response.result.rate_detail.min_invoice_charge;
                        $scope.formData.web_charge_per_witness = response.result.rate_detail.web_charge_per_witness;
                        $scope.formData.video_conference_handling_and_connection_fee = response.result.rate_detail.video_conference_handling_and_connection_fee;
                        $scope.formData.reminder_charge = response.result.rate_detail.reminder_charge;
                        $scope.formData.legalview_charge = response.result.rate_detail.legalview_charge;
                        $scope.formData.deposition_insights_charge1 = response.result.rate_detail.deposition_insights_charge1;
                        $scope.formData.deposition_insights_charge2 = response.result.rate_detail.deposition_insights_charge2;
                        $scope.formData.deposition_insights_charge3 = response.result.rate_detail.deposition_insights_charge3;
                        $scope.formData.digitization_charge = response.result.rate_detail.digitization_charge;
                        $scope.formData.exhibit_charge = response.result.rate_detail.exhibit_charge;
                        $scope.formData.appearance_hourly = response.result.rate_detail.appearance_hourly;
                        $scope.formData.appearance_hourly_remote = response.result.rate_detail.appearance_hourly_remote;
                        $scope.formData.appearance_hourly_nonsteno = response.result.rate_detail.appearance_hourly_nonsteno;

                        $scope.formData.appear_rate_remote = response.result.rate_detail.appear_rate_remote;
                        $scope.formData.appear_rate_nonsteno = response.result.rate_detail.appear_rate_nonsteno;
                        $scope.formData.rate_remote = response.result.rate_detail.rate_remote;
                        $scope.formData.rate_nonsteno = response.result.rate_detail.rate_nonsteno;
                        $scope.formData.copy_rate_remote = response.result.rate_detail.copy_rate_remote;
                        $scope.formData.copy_rate_nonsteno = response.result.rate_detail.copy_rate_nonsteno;
                        $scope.formData.word_indexing_page_rate_remote = response.result.rate_detail.word_indexing_page_rate_remote;
                        $scope.formData.word_indexing_page_rate_nonsteno = response.result.rate_detail.word_indexing_page_rate_nonsteno;
                        $scope.formData.non_appearance = response.result.rate_detail.non_appearance;
                        $scope.formData.cna = response.result.rate_detail.cna;
                        $scope.formData.no_write = response.result.rate_detail.no_write;
                        $scope.formData.rough_ascii = response.result.rate_detail.rough_ascii;

                        $scope.formData.realtime = response.result.rate_detail.realtime;
                        $scope.formData.exhibits_per_page = response.result.rate_detail.exhibits_per_page;
                        $scope.formData.expedite_percentage_per_day = response.result.rate_detail.expedite_percentage_per_day;
                        $scope.formData.transcript_repository_storage = response.result.rate_detail.transcript_repository_storage;
                        $scope.formData.videographer_fee = response.result.rate_detail.videographer_fee;
                        $scope.formData.video_archiving = response.result.rate_detail.video_archiving;

                        $scope.formData.web_amount = response.result.rate_detail.web_amount;
                        $scope.formData.SOR = response.result.rate_detail.SOR;
                        $scope.formData.SORr = response.result.rate_detail.SORr;
                        $scope.formData.e_transcript = response.result.rate_detail.e_transcript;
                        $scope.formData.med_rate = response.result.rate_detail.med_rate;
                        $scope.formData.exp_rate = response.result.rate_detail.exp_rate;
                        $scope.formData.daily_rate = response.result.rate_detail.daily_rate;
                        $scope.formData.immed_rate = response.result.rate_detail.immed_rate;
                        $scope.formData.lit_charges = response.result.rate_detail.lit_charges;
                        $scope.formData.show_calc = response.result.rate_detail.show_calc;
                        $scope.formData.charge1 = response.result.rate_detail.charge1;
                        $scope.formData.equipment_rental = response.result.rate_detail.equipment_rental;
                        $scope.formData.video_sync = response.result.rate_detail.video_sync;
                        $scope.formData.processing_fee = response.result.rate_detail.processing_fee;
                        $scope.formData.color_exhibit_charge = response.result.rate_detail.color_exhibit_charge;
                        $scope.formData.hyperlinked_exhibit_charge = response.result.rate_detail.hyperlinked_exhibit_charge;

                        $rootScope.showLoader(false);

                        $scope.firm_cnt = response.result.firm_cnt;
                        $scope.negotiated_y = response.result.negotiated_y;
                        $scope.negotiated_n = response.result.negotiated_n;
                    } else {
                        $scope.formData.appear_rate = '';
                        $scope.formData.late_appear = '';
                        $scope.formData.Room = '';
                        $scope.formData.word_indexing_page_rate = '';
                        $scope.formData.rate = '';
                        $scope.formData.copy_rate = '';
                        $scope.formData.no_min_taken_charge = '';
                        $scope.formData.word_indexing_flat_rate = '';
                        $scope.formData.admin_fee = '';
                        $scope.formData.mini_script_per_page_rate = '';
                        $scope.formData.mini_script_flat_rate = '';
                        $scope.formData.postage = '';
                        $scope.formData.cd_charge = '';
                        $scope.formData.min_invoice_charge = '';
                        $scope.formData.web_charge_per_witness = '';
                        $scope.formData.video_conference_handling_and_connection_fee = '';
                        $scope.formData.reminder_charge = '';
                        $scope.formData.legalview_charge = '';
                        $scope.formData.deposition_insights_charge1 = '';
                        $scope.formData.deposition_insights_charge2 = '';
                        $scope.formData.deposition_insights_charge3 = '';
                        $scope.formData.digitization_charge = '';
                        $scope.formData.exhibit_charge = '';
                        $scope.formData.appearance_hourly = '';
                        $scope.formData.appearance_hourly_remote = '';
                        $scope.formData.appearance_hourly_nonsteno = '';

                        $scope.formData.appear_rate_remote = '';
                        $scope.formData.appear_rate_nonsteno = '';
                        $scope.formData.rate_remote = '';
                        $scope.formData.rate_nonsteno = '';
                        $scope.formData.copy_rate_remote = '';
                        $scope.formData.copy_rate_nonsteno = '';
                        $scope.formData.word_indexing_page_rate_remote = '';
                        $scope.formData.word_indexing_page_rate_nonsteno = '';
                        $scope.formData.non_appearance = '';
                        $scope.formData.cna = '';
                        $scope.formData.no_write = '';
                        $scope.formData.rough_ascii = '';

                        $scope.formData.realtime = '';
                        $scope.formData.exhibits_per_page = '';
                        $scope.formData.expedite_percentage_per_day = '';
                        $scope.formData.transcript_repository_storage = '';
                        $scope.formData.videographer_fee = '';
                        $scope.formData.video_archiving = '';
                        $scope.formData.web_amount = '';
                        $scope.formData.e_transcript = '';
                        $scope.formData.SOR = '';
                        $scope.formData.SORr = '';
                        $scope.formData.med_rate = '';
                        $scope.formData.exp_rate = '';
                        $scope.formData.daily_rate = '';
                        $scope.formData.immed_rate = '';

                        $scope.formData.lit_charges = '';
                        $scope.formData.show_calc = '';
                        $scope.formData.charge1 = '';
                        $scope.formData.equipment_rental = '';
                        $scope.formData.video_sync = '';
                        $scope.formData.processing_fee = '';
                        $scope.formData.color_exhibit_charge = '';
                        $scope.formData.hyperlinked_exhibit_charge = '';

                        $rootScope.showLoader(false);
                    }
                })
                .error(function(err) {
                    console.log(err);
                    $rootScope.showLoader(false);
                });
        };

        $scope.save = function(type){ 

            let title = 'ARE YOU SURE?';
            let txt = 'Are You Sure Want To Change Rates?';
            if($scope.isEditMode){
                var cnt = 0;
                if($scope.formData.update_firms_rates == 'yes'){
                    cnt = $scope.negotiated_y;
                    title = "TOTAL <span style='color:red'> "+ $scope.negotiated_y +" FIRM(S)</span> HAS/HAVE THIS RATE TYPE WITH <span style='color:red'>NEGOTIATED YES</span>";
                }else if($scope.formData.update_firms_rates == 'no'){
                    cnt = $scope.negotiated_n;
                    title = "TOTAL <span style='color:red'> "+ $scope.negotiated_n +" FIRM(S)</span> HAS/HAVE THIS RATE TYPE WITH <span style='color:red'>NEGOTIATED NO</span>";
                }else{
                    cnt = $scope.firm_cnt;
                    title = "TOTAL <span style='color:red'> "+ $scope.firm_cnt +" FIRM(S)</span> HAS/HAVE THIS RATE TYPE";
                }
                if(cnt > 0){
                    title += ', THAT ALL WILL BE AFFECTED';
                    txt += " - It will take upto 5 minutes to process..";
                }
            }
            swal({
                title: title,
                text: txt,
                html:true,
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#09375f",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: true,
                closeOnCancel: true
            },
            function (isConfirm) {
                if (isConfirm) {         
                    $rootScope.showLoader(true);
                    $scope.formData.id = $state.params.id
                    commonFactory.put('/api/rates/updateRateDetails/'+ type, $scope.formData)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        Notification.success(response);
                        $state.go('rate_tables.rate_list');
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                        Notification.danger(err);
                    });
                }
            });      
        }
 
   
    }]);
