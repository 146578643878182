angular.module('deitz')
    .controller('createrateController', ['$scope', '$rootScope', '$state', 'Notification', '$stateParams', '$uibModal', 'rateFactory','$filter', 'commonFactory', function($scope, $rootScope, $state, Notification, $stateParams, $uibModal, rateFactory,$filter, commonFactory) {

        $scope.rateData = {};
        $scope.locations = [];
        $scope.rates = [];
        $scope.formSubmit = false; 
        $scope.bread_title ="Add";

        $scope.validations = rateFactory.validations;
        $scope.messages = rateFactory.messages;
        $scope.openChat = false;

        //Getting all rates from rates table.
        rateFactory.get('/api/rates')
            .success(function(response) {
                if (response.result && response.result.rates) {
                    $scope.rates = response.result.rates;
                    $scope.rate_details = response.result.rate_details;
                }
            })
            .error(function(err) {});


        $scope.checkAlreadyCreated = function(option){

            return $scope.rate_details.indexOf(option.id)!= "-1";
        };

        commonFactory.post('/api/resource-list-by-type',{rep_typ:'S'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
            }
        });

        $scope.refreshRateCardGroup = function(){
            $rootScope.showLoader(true);
            commonFactory.get('/api/rateCardGroups').success(function (response) {
                if (response.result) {
                    $scope.rateCardGroups = response.result.rateCardGroup;
                }
                $rootScope.showLoader(false);
            }).error(function (err) {
                $rootScope.showLoader(false);
            }); 
        }
        $scope.refreshRateCardGroup();

        $scope.ratecardgroupModal = function (id) {
            $rootScope.showLoader(true);
            if (id) {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/rate_card_group/add/rateCardGroupModal.html',
                    controller: 'editratecardgroupController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        },
                        ratecardgroupId: function () {
                            return id;
                        }
                    }
                });
            }
            else {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/rate_card_group/add/rateCardGroupModal.html',
                    controller: 'addratecardgroupController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        }
                    }
                });
            }
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function (id) {
                if (id) {
                    $scope.refreshRateCardGroup();
                    $scope.rateData.rate_card_group_id = id;
                }

            }, function () {
                
            });
        };

        //Save rates with location into table
        $scope.rateCreateSubmit = function(rateData, isValid) {
            $scope.formSubmit = true;
            if (isValid) {

                // if user checked word_indexing_flat_rate then we need to set rate value to word_indexing_flat_rate.
                rateData.In_WordIndexingPerPage = rateData.In_WordIndexingPerPage ? rateData.In_RATE : 0;
                rateData.In_MiniScriptPerPage = rateData.In_MiniScriptPerPage ? rateData.In_RATE : 0;

                rateFactory.post('/api/rates', rateData)
                    .success(function(response) {
                        if (response && response.result && response.error) {
                            Notification.error(response.result.message);
                        } else{
                            Notification.success("Rates added successfully");
                            $state.go("rates.browse");
                        }
                    })
                    .error(function(err) {
                        Notification.error(err.result.message);
                    });
            }
        }

        $(window).bind('keydown', function(event) {

            if (event.ctrlKey || event.metaKey) {
                switch (String.fromCharCode(event.which).toLowerCase()) {

                    case 's':

                        event.preventDefault();

                        $scope.rateCreateForm.$setSubmitted();
                        $scope.$apply();

                        $scope.rateCreateSubmit($scope.rateData, $scope.rateCreateForm.$valid);

                        break;
                }
            }
        });

        $scope.createOption = function(term) {

            $scope.$apply(function() {
                // we are set the new id = -1 because its new rate which will first add into rate table
                var new_item = $filter('filter')($scope.rates, {id: -1})[0];
                if(new_item){
                    //get the index
                    var index = $scope.rates.indexOf(new_item);
                    $scope.rates[index]= {id : -1, name: term};
                }
                else{
                    new_item = {id : -1, name: term};
                    $scope.rates.push(new_item);
                }
                $scope.rateData.rate_id = -1;
                $scope.rateData.rate_name = term;
            });
        }

    }]);
