angular.module('deitz')
    .controller('editrateController', ['$scope', '$rootScope', '$http', 'apiUrl', '$state', 'Notification', '$stateParams', '$uibModal',  'rateFactory','$timeout', 'commonFactory',
        function($scope, $rootScope, $http, apiUrl, $state, Notification, $stateParams, $uibModal, rateFactory, $timeout, commonFactory) {

        $scope.rateData = {};
        //$scope.locations = [];
        // $scope.rates = [];
        $scope.formSubmit = false;
        $scope.bread_title ="Edit";

        $scope.validations = rateFactory.validations;
        $scope.messages = rateFactory.messages;

        if ($state.params && $state.params.id) {
            rateFactory.get('/api/rates/' + $state.params.id + '/edit')
                .success(function(response) {
                    if (response.result && response.result.rate_edit_detail) {

                        $scope.rateData = response.result.rate_edit_detail || {};

                        $scope.rateData.salesperson_id = [];
                        angular.forEach($scope.rateData.account_executives,function(value, key){
                            $scope.rateData.salesperson_id.push(value.salesperson_id);
                        });
                        
                        $scope.rateData.billing_claim_representative = angular.copy($scope.rateData.claim_representative || null);

                        $scope.rateData.rate_card_group_id = (response.result.rate_edit_detail.rate_card_group_id) ? response.result.rate_edit_detail.rate_card_group_id.toString() : null;
                        // Need to convert into boolean because we are using checkbox, If it checked that means we are using word_indexing_flat_rate value of rate value
                        // $scope.rateData.In_WordIndexingPerPage = ($scope.rateData.In_WordIndexingPerPage && $scope.rateData.In_WordIndexingPerPage > 0)?true:false;
                        $scope.rateData.In_MiniScriptPerPage = ($scope.rateData.In_MiniScriptPerPage && $scope.rateData.In_MiniScriptPerPage > 0)?true:false;
                    }
                })
                .error(function(err) {});
        }

        commonFactory.post('/api/resource-list-by-type',{rep_typ:'S'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
            }
        });

        // //Getting all locations from locations table.
        // rateFactory.get('/api/locations')
        //     .success(function(response) {
        //         if (response.result && response.result.data) {
        //             $scope.locations = response.result.data;
        //         }
        //     })
        //     .error(function(err) {});

        // //Getting all rates from rates table.
        // rateFactory.get('/api/rates')
        //     .success(function(response) {
        //         if (response.result && response.result.rates) {
        //
        //             $timeout(function () {
        //                 $scope.rate_details = response.result.rate_details;
        //                 if($scope.rateData){
        //                     var i = $scope.rate_details.indexOf(parseInt($scope.rateData.rate_id));
        //                     if(i != -1) {
        //                         $scope.rate_details.splice(i, 1);
        //                     }
        //                 }
        //                 $scope.rates = response.result.rates;
        //             },1000);
        //
        //         }
        //     })
        //     .error(function(err) {});

        // $scope.checkAlreadyCreated = function(option){
        //
        //     return $scope.rate_details.indexOf(option.id)!= "-1";
        //
        // };

        $scope.searchClaimRepName = function (val) {
            return $http.post(apiUrl + '/api/search-claimrep-name', {query_string: val}).then(function (response) {
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };

        $scope.selectClaimRepName = function ($item, $model, $label, $event) {
            $scope.rateData.billing_claim_representative = angular.copy($item);
            $scope.rateData.claim_rep_id = $scope.rateData.billing_claim_representative.ClaimRepID;
        };

        $scope.refreshRateCardGroup = function(){
            $rootScope.showLoader(true);
            commonFactory.get('/api/rateCardGroups').success(function (response) {
                if (response.result) {
                    $scope.rateCardGroups = response.result.rateCardGroup;
                }
                $rootScope.showLoader(false);
            }).error(function (err) {
                $rootScope.showLoader(false);
            }); 
        }
        $scope.refreshRateCardGroup();

        $scope.ratecardgroupModal = function (id) {
            $rootScope.showLoader(true);
            if (id) {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/rate_card_group/add/rateCardGroupModal.html',
                    controller: 'editratecardgroupController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        },
                        ratecardgroupId: function () {
                            return id;
                        }
                    }
                });
            }
            else {
                var modalInstance = $uibModal.open({
                    templateUrl: 'modules/code_tables/rate_card_group/add/rateCardGroupModal.html',
                    controller: 'addratecardgroupController',
                    resolve: {
                        fromModal: function () {
                            return true;
                        }
                    }
                });
            }
            modalInstance.opened.then(function () {
                $rootScope.showLoader(false);
            });

            modalInstance.result.then(function (id) {
                if (id) {
                    $scope.refreshRateCardGroup();
                    $scope.rateData.rate_card_group_id = id;
                }

            }, function () {
                
            });
        };

        //Save rates with location into table
        $scope.rateCreateSubmit = function(rateData, isValid) {
            $scope.formSubmit = true;
            if (isValid) {

                // if user checked word_indexing_flat_rate then we need to set rate value to word_indexing_flat_rate.
                // rateData.In_WordIndexingPerPage = rateData.In_WordIndexingPerPage ? rateData.In_RATE : 0;
                rateData.In_MiniScriptPerPage = rateData.In_MiniScriptPerPage ? rateData.In_RATE : 0;

                $rootScope.showLoader(true);



                rateFactory.put('/api/rates/' + $state.params.id, rateData)
                    .success(function(response) {
                        $rootScope.showLoader(false);
                        if (response && response.result && response.error) {
                            Notification.error(response.result.message);
                        } else{
                            Notification.success("Rates updated successfully");
                            $state.go("rates.browse");
                        }
                    })
                    .error(function(err) {
                        $rootScope.showLoader(false);
                    });
            }
        }

        $(window).bind('keydown', function(event) {
            if($rootScope.havePermission('rates','update')){
                if (event.ctrlKey || event.metaKey) {
                    switch (String.fromCharCode(event.which).toLowerCase()) {

                        case 's':

                            event.preventDefault();

                            $scope.rateCreateForm.$setSubmitted();
                            $scope.$apply();

                            $scope.rateCreateSubmit($scope.rateData, $scope.rateCreateForm.$valid);

                            break;
                    }
                }
            }
        });

    }]);
