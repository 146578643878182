angular.module('deitz').controller('firmRatesController',[
    '$scope', '$http', 'apiUrl', '$rootScope', '$state', 'commonFactory', 'firmFactory', 'Notification', 'DTOptionsBuilder', 'DTColumnBuilder', '$compile', '$uibModal', '$window',
    function(
        $scope, $http, apiUrl, $rootScope, $state, commonFactory, firmFactory, Notification, DTOptionsBuilder, DTColumnBuilder, $compile, $uibModal, $window
	){
	
    	$scope.selectAll = false;
    	$scope.selectedFirmArray = [];
        $scope.totalQueueFirms = 0;
        
        $scope.isAuthenticated = false;
        if($rootScope.havePermission('allow_access_without_password','allow.change.rate.for.selected')){
            $scope.isAuthenticated = true;
        }
        $scope.filterObj = {};
        $scope.formData = { 
            includeRegularRates : false,
            includeOtherRates : false,
            includeContract : false,
            excludeContract : false,
            lastCalledInDate : ''
        }; 

        $scope.rackRateId = '';

        $http.get(apiUrl + '/api/getBusinessUnit').then(function(response) { 
            $scope.BusinessUnitsList = response.data.result.data;  
            $scope.BusinessUnitsList.unshift({name:'All'});
        });
        $scope.selectedAttorneys = [];
        $scope.selectedBusinessUnitIDs = [];
        var selectedBusinessUnitIDs =  JSON.parse(localStorage.getItem('selectedBusinessUnitIDs'));
        if(selectedBusinessUnitIDs && selectedBusinessUnitIDs.length){
            $scope.selectedBusinessUnitIDs = selectedBusinessUnitIDs
        }else{
            $rootScope.auth_user.roles_businessunits.forEach(function (e) {
                $scope.selectedBusinessUnitIDs.push(e.business_unit_id)
            });
        }
        
        $scope.selectedFirmArray = JSON.parse(localStorage.getItem('firmRateBatch'));
        if($scope.selectedFirmArray){
            $scope.totalQueueFirms = $scope.selectedFirmArray.length;
        }

        $scope.clearBatch = function(){
            $scope.selectedFirmArray = [];
            $scope.totalQueueFirms = 0; 
            localStorage.setItem('firmRateBatch',JSON.stringify($scope.selectedFirmArray));    
            $scope.reloadData();    
        };

        $scope.ratesToFilter = {};
        $scope.jobCountFilter = {};
        $scope.clientBilledFilter = {};
        $scope.jobBilledFilter = {};

        $scope.noOfRatesToFilter = [{}];

        $scope.firmRates = firmFactory.firmRates;        

        $scope.add_firm_rates = function(){            
            $scope.noOfRatesToFilter.push({});
        };

        $scope.remove_firm_rates = function(index){

            $scope.noOfRatesToFilter.splice(index, 1);
            var i = 0; 
            var reindex_firm_rates = {};
            angular.forEach($scope.ratesToFilter,function(value, key){
                if(key != index){  
                    reindex_firm_rates[i] = value;
                    i++;
                }                
            });
            $scope.ratesToFilter = reindex_firm_rates;
        }

        $http.get(apiUrl + '/api/rates/getFirmRateList').then(function(response) { 
            $scope.RateList = response.data.result.data;  
            $scope.RateList.unshift({NAME:'Please Select', ratesID: ''});
        });

        $scope.dtInstance = {};
        $scope.compileDatatable = function(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }; 

        $scope.clearFilter = function(){
            $scope.jobCountFilter = {};
            $scope.clientBilledFilter = {};
            $scope.jobBilledFilter = {};
        }

        $scope.reloadData = function() { 
            $scope.dtInstance.rerender();
        }; 

        $scope.resetSearch = function(){
            $scope.selectedGroups = [];
            $scope.selectedSourcesId = [];
            $scope.selectedAttorneys = [];
            
            $scope.dtInstance.DataTable.state.clear();
            $scope.dtInstance.rerender();

        };

        $scope.currencyFormat = function(data, type, meta, meta){
            if(parseFloat(data) >= 0){
                return '$'+parseFloat(data).toFixed(2);
            }
            if(parseFloat(data) < 0){
                return '-$'+ parseFloat(Math.abs(data)).toFixed(2);
            }
        };

        $http.get(apiUrl + '/api/get_source').then(function(response) { 
            $scope.sourcesList = response.data.data;       
        }); 

        commonFactory.get('/api/get-firm-groups')
        .success(function(response) {
            $rootScope.showLoader(false);
            $scope.firmGroups = response.result;
        })
        .error(function(err) {
            $rootScope.showLoader(false);
        });

        //Getting Attorneys list
        $scope.attorneysList = function(val) {
            $scope.searchingAtt = true;
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function(response) {
                $scope.searchingAtt = false;
                return response.data.result.map(function(item) {
                    var department = item.department_name ? ' || '+ item.department_name : '';
                    item.NAME = item.NAME + department;
                    return item;
                });
            });
        };

        $scope.groupChange = function(){
            
            var groups = [];    
            angular.forEach($scope.selectedGroups, function(value, key) {
               groups[key] = value.group_name;
            });
            
            // get firms of selected groups
            $rootScope.showLoader(true);
            commonFactory.post('/api/get-firms-of-selected-groups',{groups:groups})
            .success(function(response) {
                $rootScope.showLoader(false);
                $scope.selectedAttorneys = response.result;
                $scope.reloadData();
            })
            .error(function(err) {
                $rootScope.showLoader(false);
            });

        };

        $scope.dtOptions = DTOptionsBuilder.newOptions()
            .withDataProp('data')
            .withOption('ajax', function (data, callback, settings) {
                
                data.includeRegularRates = $scope.formData.includeRegularRates; 
                data.includeOtherRates = $scope.formData.includeOtherRates; 
                data.includeContract = $scope.formData.includeContract;
                data.excludeContract = $scope.formData.excludeContract;
                
                if(typeof $scope.formData.lastCalledInDate != "undefined" && $scope.formData.lastCalledInDate != ""){
                    data.lastCalledInDate = moment($scope.formData.lastCalledInDate).format('YYYY-MM-DD');           
                }

                data.selectedBusinessUnitIDs = $scope.selectedBusinessUnitIDs.filter(Boolean);
                localStorage.setItem('selectedBusinessUnitIDs', JSON.stringify($scope.selectedBusinessUnitIDs.filter(Boolean)));

                data.selectedSourcesIDs = $scope.selectedSourcesId;

                data.selectedAttorneyIDs = [];    
                angular.forEach($scope.selectedAttorneys, function(value, key) {
                    data.selectedAttorneyIDs[key] = value.id;
                });

                data.where = {
                    rates : $scope.ratesToFilter,
                    jobsCount : $scope.jobCountFilter,
                    clientBilled : $scope.clientBilledFilter,
                    jobBilled : $scope.jobBilledFilter,
                };

                angular.forEach(data.columns,function(value, key){
                    data.columns[key].search.value = data.columns[key].search.value.replace(/ /g, "%");
                });

                commonFactory.showDataTable('/api/show-firm-rates-data-table', data)
                .success(function(res) {
                    if (res) {
                        $rootScope.showLoader(false); 
                        $scope.selectAll = true;
                        $scope.selectedFirm = {};
                        $scope.filterObj = data;
                        callback(res);
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                });
            })
            .withLanguage({
                "sEmptyTable": "NO ATTORNEY FIRMS AVAILABLE IN TABLE",
                "sProcessing": "<img src='img/loading_bar.gif'/>",
                "sSearch": "",
                "sZeroRecords": "NO MATCHING ATTORNEY FIRMS FOUND",

            })
            .withOption('stateSave',true) 
            .withOption('processing', true)
            .withOption('serverSide', true) 
            .withPaginationType('simple_numbers')
            .withOption('searchDelay', 500)
            // .withOption('lengthMenu',[[10, 25, 50, 100, 250, 500, -1], [10, 25, 50, 100, 250, 500, "All"]])
            .withOption('lengthMenu',[[10, 25, 50, 100, 250, 500], [10, 25, 50, 100, 250, 500]])
            .withDisplayLength(10)  
            .withOption('order', [1, 'asc'])
            .withOption('createdRow', $scope.compileDatatable)
            .withOption('headerCallback', function (header) {
                // Use this headerCompiled field to only compile header once
                if (!$scope.headerCompiled) {
                    $compile(angular.element(header).contents())($scope);
                }
            }) 
            .withLightColumnFilter({
                '1': {
                    type: 'text'
                },
                '5': {
                    type: 'text'
                },
                '6': {
                    type: 'text'
                },
                '7': {
                    type: 'text'
                },
                '8': {
                    type: 'text'
                },
                '9': {
                    type: 'text'
                },
                '10': {
                    type: 'text'
                },
                '11': {
                    type: 'text'
                },
                '12': {
                    type: 'text'
                },
                '13': {
                    type: 'text'
                },
                '14': {
                    type: 'text'
                },
                '15': {
                    type: 'text'
                },
                '16': {
                    type: 'text'
                },
                '17': {
                    type: 'text'
                },
                '18': {
                    type: 'text'
                },
                '19': {
                    type: 'text'
                },
                '20': {
                    type: 'text'
                },
                '21': {
                    type: 'text'
                },
                '22': {
                    type: 'text'
                },
                '23': {
                    type: 'text'
                },
                '24': {
                    type: 'text'
                },
                '25': {
                    type: 'text'
                },
                '26': {
                    type: 'text'
                },
                '27': {
                    type: 'text'
                },
                '28': {
                    type: 'text'
                },
                '29': {
                    type: 'text'
                },
                '30': {
                    type: 'text'
                },
                '31': {
                    type: 'text'
                },
                '32': {
                    type: 'text'
                },
                '33': {
                    type: 'text'
                },
                '34': {
                    type: 'text'
                },
                '35': {
                    type: 'text'
                },
                '36': {
                    type: 'text'
                },
                '37': {
                    type: 'text'
                },
                '38': {
                    type: 'text'
                }
            });
 

        var titleHtml = '<input type="checkbox" class="layout-align-xl-center-center" icheck ng-model="selectAll" ng-change="toggleAll(selectAll)">';
        
        $scope.dtColumns = [];

        $scope.dtColumns.push( 
       		DTColumnBuilder.newColumn(null).withTitle(titleHtml).renderWith(function(data, type, full, meta){
       			
                var is_checked = false;
                var batchRateFirm = [];
                $scope.selectedFirmArray =  JSON.parse(localStorage.getItem('firmRateBatch'));

                 
                if($scope.selectedFirmArray){
                    
                    $scope.totalQueueFirms = $scope.selectedFirmArray.length;
                    batchRateFirm = $scope.selectedFirmArray.filter(function (item) {
                       return item == full.id ;                  
                    });
                
                }
                else{

                    $scope.totalQueueFirms = 0;

                } 
                if(batchRateFirm.indexOf(Number(full.id)) != -1 ){
                    
                    is_checked = true; 

                }    

                $scope.selectedFirm[full.id] = full;
       			$scope.selectedFirm[full.id].is_checked = is_checked;
       			
                if (!$scope.selectedFirm[full.id].is_checked) {
                    $scope.selectAll = false;
                }     

       			return '<input type="checkbox" icheck ng-model="selectedFirm['+ full.id +'].is_checked" ng-change="toggleFirm(selectedFirm['+ full.id +'].is_checked,'+ full.id +')">';

       		}).notSortable(),     
            DTColumnBuilder.newColumn('NAME').withOption('defaultContent', '').withTitle('Name').withOption("width", "10%").renderWith(function(data,type,full,meta){
 
                return '<a ui-sref="firms.manage.step_one({id : ' + full.id + '})" target="_blank">'+data+'</a>'
            }),
            DTColumnBuilder.newColumn('diaries_count').withOption('defaultContent', '').withTitle('Job Count'),
            DTColumnBuilder.newColumn('client_billed').withOption('defaultContent', '').withTitle('Client Billed').renderWith(function(data,type,full,meta){
                if(data == null){
                    return '$0.00';
                }
                return $scope.currencyFormat(data,type,full,meta);
            }),
            DTColumnBuilder.newColumn('job_billed').withOption('defaultContent', '').withTitle('Job Billed').renderWith(function(data,type,full,meta){
                if(data == null){
                    return '$0.00';
                }
                return $scope.currencyFormat(data,type,full,meta);
            }),
            DTColumnBuilder.newColumn('APP_RATE').withOption('defaultContent', '').withTitle('Appear Rate').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('APPL_RATE').withOption('defaultContent', '').withTitle('Late Appear').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('Room').withOption('defaultContent', '').withTitle('Room').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('RATE').withOption('defaultContent', '').withTitle('Rate').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('COPY_RATE').withOption('defaultContent', '').withTitle('Copy Rate').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('INV_MIN').withOption('defaultContent', '').withTitle('No Minutes Taken Charge').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('WordIndexingFlatRate').withOption('defaultContent', '').withTitle('Word Indexing Flat Rate').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('WordIndexingPerPage').withOption('defaultContent', '').withTitle('Word Indexing Per Page Rate').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('MiniScriptPerPage').withOption('defaultContent', '').withTitle('Mini Script Per Page').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('MiniScriptFlatRate').withOption('defaultContent', '').withTitle('Mini Script Flat Rate').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('POSTAGE').withOption('defaultContent', '').withTitle('Postage').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('CDCharge').withOption('defaultContent', '').withTitle('Cd Charge').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('INV_MINPG').withOption('defaultContent', '').withTitle('Minimum Invoice Charge').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('admin_fee').withOption('defaultContent', '').withTitle('Admin Fee').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('med_rate').withOption('defaultContent', '').withTitle('Med Charges').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('exp_rate').withOption('defaultContent', '').withTitle('Exp Charges').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('daily_rate').withOption('defaultContent', '').withTitle('Daily Charge').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('immed_rate').withOption('defaultContent', '').withTitle('Immed Charge').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('web_charge_per_witness').withOption('defaultContent', '').withTitle('Web Charge Per Witness').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('video_conference_handling_and_connection_fee').withOption('defaultContent', '').withTitle('Video Conference Handling And Connection Fee').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('reminder_charge').withOption('defaultContent', '').withTitle('Reminder Charge').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('legalview_charge').withOption('defaultContent', '').withTitle('LegalView Charge').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('deposition_insights_charge1').withOption('defaultContent', '').withTitle('Deposition Insights Charge 1').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('deposition_insights_charge2').withOption('defaultContent', '').withTitle('Deposition Insights Charge 2').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('deposition_insights_charge3').withOption('defaultContent', '').withTitle('Deposition Insights Charge 3').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('digitization_charge').withOption('defaultContent', '').withTitle('Digitization Charge').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('exhibit_charge').withOption('defaultContent', '').withTitle('B/W Exhibits').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('Charge2').withOption('defaultContent', '').withTitle('LIT Charge').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('Charge4').withOption('defaultContent', '').withTitle('Charge1').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('equipment_rental').withOption('defaultContent', '').withTitle('Equipment Rental').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('video_sync').withOption('defaultContent', '').withTitle('Video Sync').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('processing_fee').withOption('defaultContent', '').withTitle('Processing Fee').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('color_exhibit_charge').withOption('defaultContent', '').withTitle('Color Exhibits').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('hyperlinked_exhibit_charge').withOption('defaultContent', '').withTitle('Hyperlinked Exhibits').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('SOR').withOption('defaultContent', '').withTitle('SOR').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('SORr').withOption('defaultContent', '').withTitle('SORr').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('WEB_AMOUNT').withOption('defaultContent', '').withTitle('Exchange-Serve').renderWith($scope.currencyFormat),
            DTColumnBuilder.newColumn('SHOW_CALCS').withOption('defaultContent', '').withTitle('Show Invoice Detail')
        );
      
        $scope.toggleFirm = function(is_checked, id){
        	
        	$scope.selectedFirmArray = JSON.parse(localStorage.getItem('firmRateBatch'));

        	if($scope.selectedFirmArray == null){
                $scope.selectedFirmArray = [];
            }
        
            if(is_checked){    

                if($scope.selectedFirmArray.indexOf(Number(id)) == -1){
  
                    $scope.selectedFirmArray.push(Number(id));

                } 

            }else{ 
              
                $scope.selectedFirmArray =  $scope.selectedFirmArray.filter(function (item) {

                    return item != id; 
                
                }); 
                      
            }
            $scope.selectAll = true;
            angular.forEach($scope.selectedFirm, function(value, key){

                if(!value.is_checked){
                    $scope.selectAll = false;
                }; 
            });
            $scope.totalQueueFirms = $scope.selectedFirmArray.length;
            localStorage.setItem('firmRateBatch',JSON.stringify($scope.selectedFirmArray)); 
        }

        $scope.toggleAll = function(isSelectAll){
        	angular.forEach($scope.selectedFirm, function(value, key){
                
                $scope.selectedFirm[value.id].is_checked = isSelectAll;
                $scope.toggleFirm(isSelectAll,value.id);

            });  
        }

        $scope.askFirmRackRatesPass = function(){  
            swal({
                title: "PLEASE ENTER PASSWORD TO UNLOCK SCREEN",
                type: "input",
                inputType: "password",
                showCancelButton: true,
                closeOnConfirm: true
            }, function(inputValue) { 
                if (inputValue === false){
                    return false;
                } 
                if (inputValue === "") {
                    Notification.error("You need to write something!");
                    return false;
                }
                else {
                    $rootScope.showLoader(true);
                    commonFactory.post('/api/auth-firm-rates-pass', { 'password': inputValue })
                        .success(function(response) {
                            $rootScope.showLoader(false);
                            if (response['result']) {
                                if (response['result']['auth']) { 
                                    $scope.isAuthenticated = true;
                                    $scope.changeRatesForSelectedRackRates();
                                } else {
                                    Notification.error("Wrong Password!");
                                }
                            } else {
                                Notification.error("Something went wrong!");
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                            Notification.error("Something went wrong!");
                        });

                }
            });  
        } 

        $scope.changeRatesForSelectedRackRates = function(){
            if(!$scope.isAuthenticated){
                $scope.askFirmRackRatesPass();
            }else{
                $scope.selectedFirmArray =  JSON.parse(localStorage.getItem('firmRateBatch'));  

                if(!$scope.rackRateId){
                    Notification.error('PLEASE SELECT RACK RATE!');
                    return false; 
                }

                if($scope.selectedFirmArray == null){ 
                    $scope.selectedFirmArray = [];
                }
                if(!$scope.selectedFirmArray.length > 0){
                    Notification.error('THERE ARE NO FIRM SELECTED!');
                    return false; 
                }

                var txt = "THERE ARE <span style='color:red'> "+ $scope.selectedFirmArray.length +" </span> FIRMS SELECTED";   
                setTimeout(function () {       
                    swal({
                        title: txt,
                        text: "Are You Sure Want To Change Rates ?",
                        html:true,
                        type: "info",
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    },
                    function (isConfirm) {
                        if (isConfirm) { 
                            var data = {};    
                            $rootScope.showLoader(true);
                            data.ids = $scope.selectedFirmArray;  
                            data.rackRateId = $scope.rackRateId;                 
                            $http.post(apiUrl+'/api/update-firm-rates-by-rack',data).success(function(res){
                                $rootScope.showLoader(false);
                                if(res.error){
                                    Notification.error(res.result.message);
                                }else{
                                    $scope.rackRateId = '';
                                    Notification.success(res.result.message);
                                    $scope.reloadData();
                                }
                            });
                        }
                    });      

                }, 250);
            }
        }

        $scope.changeRatesForSelectedFirms = function(isAllButtonClick){
            
            if(!$scope.isAuthenticated){
               //passing isAllButtonClick flag to ask password function b'cus after authentication it will not return isAllButtonClick flag
               $scope.askFirmRatesPass(isAllButtonClick);

            }else{
 
                if(isAllButtonClick){
                    $scope.selectedFirmArray = [];
                    $scope.totalQueueFirms = 0; 
                    localStorage.setItem('firmRateBatch',JSON.stringify($scope.selectedFirmArray));    
                    $scope.reloadData();  
                    var txt = "<span style='color:red'> ALL </span> FIRMS ARE SELECTED";
                }else{

                    $scope.selectedFirmArray =  JSON.parse(localStorage.getItem('firmRateBatch'));  

                    if($scope.selectedFirmArray == null){ 
                        $scope.selectedFirmArray = [];
                    }
                    if(!$scope.selectedFirmArray.length > 0){
                       Notification.error('THREE ARE NO FIRM SELECTED !');
                       return false; 
                    }
                    var txt = "THERE ARE <span style='color:red'> "+ $scope.selectedFirmArray.length +" </span> FIRMS SELECTED";                    
                }

                $rootScope.showLoader(true);  
                var modalInstance = $uibModal.open({

                    templateUrl : 'modules/resources/templates/firm_rate_change.html',
                    controller : function($scope, $uibModalInstance){
                        $scope.firm_new_rates = {};
                        $scope.firm_rates = [{}];
                        $scope.isSubmitted = false;

                        $scope.firmRates = firmFactory.firmRates;  

                        $scope.closeModal = function(){

                            $uibModalInstance.close();
                        
                        }
                        $scope.add_firm_rates = function(){
                            
                            $scope.firm_rates.push({});

                        }
                        $scope.remove_firm_rates = function(index){

                            $scope.firm_rates.splice(index, 1);
                            var i = 0; 
                            var reindex_firm_rates = {};
                            angular.forEach($scope.firm_new_rates,function(value, key){
                                if(key != index){  
                                    reindex_firm_rates[i] = value;
                                    i++;
                                }
                                
                            });
                            $scope.firm_new_rates = reindex_firm_rates;
                        
                        }
                        $scope.save = function(isValid){   
                            $scope.isSubmitted = true;
                            if(isValid){

                                $uibModalInstance.close($scope.firm_new_rates);

                            }
                        
                        }

                    }, 
                    backdrop:false
                });

                modalInstance.opened.then(function(){
                    $rootScope.showLoader(false);
                });

                modalInstance.result.then(function (firm_new_rates) {
                    
                    if(firm_new_rates && !angular.equals(firm_new_rates, {})){ 
                        swal({
                            title: txt,
                            text: "Are You Sure Want To Change Rates ?",
                            html:true,
                            type: "info",
                            showCancelButton: true,
                            confirmButtonColor: "#09375f",
                            confirmButtonText: "Yes",
                            cancelButtonText: "No",
                            closeOnConfirm: true,
                            closeOnCancel: true
                        },
                        function (isConfirm) {
                            if (isConfirm) { 
                                var data = {};    
                                $rootScope.showLoader(true);
                                if(isAllButtonClick){
                                    data = $scope.filterObj;
                                    data.firm_new_rates = firm_new_rates;
                                    data.length = -1;
                                }else{
                                    data.ids = $scope.selectedFirmArray;
                                    data.firm_new_rates = firm_new_rates;
                                }
                        
                                $http.post(apiUrl+'/api/update-firm-rates',data).success(function(res){
                                    $rootScope.showLoader(false);
                                    if(res.error){
                                        
                                        Notification.error(res.result.message);
                                    
                                    }else{

                                        Notification.success(res.result.message);
                                        $scope.reloadData();
                                    }
                                });
                            }
                        });  
                    }

                });
            }      
            
        }

        $scope.askFirmRatesPass = function(isAllButtonClick){

            swal({
                title: "PLEASE ENTER PASSWORD TO CHANGE RATES", 
                type: "input",
                inputType: "password",
                showCancelButton: true,
                closeOnConfirm: true 
            }, function(inputValue) { 
                if (inputValue == false || inputValue === "") { 
                    return false
                }
                else {

                    $rootScope.showLoader(true);
                    commonFactory.post('/api/auth-firm-rates-pass', { 'password': inputValue })
                        .success(function(response) {
                            $rootScope.showLoader(false);
                            if (response['result']) {
                                if (response['result']['auth']) { 
                                    $scope.isAuthenticated = true;
                                    $scope.changeRatesForSelectedFirms(isAllButtonClick);
                                } else {
                             
                                    swal("Error !", "Wrong Password!", "error");
                                }
                            } else {
                                
                                swal("Oops !", "Something went wrong!", "error");
                            }
                        })
                        .error(function(err) {
                            $rootScope.showLoader(false);
                            swal("Oops !", "Something went wrong!", "error");
                        });
                }
            });
        }

        $scope.backupTable = function(){
            $rootScope.showLoader(true);
            commonFactory.post('/api/backup_tbl', { 'table': 'ATTORNEY_tbl' })
                .success(function(response) {
                    $rootScope.showLoader(false);
                    if(response.result && response.result.fileName){
                        // $window.location.href = apiUrl +'/api/download-backup-table?fileName='+response.result.fileName+'.sql';
                        $window.open(apiUrl +'/api/download-backup-table?fileName='+response.result.fileName+'.sql', '_blank'); 
                    } else{
                        swal("Oops !", "Something went wrong! Try again.", "error");
                    }
                })
                .error(function(err) {
                    $rootScope.showLoader(false);
                    swal("Oops !", "Something went wrong!", "error");
                });
        }
}]);

