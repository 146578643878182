angular.module('deitz').controller('commissionReportNewController', [
    '$scope','$state','$rootScope','$http','apiUrl','Notification','commonFactory', '$uibModal',
    function(
        $scope,$state,$rootScope,$http,apiUrl,Notification,commonFactory, $uibModal
    ){

        $scope.report = {
            startDate : moment().format('YYYY-MM-DD'),
            endDate : moment().format('YYYY-MM-DD')
        };

        $scope.selectedFirms = [];
        $scope.selectedJobs = [];
        $scope.jobs = [];
        $scope.calculatedData = [];
		$scope.salesOldCommissionData = {};

        $scope.reporterType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Salesperson'};

        $scope.title = "Commission Report (After Jan-2023)";

        $scope.itemsPerPage = 500;
        $scope.currentPage = 1;
        $scope.isSalesPersonLogin = false;
        //console.log($rootScope);
        // make hide profit information check box disabled when sales persion login.
        if($rootScope.isSalesLogin){
            console.log($rootScope);
            $scope.hideProfit = true;
            $scope.isSalesPersonLogin = true;
        }
        $scope.resetPagination = function (number=500){
            $scope.currentPage = 1; //reset to first paghe
            $scope.itemsPerPage = number;
            $scope.searchData();
        };
		$scope.changeCommissionRate = function (diary_salesman_id, diary_salesman_commission_percentage, diary_salesman_overhead_amt) {
            $rootScope.showLoader(true);
            var modalInstance = $uibModal.open({

                templateUrl : 'modules/code_tables/commission_report_new/commission_rate_change.html',
                controller : function($scope, $uibModalInstance){
                    $scope.isSubmitted = false;
					$scope.salesCommissionData = {};
					
                    $scope.salesCommissionData.id = diary_salesman_id;
                    $scope.salesCommissionData.commission_percentage = diary_salesman_commission_percentage;  
					$scope.salesCommissionData.overhead_amt = diary_salesman_overhead_amt;  

                    $scope.closeModal = function(){
                        $uibModalInstance.close();
                    }
                    $scope.save = function(isValid){   
                        $scope.isSubmitted = true;
						$rootScope.showLoader(true);
						commonFactory.post('/api/update_commission_details',$scope.salesCommissionData)
						.success(function(response) {
							if(response.error){
								Notification.error(response.result.message || 'Something went wrong!');
							} else{
								$scope.salesPersonsList = response.result.message;
							}
							$rootScope.showLoader(false);
						});
						$rootScope.showLoader(false);
						$uibModalInstance.close();
                    }

                }, 
                backdrop:false
            });
			modalInstance.opened.then(function(){
				$rootScope.showLoader(false);
			});
			modalInstance.closed.then(function(){
				$scope.searchData();
			});
			
        }

        $scope.numberWithCommas = function(x) {
            return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
        }

        //Getting Attorneys list
        $scope.attorneyslist = function(val) {
            $rootScope.showLoader(true);
            return $http.get(apiUrl + '/api/attorneySearchByName', {
                params: {
                    name: val
                }
            }).then(function(response) {
                $rootScope.showLoader(false);
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        //Getting Job list
        $scope.jobList = function(val) {
            $rootScope.showLoader(true);
            return $http.get(apiUrl + '/api/getInvoicesByJobNumber', {
                params: {
                    JOB_NO: val
                }
            }).then(function(response) {
                $rootScope.showLoader(false);
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        //Getting all resources by type.
        commonFactory.post('/api/resource-list-by-type',{rep_typ:'S'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
            }
            $rootScope.showLoader(false);
        });

        commonFactory.post('/api/resource-list-by-type',{rep_typ:'R'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.reporterList = response.result.resources;
            }
            $rootScope.showLoader(false);
        });

        commonFactory.post('/api/resource-list-by-type',{rep_typ:'T'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.typistList = response.result.resources;
            }
            $rootScope.showLoader(false);
        });
        
        //Getting all terms 
        $http.post(apiUrl + '/api/getAllterms').success(function(res){
            $scope.termsList = res.data;
        });

        //Getting all sources from source table.
        $http.get(apiUrl + '/api/get_source').then(function(response) { 
            $scope.sourcesList=response.data.data;       
        }); 

        //Getting all status of firm.
        $http.get(apiUrl + '/api/get-status-of-firm').success(function(response) { 
            $scope.statusList=response.result;       
        }); 

        $scope.searchData = function(){

            $scope.report.attorneyFirmIds = $scope.selectedFirms.map(function(a){
                return  a.id;
            });

            $scope.title = "Commission Report for Invoice : "+ moment($scope.report.startDate).format('MM/DD/YYYY') + " to " + moment($scope.report.endDate).format('MM/DD/YYYY');

            $scope.report.startDate = moment($scope.report.startDate).format('YYYY-MM-DD');
            $scope.report.endDate= moment($scope.report.endDate).format('YYYY-MM-DD');

            $scope.report.jobNos = $scope.selectedJobs.map(function(a){
                return  a.JOB_NO;
            });
            $scope.report.isSalesPersonLogin = $rootScope.isSalesLogin;
            
            $scope.report.offset = ($scope.currentPage - 1) * $scope.itemsPerPage;
            $scope.report.limit = $scope.itemsPerPage;

            $rootScope.showLoader(true);
            commonFactory.post('/api/commission-report', $scope.report)
                .success(function(response) {
                    
                    $scope.jobs = [];

                    $rootScope.showLoader(false);
                    if(response.error){
                        Notification.error(response.result.message || 'Something went wrong!');
                    } else{
                        $scope.calculatedData = response.result.calculatedData;
                        $scope.calculatedData.InvAmt_calculated = 0;
                        $scope.jobs =  response.result.jobs;
                        $scope.totalRecords = response.result.totalRecords;

                        if($scope.jobs.length == 0){
                            Notification.error('No records found!');
                        }
                    }
                })
                .error(function(err)
                {
                    $scope.jobs = [];
                    Notification.error('Something went wrong!');
                    $rootScope.showLoader(false);
                });
        };

        if($state.params.attId){
            commonFactory.get('/api/attorneys/' + $state.params.attId + '/edit').success(function(response){
                var firmObj = response.result.data;
                $scope.selectedFirms.push({
                    id : firmObj.id,
                    NAME :firmObj.NAME
                });
                $scope.searchData();
            });
        }

        $scope.getReportingSalesmanName = function(salespersons){
            var name = [];
            var i = 0;
            angular.forEach(salespersons,function(value, key){
                if(value.reptype && value.reptype.name){
                    name[i] = value.reptype.name;
                    i++;
                }
            });
            return name.join(', ');
        };

      

        $scope.getReportingSalesmanCommission = function(invoices, salesperson, commission_type){
            
            var commissoin = 0;
            var invoice_amt = 0;
            var commissionable_inv_amt = 0;
            var overhead_amt = 0;
            
            var commission_percentage = parseFloat(salesperson.commission_percentage);

            angular.forEach(invoices,function(value, key){
                invoice_amt = parseFloat(invoice_amt) + parseFloat(value.INV_AMT);
                
                if(commission_type == 'commissionable'){
                    
                    angular.forEach(value.additional_charges, function(addValue, addKey){
                        if(addValue.commissionable == 'Y'){
                            if(parseFloat(value.INV_AMT) > 0){
                                commissionable_inv_amt += parseFloat(addValue.charge);
                            }else{
                                commissionable_inv_amt -= parseFloat(addValue.charge);
                            }
                        }
                    });

                    angular.forEach(value.witness_data, function(wittValue, addKey){
                        if(parseFloat(value.INV_AMT) > 0){
                            commissionable_inv_amt += parseFloat(wittValue.line_pay);
                        }else{
                            commissionable_inv_amt -= parseFloat(wittValue.line_pay);
                        }
                    });

                }else{
                    
                    if(parseFloat(value.INV_AMT) > 0){
                        overhead_amt +=  parseFloat(salesperson.overhead_amt);
                    }else{
                        overhead_amt -=  parseFloat(salesperson.overhead_amt);
                    }
                    
                }

            });

            if(commission_type == 'commissionable'){
                commissoin = ((commissionable_inv_amt * commission_percentage) / 100);
            }else{
                commissoin = (((invoice_amt - overhead_amt) * commission_percentage) / 100);                
            }

            return commissoin.toFixed(2);
        };

        $scope.getReportingSalesmanTotalCommission = function(invoices, salespersons, commission_type){
            var totalCommission = 0;
            angular.forEach(salespersons,function(value, key){
                totalCommission = (parseFloat(totalCommission) + parseFloat($scope.getReportingSalesmanCommission(invoices, value, commission_type)));
            });
            return totalCommission.toFixed(2);
        }
}]);
