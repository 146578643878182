angular.module('deitz').controller('editInvoiceController', [
    '$scope', '$rootScope', '$http', 'apiUrl', '$state', '$timeout', '$filter', 'commonFactory', '$stateParams', '$uibModal',
    'invoiceFactory', 'Notification', '$window', 'anchorSmoothScroll', '$q',
    function (
        $scope, $rootScope, $http, apiUrl, $state, $timeout, $filter, commonFactory, $stateParams, $uibModal,
        invoiceFactory, Notification, $window, anchorSmoothScroll, $q
    ){

        $scope.title = "Edit An Invoice";
        $scope.breadcrumb_title = 'Edit';
        $scope.symbol = '$';
        $scope.fractionSize = 2;
        $scope.tabIndex = 1;
        $scope.notesEditable = false;
        $scope.userWantToEdit = true;
        $scope.isSubmitting = false;
        $scope.current_date = moment();
        $scope.isInvoicePriorMonth = true;
        $scope.isJobSelected = false;
        $scope.isFirmSelected = false;
        $scope.rateCardRate = null; 
        // History Pagination
        $scope.job_invoices = [];
        $scope.GroupArray = ["AMTRUST","GEICO","AIG","HOLLANDER"];
        $scope.holdreviewoptions = ['Yes', 'No'];
        $scope.holdnetsuiteoptions = ['Yes', 'No'];
        $scope.viewby = 20;
        $scope.totalItems = $scope.job_invoices.length;
        $scope.currentPage = 1;
        $scope.itemsPerPage = $scope.viewby;
        $scope.maxSize = 5; //Number of pager buttons to show
        $scope.reporterType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Account Executive'};
        $scope.ConnectionMethodArray = [{name: '',id:0},{name: 'Steno Remote',id:1}, {name: 'Steno In-Person',id:2}, {name: 'Digital Remote',id:3}, {name: 'Arbitration/Hearing/Trial',id:4}, {name: 'EUO',id:5}];
        $scope.selectedAddChargeGroupIDs = [];

        $scope.invoiceInEditMode = true;

        //Checking user role.
        if($rootScope.havePermission('allow_access_without_password','allow.diary.account.executive.lock.popup')){
            $scope.salesperson_source_locked = false;
        }else{
            $scope.salesperson_source_locked = true;
        }
        $scope.invoice_firm_locked = true;
        
        //assigning blank object to invoice variable. B'cus invoice date picker is not working.
        $scope.invoice = {};

        //assigning minimum and maximum date.
        var min_dt = moment().startOf('month').format('MM/DD/YYYY');
        var max_dt = moment().endOf('month').format('MM/DD/YYYY');

        //creating moment object of min and max date. B'cus min-date and max-date directive of datepicker will not work without moment object.
        $scope.min_inv_date = moment(min_dt);
        $scope.max_inv_date = moment(max_dt);


        $scope.setPage = function (pageNo) {
            $scope.currentPage = pageNo;
        };

        $scope.pageChanged = function () {

        };

        $scope.setItemsPerPage = function (num) {
            $scope.itemsPerPage = num;
            $scope.currentPage = 1; //reset to first paghe
        };

        $scope.number = 5;
        $scope.getNumber = function (num) {
            return new Array(num);
        };
         // get firms groups
         $rootScope.showLoader(true);
         commonFactory.get('/api/get-firm-groups')
         .success(function(response) {
             $rootScope.showLoader(false);
             $scope.firmGroups = response.result;
         })

        //Getting all additional charges group
        commonFactory.get('/api/additionalChargesGroups').success(function (response) {
            if (response.result) {
                $scope.additionalChargesGroups = response.result.additionalChargesGroup;
            }
        }).error(function (err) {
        });

        //Getting all resources by type.
        commonFactory.post('/api/resource-list-by-type',{rep_typ:'S'})
        .success(function(response) {
            if(response.error){
                Notification.error(response.result.message || 'Something went wrong!');
            } else{
                $scope.salesPersonsList = response.result.resources;
            }
        });

        $scope.attorney = [{id: null, NAME: "NOT PRESENT"}];
        $scope.commissions = [{text: "Yes", value: 'Yes'}, {text: "No", value: 'No'}];
        $scope.StipsArray = [{id: 'F', label: 'Federal'}, {id: 'S', label: 'State'},{id: 'Y', label: 'None'}];


        $scope.getInsuranceRateTable = function (attorney_id) {
            commonFactory.get('/api/rates')
                .success(function (response) {
                    if (response.result && response.result.rates) {
                        $scope.insuranceRates = response.result.rates;
                    }
                })
                .error(function (err) {
                });
        }
        $scope.getInsuranceRateTable();


        //Getting all sources for beling firm
        $http.get(apiUrl + '/api/get_source').then(function(response) {
            $scope.invoiceSources=response.data.data;
        });

        //Getting all additional resource types for reporters
        $scope.additionalResourceTypes={};
        $http.get(apiUrl + '/api/additionalinfo_list').then(function(response) { 
            $scope.additionalResourceTypes= response.data.result.data
         });

        // Get Additional Charges
        commonFactory.get('/api/get-charge-codes').success(function (response) {
            $scope.additional_charges = response.result;

        });

        commonFactory.get('/api/config/get-auto-partner').success(function (response) {
            if (response.result) {
                $scope.auto_partners = response.result;
            }
        });

        // Getting all invoice and transcript handling type 
        $http.get(apiUrl + '/api/invoice-and-transcript-handling').success(function(res){
            $scope.invoice_and_transcript_handling_methods = res.result;
        });

        // Getting mark ready audit log for a job
        $scope.getMarkReadyAuditLogByJob = function(JOB_NO){            
            $http.get(apiUrl + '/api/get-mark-ready-audit-log-by-job/'+JOB_NO).success(function (response) {
                $scope.markReadyAuditLogForAJob = response.result.data;
            });
        }

        $scope.invoice_id = $stateParams.invoice_id;

        $scope.reloadData = function () {
            commonFactory.post('/api/additionalChargesByGroup', {ids: $scope.selectedAddChargeGroupIDs}).success(function (response) {
                if (response.result) {
                    response.result.additionalCharges.forEach((additionalCharge) => {
                        $scope.addAdditionalChargeFromRateInfo(additionalCharge.CHG_CODE);
                    })
                }
            }).error(function (err) {
            });
        };

        $scope.$watch('selectedAddChargeGroupIDs', function (newValue, oldValue) {
            if ((newValue && oldValue) && (newValue.length < oldValue.length)) {
                let diff = oldValue.filter((val) => !newValue.some((e) => e === val));
                $scope.invoice.additional_charges = $scope.invoice.additional_charges.filter((additional_charge, index) => {
                    let flag = true;
                    angular.forEach(diff, function (value, key) {
                        if (flag){
                            if (additional_charge.additional_charge) {
                                flag = !additional_charge.additional_charge.additional_charge_group.some((e) => e.id == value);
                            } else {
                                flag = !additional_charge?.additional_charge_group?.some((e) => e.id == value);
                            }
                        }
                    });

                    return flag;
                });
                $scope.refreshCalculation();
            }
        });

        $scope.editInvoiceJobFetchData = function (invoice_id) {
            commonFactory.get('/api/invoice/' + invoice_id).success(function (response) {

                if(response.error){
                    $rootScope.showLoader(false);
                    Notification.error(response.result.message);
                    $state.go('invoice.browse');
                }

                $rootScope.showLoader(false);
                $scope.invoice = response.result.invoice;
                $scope.is_legacy_record = ($scope.invoice.job.is_legacy_record == 'yes') ? true : false;
                $scope.current_date = moment().format('M/YYYY');
                var dte_date = moment($scope.invoice.dte_invoice).format('M/YYYY');
                if($scope.current_date == dte_date && $scope.invoice.ready_for_netsuite_relese == 0){
                    $scope.isInvoicePriorMonth = false;
                }

                $scope.originalInvoiceData = angular.copy($scope.invoice);

                $scope.showJobInvoiceHistory($scope.invoice.JOB_NO);

                $scope.invoice.JOB_NO = response.result.invoice.job.JOB_NO;
                if($scope.invoice.JOB_NO){
                    $scope.isJobSelected = true;
                }
                $scope.invoice.group_name_data = response.result.invoice.group_name;
                $scope.invoice.CAPTION = response.result.invoice.job.CAPTION;
                //$scope.invoice.total_rep_pay_amt = round_to_number(response.result.invoice.job.REP_PAYAMT);
                //$scope.invoice.typ_pay_amt = round_to_number(response.result.invoice.job.TYP_PAYAMT);
                //$scope.invoice.typ_pay_amt = round_to_number(response.result.invoice.job.TYP_PAYAMT);
                $scope.invoice.total_job_adjustments = parseFloat((response.result.invoice.job.total_adjustment.length >0) ? response.result.invoice.job.total_adjustment[0].adjustmentAmount : 0.00);
                $scope.invoice.DATE_TAKEN = response.result.invoice.job.DATE_TAKEN;

                // collect resource wise job payment list
                $scope.getResourceWiseJobPayments(response.result.invoice.diary_data.job_resources);
                $scope.invoice.job_grand_total= round_to_number($scope.invoice.typ_pay_amt+$scope.invoice.total_rep_pay_amt+$scope.invoice.total_job_adjustments);

                // var updatedDate = $scope.invoice.updated_at || $scope.invoice.UpdatedDate;
                // $scope.invoice.updated_at = new Date(updatedDate);

                $scope.invoice.is_pulled_data = response.result.invoice.is_pulled_data == "1" ?true : undefined;

                $scope.diaryData = response.result.invoice.diary_data;

                if ($scope.diaryData.rate_card_group_id){
                    commonFactory.post('/api/rate/getRateGroupActiveRate', {rate_card_group: [$scope.diaryData.rate_card_group_id]})
                        .success(function (response) {
                            if (response.result && response.result.rates) {
                                $scope.insuranceRates = response.result.rates;
                            }
                        })
                        .error(function (err) {
                        });
                }

                $scope.invoice.IndexNumber = angular.copy($scope.diaryData.IndexNumber || "");
                $scope.invoice.orderingAttorneyFirms = {attorney_name: {}};

                $scope.invoice.orderingAttorneyFirms = angular.copy($scope.diaryData.attorney_firm);
                //$scope.invoice.orderingAttorneyFirms.attorney_name.NAME = $scope.diaryData.attorney_name.NAME;

                // For Ordering Firm
                $scope.getAttorneys($scope.diaryData.attorney_firm.id, function (attorneyData) {
                    $scope.orderingFirmAttorneys = angular.copy(attorneyData || {});
                    $scope.calculateInvoiceAmount();
                });

                // Set the Date filter
                // $scope.invoice.INV_DT = $scope.invoice.dte_invoice || $scope.invoice.created_at;
                var inv_dt = moment($scope.invoice.dte_invoice).format("MM/DD/YYYY");
                $scope.invoice.INV_DT = moment(inv_dt);                 
                $scope.invoice.INV_DT = ($scope.invoice.hold_for_review == 'Yes') ?  moment(moment().format("MM/DD/YYYY")) : $scope.invoice.INV_DT;
                $scope.invoice.hold_for_netsuite = ($scope.invoice.hold_for_netsuite == 1) ? $scope.invoice.hold_for_netsuite = 'Yes' : $scope.invoice.hold_for_netsuite = 'No';                         

                $scope.invoice.ordering_attname_id = angular.copy(parseInt($scope.diaryData.attname_id) || null);
                $scope.invoice.HELD_AT1 = angular.copy($scope.diaryData.HELD_AT1);
                $scope.invoice.total_job_invoices_amount = angular.copy($scope.diaryData.total_job_invoices_amount);
                $scope.invoice.total_job_amount = angular.copy($scope.diaryData.total_job_invoices_amount);
                $scope.invoice.CLM_NOTE = angular.copy($scope.diaryData.CLM_NOTE);
                $scope.invoice.direct_billing_notes = angular.copy($scope.diaryData.direct_billing_notes);

                $scope.invoice.bu_gm_value = $scope.diaryData.businessunit.gm_value;

                $scope.invoice.ordering_claim_representative = angular.copy($scope.diaryData.claim_representative || {});
                $scope.invoice.billing_claim_representative = $scope.invoice.claim_representative || null;
                // $scope.invoice.billing_claim_representative = angular.copy($scope.invoice.claim_representative || {});

                $scope.invoice.ORDERING_CLM_NO = angular.copy($scope.diaryData.CLM_NO);
                $scope.invoice.ORDERING_FILE_NO = angular.copy($scope.diaryData.FILE_NO);

                $scope.invoice.BILLING_CLM_NO = $scope.invoice.ClaimNumber;
                $scope.invoice.claim_number = $scope.invoice.ClaimNumber;
                
                $scope.invoice.BILLING_FILE_NO = $scope.invoice.FILE_NO;

                $scope.invoice.source_id = $scope.diaryData.source_id;

                $scope.attorneyFirmData = response.result.invoice.attorney_firms;
                $scope.invoice.attorneyFirms = angular.copy($scope.attorneyFirmData);

                //assigning status and beeper(autopartner) of firm.
                $scope.firm_status = $scope.invoice.attorneyFirms.STATUS;
                $scope.assigningAutoPartner($scope.invoice.attorneyFirms);
                
                if($scope.invoice.attorneyFirms){
                    $scope.isFirmSelected = true;
                }

                if($scope.diaryData.diary_reporting_salesman.length){
                    var reportingSalesmanIds = [];
                    angular.forEach($scope.diaryData.diary_reporting_salesman,function(value, key){
                        reportingSalesmanIds[key] = value.sales_reptyp_id;
                    });
                    $scope.invoice.reporting_sales_ids = reportingSalesmanIds;
                }else{
                    $scope.invoice.reporting_sales_ids = [];
                }

                if($scope.diaryData.diary_records_salesman.length){
                    var recordsSalesmanIds = [];
                    angular.forEach($scope.diaryData.diary_records_salesman,function(value, key){
                        recordsSalesmanIds[key] = value.sales_reptyp_id;
                    });
                    $scope.invoice.records_sales_ids = recordsSalesmanIds;
                }else{
                    $scope.invoice.records_sales_ids = [];
                }

                $scope.getAttorneyRates($scope.invoice.attorneyFirms.id,true);

                // set the rate_id what was before while saving the data
                $scope.invoice.attorneyFirms.rate_id = $scope.invoice.rate_id;
           

                //Assign the variable which will save directly into database table
                //Assign the rate information to invoice object from AttorneyFirms object

                $scope.invoice.Room = parseFloat($scope.invoice.Room || 0.00);
                $scope.invoice.RATE = parseFloat($scope.invoice.RATE || 0.00);
                $scope.invoice.med_rate = parseFloat($scope.invoice.med_rate || 0.00);
                $scope.invoice.MiniScriptPerPage = parseFloat($scope.invoice.MiniScriptPerPage || 0.00);
                $scope.invoice.COPY_RATE = parseFloat($scope.invoice.COPY_RATE || 0.00);
                $scope.invoice.exp_rate = parseFloat($scope.invoice.exp_rate || 0.00);
                $scope.invoice.WEB_AMOUNT = parseFloat($scope.invoice.WEB_AMOUNT || 0.00);
                $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.WordIndexingPerPage || 0.00);
                $scope.invoice.APP_RATE = parseFloat($scope.invoice.APP_RATE || 0.00);
                $scope.invoice.daily_rate = parseFloat($scope.invoice.daily_rate || 0.00);
                $scope.invoice.CDCharge = parseFloat($scope.invoice.CDCharge || 0.00);
                $scope.invoice.MiniScriptFlatRate = parseFloat($scope.invoice.MiniScriptFlatRate || 0.00);
                $scope.invoice.INV_MINPG = parseFloat($scope.invoice.INV_MINPG || 0.00);
                $scope.invoice.immed_rate = parseFloat($scope.invoice.immed_rate || 0.00);
                $scope.invoice.ETranscript = parseFloat($scope.invoice.ETranscript || 0.00);
                $scope.invoice.WordIndexingFlatRate = parseFloat($scope.invoice.WordIndexingFlatRate || 0.00);
                $scope.invoice.INV_MIN = parseFloat($scope.invoice.INV_MIN || 0.00);
                $scope.invoice.SOR = parseFloat($scope.invoice.SOR || 0.00);
                $scope.invoice.rate_id = $scope.invoice.rate_id || null;
                $scope.invoice.rate_connection_method = $scope.invoice.rate_connection_method || 1;
                $scope.invoice.SHOW_CALCS = ($scope.invoice.SHOW_CALCS || 'No').toLowerCase();
                $scope.invoice.Charge2 = parseFloat($scope.invoice.Charge2 || 0.00);
                $scope.invoice.POSTAGE = parseFloat($scope.invoice.POSTAGE || 0.00);
                $scope.invoice.admin_fee = parseFloat($scope.invoice.admin_fee || 0.00);
                $scope.invoice.INV_NOTE = $scope.invoice.NOTES;
                $scope.mark_ready = $scope.diaryData.mark_ready == "1" ? true : undefined;
                $scope.getMarkReadyAuditLogByJob($scope.invoice.JOB_NO);
                $scope.invoice.web_charge_per_witness = parseFloat($scope.invoice.web_charge_per_witness || 0.00);
                $scope.invoice.video_conference_handling_and_connection_fee = parseFloat($scope.invoice.video_conference_handling_and_connection_fee || 0.00);
                $scope.invoice.reminder_charge = parseFloat($scope.invoice.reminder_charge || 0.00);
                $scope.invoice.legalview_charge = parseFloat($scope.invoice.legalview_charge || 0.00);
                $scope.invoice.digitization_charge = parseFloat($scope.invoice.digitization_charge || 0.00);
                $scope.invoice.exhibit_charge = parseFloat($scope.invoice.exhibit_charge || 0.00);
                $scope.invoice.equipment_rental = parseFloat($scope.invoice.equipment_rental || 0.00);
                $scope.invoice.video_sync = parseFloat($scope.invoice.video_sync || 0.00);
                $scope.invoice.processing_fee = parseFloat($scope.invoice.processing_fee || 0.00);
                $scope.invoice.color_exhibit_charge = parseFloat($scope.invoice.color_exhibit_charge || 0.00);
                $scope.invoice.hyperlinked_exhibit_charge = parseFloat($scope.invoice.hyperlinked_exhibit_charge || 0.00);
                $scope.invoice.exhibits_per_page = parseFloat($scope.invoice.exhibits_per_page || 0.00);
                $scope.invoice.no_transcript_arbitration_appear_rate = parseFloat($scope.invoice.no_transcript_arbitration_appear_rate || 0.00);
                $scope.invoice.no_transcript_depo_appear_rate = parseFloat($scope.invoice.no_transcript_depo_appear_rate || 0.00);
                $scope.invoice.no_transcript_euo_appear_rate = parseFloat($scope.invoice.no_transcript_euo_appear_rate || 0.00);
                $scope.invoice.cancellation = parseFloat($scope.invoice.cancellation || 0.00);
                $scope.invoice.video_cancellation = parseFloat($scope.invoice.video_cancellation || 0.00);
                $scope.invoice.medical_technical_expert = parseFloat($scope.invoice.medical_technical_expert || 0.00);
                // $scope.invoice.attorneyFirms = angular.copy($scope.invoice.orderingAttorneyFirms);


                // Populate the additional charge from existing attorney firm charges
                angular.forEach($scope.invoice.additional_charges, function ($item, index) {
                    angular.forEach($item.additional_charge.additional_charge_group, function ($additionalChargesGroup, sub_index) {
                        $scope.selectedAddChargeGroupIDs.push($additionalChargesGroup.id);
                    });
                    if($item.additional_charge){
                        $scope.invoice.additional_charges[index].CHG_CODE = $item.additional_charge.CHG_CODE;
                        $scope.invoice.additional_charges[index].ChargeID = $item.additional_charge.ChargeID;
                        $scope.addAdditionalChargeFromRateInfo($item.additional_charge.CHG_CODE, $item.AMT,false, $item.invchgID);
                    }else{
                        $scope.invoice.additional_charges.splice(index,1);
                    }
                });
                $scope.selectedAddChargeGroupIDs = [...new Set($scope.selectedAddChargeGroupIDs)];
                // For Billing firm              
                
                $scope.getAttorneys_invoice($scope.invoice.attorneyFirms.id, function (attorneyData) {

                    $scope.attorney = [{id: null, NAME: "NOT PRESENT"}];
                    $scope.attorney = $scope.attorney.concat(attorneyData || {});
                    // $scope.attorney = angular.copy(attorneyData || {});
                    // $scope.invoice.billing_claim_representative = angular.copy({});
                    $scope.mainAttorney = angular.copy(attorneyData || {});
                    $scope.invoice.attname_id = parseInt($scope.invoice.attorneyname_id) || null;
                });

                $timeout(function(){
                    $scope.witnesslist($scope.invoice.JOB_NO);
                },1500);

                $scope.getInvoiceNotes();


            }).error(function (error) {
                $rootScope.showLoader(false);
                Notification.error("Opss! Something went wrong");
                $state.go('invoice.browse');
            });
        };

        $scope.invoicelist = function (val) {
            return $http.get(apiUrl + '/api/getInvoicesByJobNumber', {
                params: {
                    JOB_NO: val,
                    without_cancel_job:'y'
                }
            }).then(function (response) {
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };

        $scope.selectJobFromList = function ($item, $model, $label, $event) {
            $scope.invoice.job_id = $item.id;
            $scope.invoice.JOB_NO = $item.JOB_NO;
            $scope.invoice.CAPTION = $item.CAPTION;
            // $scope.invoice.total_rep_pay_amt = round_to_number($item.REP_PAYAMT);
            // $scope.invoice.typ_pay_amt = round_to_number($item.TYP_PAYAMT);
            $scope.invoice.total_job_adjustments =  parseFloat(($item.total_adjustment.length >0) ? $item.total_adjustment[0].adjustmentAmount : 0.00);
            $scope.invoice.DATE_TAKEN = $item.DATE_TAKEN;

            $scope.getDiaryData($item.JOB_NO);
            $scope.witnesslist($item.JOB_NO);
            $scope.showJobInvoiceHistory($item.JOB_NO);
            $scope.getMarkReadyAuditLogByJob($item.JOB_NO);

            $scope.isJobSelected = true;

        };


        $scope.getDiaryData = function (job_number) {
            $rootScope.showLoader(true);
            var diaryDetails = {};
            diaryDetails.id = job_number;
            commonFactory.post('/api/getdiarydetails', diaryDetails)
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response && response.result && response.result.diaryDetails) {

                        $scope.diaryData = response.result.diaryDetails;
                        // get resource payments
                        $scope.getResourceWiseJobPayments($scope.diaryData.job_resources);
                        $scope.invoice.job_grand_total= round_to_number($scope.invoice.typ_pay_amt+$scope.invoice.total_rep_pay_amt+$scope.invoice.total_job_adjustments);
                        $scope.invoice.insurance_rate_id = response.result.diaryDetails.insurance_rate_id;

                        //Assign the variable which will save directly into database table
                        $scope.invoice.job_category_id = $scope.diaryData.job_category_id;
                        $scope.invoice.IndexNumber = angular.copy($scope.diaryData.IndexNumber || "");
                        $scope.invoice.attname_id = angular.copy($scope.diaryData.attname_id || null);
                        $scope.invoice.ordering_attname_id = angular.copy($scope.diaryData.attname_id);
                        //$scope.invoice.order_firm_id = angular.copy($scope.diaryData.attorney_id);

                        if ($scope.diaryData.rate_card_group_id){
                            commonFactory.post('/api/rate/getRateGroupActiveRate', {rate_card_group: [$scope.diaryData.rate_card_group_id]})
                                .success(function (response) {
                                    if (response.result && response.result.rates) {
                                        $scope.insuranceRates = response.result.rates;
                                    }
                                })
                                .error(function (err) {
                                });
                        }else{
                            $scope.getInsuranceRateTable();
                        }

                        $scope.invoice.HELD_AT1 = angular.copy($scope.diaryData.HELD_AT1);
                        $scope.invoice.total_job_invoices_amount = angular.copy($scope.diaryData.total_job_invoices_amount);
                        $scope.invoice.total_job_amount = angular.copy($scope.diaryData.total_job_invoices_amount);
                        $scope.invoice.CLM_NOTE = angular.copy($scope.diaryData.CLM_NOTE);
                        $scope.invoice.direct_billing_notes = angular.copy($scope.diaryData.direct_billing_notes);

                        $scope.invoice.ordering_claim_representative = angular.copy($scope.diaryData.claim_representative || {});
                        $scope.invoice.billing_claim_representative = $scope.diaryData.claim_representative || null;

                        $scope.invoice.ORDERING_CLM_NO = angular.copy($scope.diaryData.CLM_NO);
                        $scope.invoice.ORDERING_FILE_NO = angular.copy($scope.diaryData.FILE_NO);

                        $scope.invoice.BILLING_CLM_NO = angular.copy($scope.diaryData.CLM_NO);
                        $scope.invoice.BILLING_FILE_NO = angular.copy($scope.diaryData.FILE_NO);
                        $scope.invoice.source_id = angular.copy($scope.diaryData.source_id || null);
                        $scope.invoice.billing_type= $scope.diaryData.businessunit.invoice_billing_type;

                        if ($scope.diaryData.rate_card_group_id){
                            commonFactory.post('/api/rate/getRateGroupActiveRate', {rate_card_group: [$scope.diaryData.rate_card_group_id]})
                                .success(function (response) {
                                    if (response.result && response.result.rates) {
                                        $scope.insuranceRates = response.result.rates;
                                    }
                                })
                                .error(function (err) {
                                });
                        }

                        if($scope.diaryData.diary_reporting_salesman.length){
                            var reportingSalesmanIds = [];
                            angular.forEach($scope.diaryData.diary_reporting_salesman,function(value, key){
                                reportingSalesmanIds[key] = value.sales_reptyp_id;
                            });
                            $scope.invoice.reporting_sales_ids = reportingSalesmanIds;
                        }else{
                            $scope.invoice.reporting_sales_ids = [];
                        }

                        if($scope.diaryData.diary_records_salesman.length){
                            var recordsSalesmanIds = [];
                            angular.forEach($scope.diaryData.diary_records_salesman,function(value, key){
                                recordsSalesmanIds[key] = value.sales_reptyp_id;
                            });
                            $scope.invoice.records_sales_ids = recordsSalesmanIds;
                        }else{
                            $scope.invoice.records_sales_ids = [];
                        }

                        var attorney_id = $scope.diaryData.attorney_id;
                        var attname_id = $scope.diaryData.attname_id || null;
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/getAttorneyfirmdata', {attorney_id: attorney_id})
                            .success(function (response) {
                                $rootScope.showLoader(false);
                                $scope.attorneyFirmData = response.result.attorney_data;
                                $scope.invoice.attorneyFirms = angular.copy($scope.attorneyFirmData);
                                $scope.invoice.orderingAttorneyFirms = angular.copy($scope.attorneyFirmData);

                                if(!$scope.invoice.reporting_sales_ids.length){
                                    if($scope.attorneyFirmData.reporting_salesman.length){
                                        var reportingSalesmanIds = [];
                                        angular.forEach($scope.attorneyFirmData.reporting_salesman,function(value, key){
                                            reportingSalesmanIds[key] = value.sales_reptyp_id;
                                        });
                                        $scope.invoice.reporting_sales_ids = reportingSalesmanIds;
                                    }
                                }

                                if(!$scope.invoice.records_sales_ids.length){
                                    if($scope.attorneyFirmData.records_salesman.length){
                                        var recordsSalesmanIds = [];
                                        angular.forEach($scope.attorneyFirmData.records_salesman,function(value, key){
                                            recordsSalesmanIds[key] = value.sales_reptyp_id;
                                        });
                                        $scope.invoice.records_sales_ids = recordsSalesmanIds;
                                    }
                                }

                                $scope.getAttorneys(attorney_id, function (attorneyData) {

                                    $scope.orderingFirmAttorneys = angular.copy(attorneyData);
                                    // Set to false according to mark suggestion, Meeting held on 24 Aug 2017
                                    $scope.invoice.is_pulled_data = false;
                                    $scope.change_pulled_data();

                                    //Assign the variable which will save directly into database table
                                    //Assign the rate information to invoice object from AttorneyFirms object
                                    $scope.rateInformationAssignment();

                                    $scope.calculateInvoiceAmount();
                                });
                            })
                            .error(function (err) {
                                $rootScope.showLoader(false);
                            });
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };

        $scope.onSelectInvoiceChangeSalesperson = function(){
			$scope.invoice.reporting_sales_ids = [];
            $scope.insuranceRates.filter(function(item){                
				if($scope.invoice.insurance_rate_id == item.InsuranceRateID && (item.account_executives && item.account_executives.length > 0)) {					
					angular.forEach(item.account_executives,function(value, key){
                        $scope.invoice.reporting_sales_ids.push(value.salesperson_id);
                    });
				}
            });
            // $scope.invoice.insurance_rate_id = $scope.invoice.attorneyFirms.rate_id;if($scope.invoice.attorneyFirms.rate_id > 0){
            /*if(!$scope.isInvoicePriorMonth && $scope.rates_list.length > 1 ){
                $scope.invoice.attorneyFirms.rate_id = null;
                $scope.rates_list.filter(function(item){
                    if($scope.invoice.insurance_rate_id == item.rate_id ) {					
                        $scope.invoice.attorneyFirms.rate_id = item.rate_id;
                        $scope.changeAttorneyRate();
                    }
                });
            }*/
        }

        $scope.setOrderingFirmData = function(){
            // Set the Ordering firm Data here.
            $scope.invoice.attorneyFirms = {};
            $scope.invoice.attorneyFirms = angular.copy($scope.invoice.orderingAttorneyFirms);
            $scope.attorney = [{id: null, NAME: "NOT PRESENT"}];
            $scope.attorney = $scope.attorney.concat($scope.orderingFirmAttorneys || {});
            $timeout(function(){
                $scope.invoice.attname_id = angular.copy(parseInt($scope.diaryData.attname_id) || null);
            });

            $scope.invoice.billing_claim_representative = $scope.diaryData.claim_representative || null;
            $scope.invoice.BILLING_CLM_NO = angular.copy($scope.diaryData.CLM_NO);
            $scope.invoice.BILLING_FILE_NO = angular.copy($scope.diaryData.FILE_NO);

            $scope.invoice.invoice_and_transcript_handling_id = $scope.invoice.attorneyFirms.invoice_and_transcript_handling_id;

            //assigning status and beeper(autopartner) of firm.
            $scope.firm_status = $scope.invoice.attorneyFirms.STATUS;
            $scope.assigningAutoPartner($scope.invoice.attorneyFirms);
            
            // Get the Attorney Firms rates
            $scope.getAttorneyRates($scope.invoice.attorneyFirms.id);
            $scope.isFirmSelected = true;
            $scope.userWantToEdit = false;
            $scope.invoice.purchase_order = $scope.invoice.attorneyFirms.default_purchase_order;


        };

        $scope.makeBlankToBillingFirm = function(){
            $scope.invoice.attorneyFirms = {};
            $scope.attorney = [{id: null, NAME: "NOT PRESENT"}];
            $scope.rates_list = [{rate_id : null, rate : {name:"Please select rate type"}}];
            $scope.invoice.billing_claim_representative = null;
            $scope.invoice.BILLING_CLM_NO = angular.copy('');
            $scope.invoice.BILLING_FILE_NO = angular.copy('');
            $scope.userWantToEdit = false;
            $scope.firm_status = '';
            $scope.firm_beeper = '';
            $scope.invoice.purchase_order = null;
            $scope.invoice.invoice_and_transcript_handling_id = '';

            //Assign the variable which will save directly into database table
            //Assign the rate information to invoice object from AttorneyFirms object
            $scope.rateInformationAssignment();
        }

        // Change the watch to function because it automatically calling in edit mode and distrub the calculation
        $scope.change_pulled_data = function(){

            if ($scope.invoice.is_pulled_data) {
                $scope.isFirmSelected = true;
                $scope.setOrderingFirmData();
            }
            else {
                $scope.isFirmSelected = false;
                $scope.makeBlankToBillingFirm();
            }

            //Assign the rate information to invoice object from AttorneyFirms object
            $scope.rateInformationAssignment($scope.invoice.is_pulled_data);
        }

        $scope.allowUserToChange = function(){


            $rootScope.showLoader(true);
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/billing_firm_change_popup.html",
                controller: ['$scope', '$rootScope', 'users', '$uibModalInstance', 'commonFactory','scope', function ($scope, $rootScope, users, $uibModalInstance, commonFactory,scope) {
                    $rootScope.showLoader(false);
                    $scope.users = [{'id': null, name: "Please select the user"}];
                    $scope.users = $scope.users.concat(users.data);
                    $scope.invoice_attorney = {approved_by: null, invoice_id: scope.invoice.invoiceID,attorney_id : scope.invoice.attorneyFirms.id};
                    $scope.uibModalInstance = $uibModalInstance;

                    $scope.voidFormSubmit = function ($valid) {
                        if ($valid) {
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/invoice/change-ordering-firm', $scope.invoice_attorney).success(function (response) {
                                $rootScope.showLoader(false);
                                $uibModalInstance.close(true);
                            }).error(function (error) {
                                $rootScope.showLoader(false);
                            });
                        }
                    }

                }],
                resolve: {
                    scope: function(){
                        return $scope;
                    },
                    users: ['UsersFactory', function (UsersFactory) {
                        return UsersFactory.get('/api/users').success(function (response) {
                            return response.data;
                        });
                    }]
                },
                keyboard: true,
                backdrop: true,
                size: 'md',
                windowClass: 'middle-width-model',
            });
            invoiceModal.result.then(function(bool){
                if(bool){

                    $scope.userWantToEdit = false;
                    $scope.isFirmSelected = false;
                    $scope.invoice.is_pulled_data = false; 
                    $scope.change_pulled_data();

                }
            });
        };

        $scope.changeInvoiceAttorneyPopup = function(){

            $rootScope.showLoader(true);
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/change_invoice_attorney_popup.html",
                controller: ['$scope', '$rootScope', 'INV_DATA', '$uibModalInstance', 'commonFactory', function ($scope, $rootScope, INV_DATA, $uibModalInstance, commonFactory) {
                
                    $scope.uibModalInstance = $uibModalInstance;
                    $scope.assignedFirm = false;
                    $scope.invoice = {
                        INV_NO : INV_DATA.INV_NO
                    };

                    var att_id = INV_DATA.attorneyFirms.id;
                    commonFactory.get('/api/attorneys/'+att_id)
                        .success(function (response) {
                            $rootScope.showLoader(false);
                            if(! response.error){
                                $scope.invoice.newAttorneyFirms = response.result.data || '';
                                $scope.invoice.oldAttorneyFirms = response.result.data || '';
                                $scope.assignedFirm = true;
                            }
                        })
                        .error(function (err) {
                            $rootScope.showLoader(false);
                        });

                    $scope.manuallyChangeAttorneyFirm = function(){
                        if($scope.assignedFirm){
                            $scope.assignedFirm = false;
                        }
                    }

                    //Getting Attorney Firm office_code
                    $scope.attorneyofficedata = function (val) {
                        return $http.get(apiUrl + '/api/getattorneyfirm', {
                            params: {
                                name: val
                            }
                        }).then(function (response) {
                            $scope.foundFromElaw = false;
                            if(response.data.foundFromElaw){
                                $scope.foundFromElaw = true;
                            }
                            return response.data.result.map(function(item) {
                                return item;
                            });
                        });
                    };

                    $scope.onSelectAttorneyFirm = function($item, $model, $label, $event){
                        $scope.assignedFirm = true;
                        $scope.invoice.newAttorneyFirms = $item;
                    };

                    $scope.updateInvoice = function ($valid) {
                        if ($valid && $scope.assignedFirm) {
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/invoice/change-ordering-firm', $scope.invoice).success(function (response) {
                                if(!response.error){
                                    Notification.success(response.result.message);
                                    $uibModalInstance.close({updated : true, firmData : $scope.invoice.newAttorneyFirms});
                                }
                                
                                $rootScope.showLoader(false);
                            }).error(function (error) {
                                $rootScope.showLoader(false);
                            });
                        }
                    }

                }],
                resolve: {
                    INV_DATA: function(){
                        return $scope.invoice;
                    },
                },
                keyboard: true,
                backdrop: true,
                size: 'md',
                windowClass: 'middle-width-model',
            });
            invoiceModal.result.then(function(resp){
                if(resp.updated){
                    
                    if(!$scope.isInvoicePriorMonth){
                        $scope.refreshFirm(resp.firmData.id);
                    } else{
                        $scope.isFirmSelected = true;
                        $scope.invoice.attorneyFirms = resp.firmData;
                    }
                
                }
            });
        }

        $scope.editInvoiceJobFetchData($scope.invoice_id);

        $scope.initializeInvoice = function () {
            $scope.invoice = {
                JOB_NO: '',
                invoiceID: '',
                witnessData: [],
                STIPS: 'S',
                additional_charge_amount: 0,
                copy_fees: 0,
                createdDate: $filter('date')(new Date(), "MM/dd/yyyy"),
                INV_DT: moment(),
                attname_id: null,
                total_job_invoices_amount: 0,
                additional_charges: [{
                    QTY: 1,
                    AMT: 0
                }]
            };

            $scope.job_invoices = [];
            $scope.invoice_table_grid = false;
            $scope.userWantToEdit = false;
            $scope.firm_status = '';
            $scope.firm_beeper = '';
            $scope.mark_ready = undefined;
            $scope.markReadyAuditLogForAJob = '';
        };

        // If user start to change the JOb number make all option blank
        $scope.onChangeOfJobNumber = function () {

            // if($scope.isJobSelected){
            //     $scope.initializeInvoice();
                $scope.isFirmSelected = false;
                $scope.isJobSelected = false;
            // }
        }

        $scope.clearJobNumber = function(){
            if(!$scope.isJobSelected){
                // $scope.invoice.JOB_NO = null;
                $scope.initializeInvoice();
            }
        };

        $scope.newInvoice = function(job_no){
            $state.go('invoice.create',{job_id:job_no});
        };

        $scope.is_pulled_witness = false;

        $scope.witnesslist = function (id) {
            if (id) {
                $rootScope.showLoader(true);
                commonFactory.get('/api/getwitnessfordiaryinvoice/'+id)
                    .success(function (response) {
                        if (response.result && response.result.witnessDetails) {

                            $scope.invoice.witnessData = angular.copy(response.result.witnessDetails);

                            // If we have already witnesses

                            if ($scope.invoice.witnessData && $scope.invoice.witnessData.length > 0) {

                                angular.forEach($scope.invoice.witnessData, function ($item, $index) {
                                    var witness = $filter('filter')($scope.invoice.witness_data, {witness_id: $item.id})[0];
                                    if (witness) {
                                        $scope.invoice.witnessData[$index].is_add = true;
                                        $scope.invoice.witnessData[$index].COPIES = witness.COPIES;
                                        $scope.invoice.witnessData[$index].Enclosed = witness.Enclosed;
                                        $scope.invoice.witnessData[$index].IndexPages = witness.IndexPages;
                                        $scope.invoice.witnessData[$index].MiniScriptPages = witness.MiniScriptPages;
                                        $scope.invoice.witnessData[$index].PAGES = witness.PAGES;
                                        $scope.invoice.witnessData[$index].PAGE_END = witness.PAGE_END;
                                        $scope.invoice.witnessData[$index].PAGE_START = witness.PAGE_START;
                                        $scope.invoice.witnessData[$index].SPLIT = witness.SPLIT;
                                        $scope.invoice.witnessData[$index].TOTAL_PAGES = witness.TOTAL_PAGES;
                                        $scope.invoice.witnessData[$index].addrate = witness.addrate || 'norm_rate';
                                        $scope.invoice.witnessData[$index].charge = witness.charge;
                                        $scope.invoice.witnessData[$index].line_pay = witness.line_pay;
                                        $scope.invoice.witnessData[$index].medical_charge = (witness.medical_charge!="0")?true:false;
                                        $scope.invoice.witnessData[$index].rate_type = witness.rate_type || 'rate';
                                        $scope.invoice.witnessData[$index].rate_flag = witness.rate_flag || '-';
                                        $scope.invoice.witnessData[$index].nsb_type = witness.nsb_type || '';
                                        $scope.invoice.witnessData[$index].is_deposition_insights = $scope.checkDuplicateDepositionInsightsAditionalCharges($scope.invoice.witnessData[$index])

                                    }


                                });

                                $scope.is_pulled_witness = true;
                                $scope.calculateInvoiceAmount();
                            }

                        }
                        $rootScope.showLoader(false);
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }
        };

        $scope.refreshWitnessSelection = function(){
            angular.forEach($scope.invoice.witnessData, function(witness, $key) {
                $scope.invoice.witnessData[$key].Enclosed = 0;
                $scope.invoice.witnessData[$key].is_add = false;
            });
        };

        $scope.searchClaimRepName = function (val) {
            return $http.post(apiUrl + '/api/search-claimrep-name', {query_string: val}).then(function (response) {
                return response.data.result.map(function (item) {
                    return item;
                });
            });
        };

        $scope.selectClaimRepName = function ($item, $model, $label, $event) {
            // console.log($item,'select data')
            $scope.invoice.purchase_order =$item.insurance_comp.purchase_order
            // $scope.invoice.billing_claim_representative = angular.copy($item);
            //
        };

        $scope.getAdditionalChargeStatus = function(status){
            return (status == '1') ? "Active" : "Deactive";
        }
        
        $scope.selectChargeCodeFromList = function ($index) {

            var selectedValue = $scope.invoice.additional_charges[$index].CHG_CODE;

            $item = $scope.additional_charges.find(obj => {
                return obj.CHG_CODE === selectedValue
            });

            if($item){
                if($item.status == 1){
                    var AMT = 0;
                    if($item.CHG_CODE == 'E' && Number($scope.invoice.exhibit_charge) > 0){
                        AMT = Number($scope.invoice.exhibit_charge);
                    }else{
                        AMT = $item.AMT;
                    }
                    $scope.invoice.additional_charges[$index] = angular.copy($item);
                    $scope.invoice.additional_charges[$index].AMT = Number(AMT);
                    $scope.invoice.additional_charges[$index].QTY = Number(1);
                    $scope.invoice.additional_charges[$index].SPLIT = Number(1);

                    $scope.calculationAdditionalCharge($index);
                }else{
                    $scope.invoice.additional_charges[$index].CHG_CODE = '';
                    $scope.invoice.additional_charges[$index].AMT = 0;
                    $scope.invoice.additional_charges[$index].QTY = Number(1);
                    $scope.invoice.additional_charges[$index].SPLIT = Number(1);
                    $scope.calculationAdditionalCharge($index);
                    Notification.error('You can not select deactivated charge please select active one.');
                }
            }else{
                
                $scope.invoice.additional_charges[$index].CHG_CODE = '';
                $scope.invoice.additional_charges[$index].AMT = 0;
                $scope.invoice.additional_charges[$index].QTY = Number(1);
                $scope.invoice.additional_charges[$index].SPLIT = Number(1);
                $scope.calculationAdditionalCharge($index);
            }

        };

        $scope.foundFromElaw = false;
        //Getting Attorney Firm office_code
        $scope.attorneyofficedata = function (val) {
            return $http.get(apiUrl + '/api/getattorneyfirm', {
                params: {
                    name: val
                }
            }).then(function (response) {
                $scope.foundFromElaw = false;
                if(response.data.foundFromElaw){
                    $scope.foundFromElaw = true;
                }
                return response.data.result.map(function(item) {
                    return item;
                });
            });
        };

        $scope.getAttorneys = function (id, callback) {
            var data = {};
            data.firm_id = id;
            $rootScope.showLoader(true);
            $timeout(function () {
                commonFactory.post('/api/attorneybyfirm', data)
                    .success(function (response) {
                        if (response) {
                            if (response && response.result && response.result.attorney_data) {


                                if (typeof callback == "function") {

                                    callback(response.result.attorney_data);
                                }
                            }
                        }
                        $rootScope.showLoader(false);
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }, 1000);
        };

        $scope.getAttorneys_invoice = function (id, callback) {
            var data = {};
            data.firm_id = id;
            $rootScope.showLoader(true);
            $timeout(function () {
                commonFactory.post('/api/attorneyby_invoice_att_id', data)
                    .success(function (response) {
                        if (response) {
                            if (response && response.result && response.result.attorney_data) {


                                if (typeof callback == "function") {

                                    callback(response.result.attorney_data);
                                }
                            }
                        }
                        $rootScope.showLoader(false);
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
            }, 1000);
        };
        

        $scope.onSelectAttorneyFirm = function ($item, $model, $label, $event) {

            $scope.isFirmSelected = true;
            if(!$scope.foundFromElaw){
                $scope.invoice.attorneyFirms = $item;
                $scope.invoice.purchase_order = $item.default_purchase_order;
                $scope.invoice.billing_claim_representative = angular.copy($item.claim_rep_name);

                $scope.invoice.invoice_and_transcript_handling_id = $scope.invoice.attorneyFirms.invoice_and_transcript_handling_id;

                //assigning status and beeper(autopartner) of firm.
                $scope.firm_status = $item.STATUS;
                $scope.assigningAutoPartner($item);

                // disable firm after selecting the firm

                //Assign the variable which will save directly into database table
                //Assign the rate information to invoice object from AttorneyFirms object
                $scope.rateInformationAssignment(false);

                $scope.getAttorneyRates($item.id);

                //$scope.getAttorneys($item.id);
                $scope.getAttorneys($item.id, function (attorneyData) {

                    $scope.attorney = [{id: null, NAME: "NOT PRESENT"}];
                    $scope.attorney = $scope.attorney.concat(attorneyData || {});
                    //$scope.mainAttorney = angular.copy(attorneyData || {});
                    $scope.invoice.attname_id = null
                });
            }else{

                swal({
                        title: "Are you sure?",
                        text: "You are about to add new firm!",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#09375f",
                        confirmButtonText: "Yes",
                        closeOnConfirm: true},
                    function(isConfirm){
                        // $state.go('firms.add.step_one_add', { elawId : $item.id});
                        // $scope.diaryData.attorney_id = null;
                        // var url = $state.href('firms.add.step_one_add', { elawId : $item.id});
                        // window.open(url,'_blank');

                        if(isConfirm){

                            $scope.addAttorneyFirm($item.id);

                        }
                        else{
                            $scope.invoice.attorneyFirms = {};
                            $scope.$apply();
                        }

                    });

            }
        };

        $scope.manuallyChangeAttorneyFirm = function () {
            // if($scope.isFirmSelected){
                $scope.invoice.is_pulled_data = false;
                $scope.isFirmSelected = false;
                // $scope.makeBlankToBillingFirm();
            // }
        };

        $scope.clearAttorneyFirm = function(){
            
            if(!$scope.isFirmSelected){
                // $scope.invoice.attorneyFirms = null;
                $scope.makeBlankToBillingFirm();
            }
        };

        /**
         * Get all the Rate list which is associated with selected Attorney
         */
        $scope.getAttorneyRates = function(attorneyFirmId,assignExistingRates){
            $rootScope.showLoader(true);
            commonFactory.get('/api/get-attorney-rates/'+attorneyFirmId)
                .success(function (response) {
                    if (response) {

                        // if (response && response.rates) {
                        //
                        //     $scope.rates_list = response.rates;
                        // }
                        if (response && response.rates) {

                            if(response.rates.length > 1){

                                var checkRate = function(element) {
                                    // checks if default rates is exist

                                    return element.rate_id == "0" || element.rate_id == null;
                                };

                                var alreadyDefaultExists = response.rates.some(checkRate);


                                $scope.rates_list = [{rate_id : null,rate : {RateName : "Please select rate type"}}];
                                if(!alreadyDefaultExists){
                                    $scope.rates_list = [{rate_id : 0}];

                                }
                                $scope.rates_list = $scope.rates_list.concat(response.rates);
                                if(!assignExistingRates){
                                    $scope.invoice.attorneyFirms.rate_id = null;
                                    $scope.setBlankToAttorneyRates();
                                }

                            }
                            else if(response.rates.length == 1){

                                if(response.rates[0].rate_id!=0){
                                    $scope.rates_list = [{rate_id : null, rate : {RateName : "Please select rate type"}}];
                                    $scope.rates_list = $scope.rates_list.concat({rate_id : 0});
                                    $scope.rates_list = $scope.rates_list.concat(response.rates);
                                    if(!assignExistingRates) {
                                        $scope.invoice.attorneyFirms.rate_id = null;
                                        $scope.setBlankToAttorneyRates();
                                    }

                                }else{
                                    $scope.rates_list = response.rates;
                                    if(!assignExistingRates) {
                                        $scope.invoice.attorneyFirms.rate_id = $scope.rates_list[0].rate_id;
                                    }

                                }

                            }
                            else{
                                $scope.rates_list = [{rate_id : 0}];
                                $scope.invoice.attorneyFirms.rate_id = 0;
                            }
                        }
                    }
                    $rootScope.showLoader(false);
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };

        $scope.changeAttorneyRate = function(){
            if($scope.invoice.attorneyFirms.rate_id > 0) {
                swal({
                    title: "Do you want to apply this rate card to the ordering firm as well ?",
                    text: "<b>THIS MAY CHANGE THE ACCOUNT EXECUTIVE FOR THE CALLED-IN ASSIGNMENT.</b>",
                    type: "warning",
                    html: true,
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it.",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $scope.invoice.insurance_rate_id = $scope.invoice.attorneyFirms.rate_id;
                        $scope.invoice.reporting_sales_ids = [];
                        $scope.insuranceRates.filter(function(item){        
                            if($scope.invoice.insurance_rate_id == item.InsuranceRateID && (item.account_executives && item.account_executives.length > 0)) {		
                                if(item.allow_rep == '0'){
                                    if($scope.diaryData.diary_reporting_salesman.length){
                                        var reportingSalesmanIds = [];
                                        angular.forEach($scope.diaryData.diary_reporting_salesman,function(value, key){
                                            reportingSalesmanIds[key] = value.sales_reptyp_id;
                                        });
                                        $scope.invoice.reporting_sales_ids = reportingSalesmanIds;
                                    }
                                }		
                                
                                angular.forEach(item.account_executives,function(value, key){
                                    $scope.invoice.reporting_sales_ids.push(value.salesperson_id);
                                });
                            }
                        });
                    }
               
                $rootScope.showLoader(true);
                commonFactory.get('/api/get-rate-detail/' + $scope.invoice.attorneyFirms.rate_id)
                    .success(function (response) {
                        if (response) {
                            if (response && response.rate_details) {
                                $scope.invoice.Room = parseFloat(response.rate_details.In_Room || 0.00);
                                $scope.rateCardRate = response.rate_details;
                                $scope.invoice.med_rate = parseFloat(response.rate_details.In_med_rate || 0.00);
                                $scope.invoice.MiniScriptPerPage = parseFloat(response.rate_details.In_MiniScriptPerPage || 0.00);
                                $scope.invoice.rate_connection_method = 0
                                $scope.invoice.exp_rate = parseFloat(response.rate_details.In_exp_rate || 0.00);
                                $scope.invoice.WEB_AMOUNT = parseFloat(response.rate_details.In_WEB_AMOUNT || 0.00);
                                
                                $scope.invoice.RATE = parseFloat(response.rate_details.In_RATE || 0.00);
                                $scope.invoice.COPY_RATE = parseFloat(response.rate_details.In_COPY_RATE || 0.00);
                                $scope.invoice.APP_RATE = parseFloat(response.rate_details.In_APP_RATE || 0.00);
                                $scope.invoice.daily_rate = parseFloat(response.rate_details.In_daily_rate || 0.00);
                                console.log($scope.invoice)
                                $scope.invoice.CDCharge = parseFloat(response.rate_details.In_CDCharge || 0.00);
                                $scope.invoice.MiniScriptFlatRate = parseFloat(response.rate_details.In_MiniScriptFlatRate || 0.00);
                                $scope.invoice.INV_MINPG = parseFloat(response.rate_details.In_INV_MINPG || 0.00);
                                $scope.invoice.immed_rate = parseFloat(response.rate_details.In_immed_rate || 0.00);
                                $scope.invoice.ETranscript = parseFloat(response.rate_details.In_ETranscript || 0.00);
                                $scope.invoice.WordIndexingFlatRate = parseFloat(response.rate_details.In_WordIndexingFlatRate || 0.00);
                                $scope.invoice.INV_MIN = parseFloat(response.rate_details.In_INV_MIN || 0.00);
                                $scope.invoice.SOR = parseFloat(response.rate_details.In_SOR || 0.00);
                                $scope.invoice.Charge2 = parseFloat(response.rate_details.In_Charge2 || 0.00);
                                $scope.invoice.POSTAGE = parseFloat(response.rate_details.In_POSTAGE || 0.00);
                                $scope.invoice.admin_fee = parseFloat(response.rate_details.In_admin_fee || 0.00);
                                $scope.invoice.web_charge_per_witness = parseFloat(response.rate_details.In_web_charge_per_witness || 0.00);
                                $scope.invoice.video_conference_handling_and_connection_fee = parseFloat(response.rate_details.In_video_conference_handling_and_connection_fee || 0.00);
                                $scope.invoice.reminder_charge = parseFloat(response.rate_details.In_reminder_charge || 0.00);
                                $scope.invoice.legalview_charge = parseFloat(response.rate_details.In_legalview_charge || 0.00);
                                $scope.invoice.digitization_charge = parseFloat(response.rate_details.In_digitization_charge || 0.00);
                                $scope.invoice.exhibit_charge = parseFloat(response.rate_details.In_exhibit_charge || 0.00);
                                $scope.invoice.equipment_rental = parseFloat(response.rate_details.In_equipment_rental || 0.00);
                                $scope.invoice.video_sync = parseFloat(response.rate_details.In_video_sync || 0.00);
                                $scope.invoice.processing_fee = parseFloat(response.rate_details.In_processing_fee || 0.00);
                                $scope.invoice.color_exhibit_charge = parseFloat(response.rate_details.In_color_exhibit_charge || 0.00);
                                $scope.invoice.hyperlinked_exhibit_charge = parseFloat(response.rate_details.In_hyperlinked_exhibit_charge || 0.00);
                                $scope.invoice.exhibits_per_page = parseFloat(response.rate_details.In_exhibits_per_page || 0.00);
                                $scope.invoice.no_transcript_depo_appear_rate = parseFloat(response.rate_details.In_no_transcript_depo_appear_rate || 0.00);
                                $scope.invoice.no_transcript_arbitration_appear_rate = parseFloat(response.rate_details.In_no_transcript_arbitration_appear_rate || 0.00);
                                $scope.invoice.no_transcript_euo_appear_rate = parseFloat(response.rate_details.In_no_transcript_euo_appear_rate || 0.00);
                                $scope.invoice.cancellation = parseFloat(response.rate_details.In_cancellation || 0.00);
                                $scope.invoice.video_cancellation = parseFloat(response.rate_details.In_video_cancellation || 0.00);
                                $scope.invoice.medical_technical_expert = parseFloat(response.rate_details.In_medical_technical_expert || 0.00);
                                if(response.rate_details.claim_representative){
                                    $scope.invoice.billing_claim_representative = angular.copy(response.rate_details.claim_representative);
                                }
                                // var rate_detail_obj = $filter('filter')($scope.rates_list, {rate_id: response.rate_details.InsuranceRateID}, true)[0];
                                $scope.invoice.SHOW_CALCS = (response.rate_details.show_invoice_detail || 'No').toLowerCase();  

                                // $scope.invoice.attorneyFirms.ratetype.name = rate_detail_obj.rate.RateName;

                                // Calculate the All witness here also because, here we will get the rate information object.
                                $scope.calculateAllWitnessCharges();

                                // Populate the additional charge from existing attorney firm charges
                                $scope.populateAdditionalChargeFromAttorney();
                            }
                        }
                        $rootScope.showLoader(false);
                    })
                    .error(function (err) {
                        $rootScope.showLoader(false);
                    });
                });
            }
            else{
                $scope.rateInformationAssignment(false);
            }
        };

        $scope.rateInformationAssignment = function (is_existing) {
            // Check its already applying edited rates
            if (is_existing) {
                // Fetch the updated rate while its saving the data
                $scope.invoice.Room = parseFloat($scope.invoice.orderingAttorneyFirms.Room || 0.00);
                
                $scope.invoice.med_rate = parseFloat($scope.invoice.orderingAttorneyFirms.med_rate || 0.00);
                $scope.invoice.MiniScriptPerPage = parseFloat($scope.invoice.orderingAttorneyFirms.MiniScriptPerPage || 0.00);
               
                $scope.invoice.exp_rate = parseFloat($scope.invoice.orderingAttorneyFirms.exp_rate || 0.00);
                $scope.invoice.WEB_AMOUNT = parseFloat($scope.invoice.orderingAttorneyFirms.WEB_AMOUNT || 0.00);
               
                
                $scope.invoice.daily_rate = parseFloat($scope.invoice.orderingAttorneyFirms.daily_rate || 0.00);
                $scope.invoice.CDCharge = parseFloat($scope.invoice.orderingAttorneyFirms.CDCharge || 0.00);
                $scope.invoice.MiniScriptFlatRate = parseFloat($scope.invoice.orderingAttorneyFirms.MiniScriptFlatRate || 0.00);
                $scope.invoice.INV_MINPG = parseFloat($scope.invoice.orderingAttorneyFirms.INV_MINPG || 0.00);
                $scope.invoice.immed_rate = parseFloat($scope.invoice.orderingAttorneyFirms.immed_rate || 0.00);
                $scope.invoice.ETranscript = parseFloat($scope.invoice.orderingAttorneyFirms.ETranscript || 0.00);
                $scope.invoice.WordIndexingFlatRate = parseFloat($scope.invoice.orderingAttorneyFirms.WordIndexingFlatRate || 0.00);
                $scope.invoice.INV_MIN = parseFloat($scope.invoice.orderingAttorneyFirms.INV_MIN || 0.00);
                $scope.invoice.SOR = parseFloat($scope.invoice.orderingAttorneyFirms.SOR || 0.00);
                $scope.invoice.rate_id = $scope.invoice.orderingAttorneyFirms.rate_id || null;
                $scope.invoice.SHOW_CALCS = ($scope.invoice.orderingAttorneyFirms.SHOW_CALCS || 'No').toLowerCase();
                $scope.invoice.Charge2 = parseFloat($scope.invoice.orderingAttorneyFirms.Charge2 || 0.00);
                $scope.invoice.POSTAGE = parseFloat($scope.invoice.orderingAttorneyFirms.POSTAGE || 0.00);
                $scope.invoice.admin_fee = parseFloat($scope.invoice.orderingAttorneyFirms.admin_fee || 0.00);
                $scope.invoice.web_charge_per_witness = parseFloat($scope.invoice.orderingAttorneyFirms.web_charge_per_witness || 0.00);
                $scope.invoice.video_conference_handling_and_connection_fee = parseFloat($scope.invoice.orderingAttorneyFirms.video_conference_handling_and_connection_fee || 0.00);
                $scope.invoice.reminder_charge = parseFloat($scope.invoice.orderingAttorneyFirms.reminder_charge || 0.00);
                $scope.invoice.legalview_charge = parseFloat($scope.invoice.orderingAttorneyFirms.legalview_charge || 0.00);
                $scope.invoice.digitization_charge = parseFloat($scope.invoice.orderingAttorneyFirms.digitization_charge || 0.00);
                $scope.invoice.exhibit_charge = parseFloat($scope.invoice.orderingAttorneyFirms.exhibit_charge || 0.00);
                $scope.invoice.equipment_rental = parseFloat($scope.invoice.orderingAttorneyFirms.equipment_rental || 0.00);
                $scope.invoice.video_sync = parseFloat($scope.invoice.orderingAttorneyFirms.video_sync || 0.00);
                $scope.invoice.processing_fee = parseFloat($scope.invoice.orderingAttorneyFirms.processing_fee || 0.00);
                $scope.invoice.color_exhibit_charge = parseFloat($scope.invoice.orderingAttorneyFirms.color_exhibit_charge || 0.00);
                $scope.invoice.hyperlinked_exhibit_charge = parseFloat($scope.invoice.orderingAttorneyFirms.hyperlinked_exhibit_charge || 0.00);
                $scope.invoice.exhibits_per_page = parseFloat($scope.invoice.orderingAttorneyFirms.exhibits_per_page || 0.00);
                $scope.invoice.no_transcript_depo_appear_rate = parseFloat($scope.invoice.orderingAttorneyFirms.no_transcript_depo_appear_rate || 0.00);
                $scope.invoice.no_transcript_arbitration_appear_rate = parseFloat($scope.invoice.orderingAttorneyFirms.no_transcript_arbitration_appear_rate || 0.00);
                $scope.invoice.no_transcript_euo_appear_rate = parseFloat($scope.invoice.orderingAttorneyFirms.no_transcript_euo_appear_rate || 0.00);
                $scope.invoice.video_cancellation = parseFloat($scope.invoice.orderingAttorneyFirms.video_cancellation || 0.00);

                if($scope.invoice.rate_connection_method == 1){
                    $scope.invoice.RATE = parseFloat($scope.invoice.orderingAttorneyFirms.RATE || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.invoice.orderingAttorneyFirms.COPY_RATE || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.invoice.orderingAttorneyFirms.APP_RATE || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.orderingAttorneyFirms.WordIndexingPerPage || 0.00);
                    $scope.invoice.cancellation = parseFloat($scope.invoice.orderingAttorneyFirms.cancellation || 0.00);
                    $scope.invoice.medical_technical_expert = parseFloat($scope.invoice.orderingAttorneyFirms.medical_technical_expert || 0.00);
                }else if($scope.invoice.rate_connection_method == 2){
                    $scope.invoice.RATE = parseFloat($scope.invoice.orderingAttorneyFirms.rate_remote || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.invoice.orderingAttorneyFirms.copy_rate_remote || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.invoice.orderingAttorneyFirms.appear_rate_remote || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.orderingAttorneyFirms.word_indexing_page_rate_remote || 0.00);
                }else if($scope.invoice.rate_connection_method == 3){
                    $scope.invoice.RATE = parseFloat($scope.invoice.orderingAttorneyFirms.rate_nonsteno || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.invoice.orderingAttorneyFirms.copy_rate_nonsteno || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.invoice.orderingAttorneyFirms.appear_rate_nonsteno || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.orderingAttorneyFirms.word_indexing_page_rate_nonsteno || 0.00);
                    $scope.invoice.cancellation = parseFloat($scope.invoice.orderingAttorneyFirms.cancellation_nonsteno || 0.00);
                    $scope.invoice.medical_technical_expert = parseFloat($scope.invoice.orderingAttorneyFirms.medical_technical_expert_nonsteno || 0.00);
                }else if($scope.invoice.rate_connection_method == 4){
                    $scope.invoice.RATE = parseFloat($scope.invoice.orderingAttorneyFirms.rate_arbitration || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.invoice.orderingAttorneyFirms.copy_rate_arbitration || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.invoice.orderingAttorneyFirms.appear_rate_arbitration || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.orderingAttorneyFirms.word_indexing_page_rate_arbitration || 0.00);
                }else if($scope.invoice.rate_connection_method == 5){
                    $scope.invoice.RATE = parseFloat($scope.invoice.orderingAttorneyFirms.rate_euo || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.invoice.orderingAttorneyFirms.copy_rate_euo || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.invoice.orderingAttorneyFirms.appear_rate_euo || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.orderingAttorneyFirms.word_indexing_page_rate_euo || 0.00);
                }else{
                    $scope.invoice.RATE = parseFloat($scope.invoice.orderingAttorneyFirms.RATE || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.invoice.orderingAttorneyFirms.COPY_RATE || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.invoice.orderingAttorneyFirms.APP_RATE || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.orderingAttorneyFirms.WordIndexingPerPage || 0.00);
                    $scope.invoice.cancellation = parseFloat($scope.invoice.orderingAttorneyFirms.cancellation || 0.00);
                    $scope.invoice.medical_technical_expert = parseFloat($scope.invoice.orderingAttorneyFirms.medical_technical_expert || 0.00);
                }
                $scope.invoice.attorneyFirms = angular.copy($scope.invoice.orderingAttorneyFirms);
            }
            else {
                if ($scope.invoice.attorneyFirms) {

                    $scope.invoice.Room = parseFloat($scope.invoice.attorneyFirms.Room || 0.00);
                    
                    $scope.invoice.med_rate = parseFloat($scope.invoice.attorneyFirms.med_rate || 0.00);
                    $scope.invoice.MiniScriptPerPage = parseFloat($scope.invoice.attorneyFirms.MiniScriptPerPage || 0.00);
                  
                    $scope.invoice.exp_rate = parseFloat($scope.invoice.attorneyFirms.exp_rate || 0.00);
                    $scope.invoice.WEB_AMOUNT = parseFloat($scope.invoice.attorneyFirms.WEB_AMOUNT || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.attorneyFirms.WordIndexingPerPage || 0.00);
                  
                    $scope.invoice.daily_rate = parseFloat($scope.invoice.attorneyFirms.daily_rate || 0.00);
                    $scope.invoice.CDCharge = parseFloat($scope.invoice.attorneyFirms.CDCharge || 0.00);
                    $scope.invoice.MiniScriptFlatRate = parseFloat($scope.invoice.attorneyFirms.MiniScriptFlatRate || 0.00);
                    $scope.invoice.INV_MINPG = parseFloat($scope.invoice.attorneyFirms.INV_MINPG || 0.00);
                    $scope.invoice.immed_rate = parseFloat($scope.invoice.attorneyFirms.immed_rate || 0.00);
                    $scope.invoice.ETranscript = parseFloat($scope.invoice.attorneyFirms.ETranscript || 0.00);
                   
                    $scope.invoice.INV_MIN = parseFloat($scope.invoice.attorneyFirms.INV_MIN || 0.00);
                    $scope.invoice.SOR = parseFloat($scope.invoice.attorneyFirms.SOR || 0.00);
                    $scope.invoice.rate_id = $scope.invoice.attorneyFirms.rate_id || null;
                    $scope.invoice.SHOW_CALCS = ($scope.invoice.attorneyFirms.SHOW_CALCS || 'No').toLowerCase();
                    $scope.invoice.Charge2 = parseFloat($scope.invoice.attorneyFirms.Charge2 || 0.00);
                    $scope.invoice.POSTAGE = parseFloat($scope.invoice.attorneyFirms.POSTAGE || 0.00);
                    $scope.invoice.admin_fee = parseFloat($scope.invoice.attorneyFirms.admin_fee || 0.00);
                    $scope.invoice.web_charge_per_witness = parseFloat($scope.invoice.attorneyFirms.web_charge_per_witness || 0.00);
                    $scope.invoice.video_conference_handling_and_connection_fee = parseFloat($scope.invoice.attorneyFirms.video_conference_handling_and_connection_fee || 0.00);
                    $scope.invoice.reminder_charge = parseFloat($scope.invoice.attorneyFirms.reminder_charge || 0.00);
                    $scope.invoice.legalview_charge = parseFloat($scope.invoice.attorneyFirms.legalview_charge || 0.00);
                    $scope.invoice.digitization_charge = parseFloat($scope.invoice.attorneyFirms.digitization_charge || 0.00);
                    $scope.invoice.exhibit_charge = parseFloat($scope.invoice.attorneyFirms.exhibit_charge || 0.00);
                    $scope.invoice.equipment_rental = parseFloat($scope.invoice.attorneyFirms.equipment_rental || 0.00);
                    $scope.invoice.video_sync = parseFloat($scope.invoice.attorneyFirms.video_sync || 0.00);
                    $scope.invoice.processing_fee = parseFloat($scope.invoice.attorneyFirms.processing_fee || 0.00);
                    $scope.invoice.color_exhibit_charge = parseFloat($scope.invoice.attorneyFirms.color_exhibit_charge || 0.00);
                    $scope.invoice.hyperlinked_exhibit_charge = parseFloat($scope.invoice.attorneyFirms.hyperlinked_exhibit_charge || 0.00);
                    $scope.invoice.exhibits_per_page = parseFloat($scope.invoice.attorneyFirms.exhibits_per_page || 0.00);
                    $scope.invoice.no_transcript_depo_appear_rate = parseFloat($scope.invoice.attorneyFirms.no_transcript_depo_appear_rate || 0.00);
                    $scope.invoice.no_transcript_arbitration_appear_rate = parseFloat($scope.invoice.attorneyFirms.no_transcript_arbitration_appear_rate || 0.00);
                    $scope.invoice.no_transcript_euo_appear_rate = parseFloat($scope.invoice.attorneyFirms.no_transcript_euo_appear_rate || 0.00);
                    $scope.invoice.video_cancellation = parseFloat($scope.invoice.attorneyFirms.video_cancellation || 0.00);

                    if($scope.invoice.rate_connection_method == 1){
                        $scope.invoice.RATE = parseFloat($scope.invoice.attorneyFirms.RATE || 0.00);
                        $scope.invoice.COPY_RATE = parseFloat($scope.invoice.attorneyFirms.COPY_RATE || 0.00);
                        $scope.invoice.APP_RATE = parseFloat($scope.invoice.attorneyFirms.APP_RATE || 0.00);
                        $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.attorneyFirms.WordIndexingPerPage || 0.00);
                        $scope.invoice.cancellation = parseFloat($scope.invoice.attorneyFirms.cancellation || 0.00);
                        $scope.invoice.medical_technical_expert = parseFloat($scope.invoice.attorneyFirms.medical_technical_expert || 0.00);
                    }else if($scope.invoice.rate_connection_method == 2){
                        $scope.invoice.RATE = parseFloat($scope.invoice.attorneyFirms.rate_remote || 0.00);
                        $scope.invoice.COPY_RATE = parseFloat($scope.invoice.attorneyFirms.copy_rate_remote || 0.00);
                        $scope.invoice.APP_RATE = parseFloat($scope.invoice.attorneyFirms.appear_rate_remote || 0.00);
                        $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.attorneyFirms.word_indexing_page_rate_remote || 0.00);
                    }else if($scope.invoice.rate_connection_method == 3){
                        $scope.invoice.RATE = parseFloat($scope.invoice.attorneyFirms.rate_nonsteno || 0.00);
                        $scope.invoice.COPY_RATE = parseFloat($scope.invoice.attorneyFirms.copy_rate_nonsteno || 0.00);
                        $scope.invoice.APP_RATE = parseFloat($scope.invoice.attorneyFirms.appear_rate_nonsteno || 0.00);
                        $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.attorneyFirms.word_indexing_page_rate_nonsteno || 0.00);
                        $scope.invoice.cancellation = parseFloat($scope.invoice.attorneyFirms.cancellation_nonsteno || 0.00);
                        $scope.invoice.medical_technical_expert = parseFloat($scope.invoice.attorneyFirms.medical_technical_expert_nonsteno || 0.00);
                    }else if($scope.invoice.rate_connection_method == 4){
                        $scope.invoice.RATE = parseFloat($scope.invoice.attorneyFirms.rate_arbitration || 0.00);
                        $scope.invoice.COPY_RATE = parseFloat($scope.invoice.attorneyFirms.copy_rate_arbitration || 0.00);
                        $scope.invoice.APP_RATE = parseFloat($scope.invoice.attorneyFirms.appear_rate_arbitration || 0.00);
                        $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.attorneyFirms.word_indexing_page_rate_arbitration || 0.00);
                    }else if($scope.invoice.rate_connection_method == 5){
                        $scope.invoice.RATE = parseFloat($scope.invoice.attorneyFirms.rate_euo || 0.00);
                        $scope.invoice.COPY_RATE = parseFloat($scope.invoice.attorneyFirms.copy_rate_euo || 0.00);
                        $scope.invoice.APP_RATE = parseFloat($scope.invoice.attorneyFirms.appear_rate_euo || 0.00);
                        $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.attorneyFirms.word_indexing_page_rate_euo || 0.00);
                    }else{
                        $scope.invoice.RATE = parseFloat($scope.invoice.attorneyFirms.RATE || 0.00);
                        $scope.invoice.COPY_RATE = parseFloat($scope.invoice.attorneyFirms.COPY_RATE || 0.00);
                        $scope.invoice.APP_RATE = parseFloat($scope.invoice.attorneyFirms.APP_RATE || 0.00);
                        $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.attorneyFirms.WordIndexingPerPage || 0.00);
                        $scope.invoice.cancellation = parseFloat($scope.invoice.attorneyFirms.cancellation || 0.00);
                        $scope.invoice.medical_technical_expert = parseFloat($scope.invoice.attorneyFirms.medical_technical_expert || 0.00);
                    }
                }
                else{
                    $scope.invoice.Room = 0.00;
                    $scope.invoice.RATE = 0.00;
                    $scope.invoice.med_rate = 0.00;
                    $scope.invoice.MiniScriptPerPage = 0.00;
                    $scope.invoice.COPY_RATE = 0.00;
                    $scope.invoice.exp_rate = 0.00;
                    $scope.invoice.WEB_AMOUNT = 0.00;
                    $scope.invoice.WordIndexingPerPage = 0.00;
                    $scope.invoice.APP_RATE = 0.00;
                    $scope.invoice.daily_rate = 0.00;
                    $scope.invoice.CDCharge = 0.00;
                    $scope.invoice.MiniScriptFlatRate = 0.00;
                    $scope.invoice.INV_MINPG = 0.00;
                    $scope.invoice.immed_rate = 0.00;
                    $scope.invoice.ETranscript = 0.00;
                    $scope.invoice.WordIndexingFlatRate = 0.00;
                    $scope.invoice.INV_MIN = 0.00;
                    $scope.invoice.SOR = 0.00;
                    $scope.invoice.rate_id = null;
                    $scope.invoice.SHOW_CALCS = 'No'.toLowerCase();
                    $scope.invoice.Charge2 = 0.00;
                    $scope.invoice.POSTAGE = 0.00;
                    $scope.invoice.admin_fee = 0.00;
                    $scope.invoice.web_charge_per_witness = 0.00;
                    $scope.invoice.video_conference_handling_and_connection_fee = 0.00;
                    $scope.invoice.reminder_charge = 0.00;
                    $scope.invoice.legalview_charge = 0.00;
                    $scope.invoice.digitization_charge = 0.00;
                    $scope.invoice.exhibit_charge = 0.00;
                    $scope.invoice.equipment_rental = 0.00;
                    $scope.invoice.video_sync = 0.00;
                    $scope.invoice.processing_fee = 0.00;
                    $scope.invoice.color_exhibit_charge = 0.00;
                    $scope.invoice.hyperlinked_exhibit_charge = 0.00;
                    $scope.invoice.exhibits_per_page = 0.00;
                    $scope.invoice.no_transcript_depo_appear_rate = 0.00;
                    $scope.invoice.no_transcript_arbitration_appear_rate = 0.00;
                    $scope.invoice.no_transcript_euo_appear_rate = 0.00;
                    $scope.invoice.video_cancellation = 0.00;
                    $scope.invoice.cancellation = 0.00;
                    $scope.invoice.medical_technical_expert = 0.00;
                }
            }

            // Calculate the All witness here also because, here we will get the rate information object.
            $scope.calculateAllWitnessCharges();

            // Populate the additional charge from existing attorney firm charges
            $scope.populateAdditionalChargeFromAttorney();
        };

        $scope.setBlankToAttorneyRates = function(){
            $scope.invoice.Room = 0.00;
            $scope.invoice.RATE = 0.00;
            $scope.invoice.med_rate = 0.00;
            $scope.invoice.MiniScriptPerPage = 0.00;
            $scope.invoice.COPY_RATE = 0.00;
            $scope.invoice.exp_rate = 0.00;
            $scope.invoice.WEB_AMOUNT = 0.00;
            $scope.invoice.WordIndexingPerPage = 0.00;
            $scope.invoice.APP_RATE = 0.00;
            $scope.invoice.daily_rate = 0.00;
            $scope.invoice.CDCharge = 0.00;
            $scope.invoice.MiniScriptFlatRate = 0.00;
            $scope.invoice.INV_MINPG = 0.00;
            $scope.invoice.immed_rate = 0.00;
            $scope.invoice.ETranscript = 0.00;
            $scope.invoice.WordIndexingFlatRate = 0.00;
            $scope.invoice.INV_MIN = 0.00;
            $scope.invoice.SOR = 0.00;
            $scope.invoice.rate_id = null;
            $scope.invoice.SHOW_CALCS = ('No').toLowerCase();
            $scope.invoice.Charge2 = 0.00;
            $scope.invoice.POSTAGE = 0.00;
            $scope.invoice.admin_fee = 0.00;
            $scope.invoice.web_charge_per_witness = 0.00;
            $scope.invoice.video_conference_handling_and_connection_fee = 0.00;
            $scope.invoice.reminder_charge = 0.00;
            $scope.invoice.legalview_charge = 0.00;
            $scope.invoice.digitization_charge = 0.00;
            $scope.invoice.exhibit_charge = 0.00;
        };

        $scope.populateAdditionalChargeFromAttorney = function () {
            if(!$scope.invoice.invoiceID){
                // if ($scope.invoice.APP_RATE > 0)
                $scope.addAdditionalChargeFromRateInfo('A', $scope.invoice.APP_RATE);

                // if ($scope.invoice.MiniScriptPerPage > 0)
                // $scope.addAdditionalChargeFromRateInfo('MSPAGE', $scope.invoice.MiniScriptPerPage);

                // if ($scope.invoice.Room > 0)
                $scope.addAdditionalChargeFromRateInfo('FF', $scope.invoice.Room);

                // if ($scope.invoice.POSTAGE > 0)
                $scope.addAdditionalChargeFromRateInfo('P', $scope.invoice.POSTAGE);

                // if ($scope.invoice.WordIndexingPerPage > 0)
                // $scope.addAdditionalChargeFromRateInfo('WIPAGE', $scope.invoice.WordIndexingPerPage);

                // if ($scope.invoice.WEB_AMOUNT > 0)
                $scope.addAdditionalChargeFromRateInfo('WEB', $scope.invoice.WEB_AMOUNT);

                // if ($scope.invoice.MiniScriptFlatRate > 0)
                $scope.addAdditionalChargeFromRateInfo('MS', $scope.invoice.MiniScriptFlatRate);

                // if ($scope.invoice.CDCharge > 0)
                $scope.addAdditionalChargeFromRateInfo('D', $scope.invoice.CDCharge);

                // if ($scope.invoice.WordIndexingFlatRate > 0)
                $scope.addAdditionalChargeFromRateInfo('APP', $scope.invoice.WordIndexingFlatRate);

                // if ($scope.invoice.Charge2 > 0)
                $scope.addAdditionalChargeFromRateInfo('C2', $scope.invoice.Charge2);

                // if ($scope.invoice.ETranscript > 0)
                $scope.addAdditionalChargeFromRateInfo('ET', $scope.invoice.ETranscript);

                // if ($scope.invoice.admin_fee > 0)
                $scope.addAdditionalChargeFromRateInfo('admin', $scope.invoice.admin_fee);

                // if ($scope.invoice.legalview_charge > 0)
                $scope.addAdditionalChargeFromRateInfo('AL', $scope.invoice.legalview_charge);

                // if ($scope.invoice.digitization_charge > 0)
                $scope.addAdditionalChargeFromRateInfo('P2', $scope.invoice.digitization_charge);

                // if ($scope.invoice.no_transcript_depo_appear_rate > 0)
                $scope.addAdditionalChargeFromRateInfo('DAFNTO', $scope.invoice.no_transcript_depo_appear_rate);

                // if ($scope.invoice.no_transcript_arbitration_appear_rate > 0)
                $scope.addAdditionalChargeFromRateInfo('AAFNTO', $scope.invoice.no_transcript_arbitration_appear_rate);

                // if ($scope.invoice.no_transcript_euo_appear_rate > 0)
                $scope.addAdditionalChargeFromRateInfo('EUOAFNTO', $scope.invoice.no_transcript_euo_appear_rate);

                // if ($scope.invoice.equipment_rental > 0)
                $scope.addAdditionalChargeFromRateInfo('EQR', $scope.invoice.equipment_rental);

                // if ($scope.invoice.video_sync > 0)
                $scope.addAdditionalChargeFromRateInfo('v-sync', $scope.invoice.video_sync);

                $scope.addAdditionalChargeFromRateInfo('VIDEO-CANCEL-FEE', $scope.invoice.video_cancellation);

                $scope.addAdditionalChargeFromRateInfo('CANCEL-FEE', $scope.invoice.cancellation);

                $scope.addAdditionalChargeFromRateInfo('EXPERT-FEE', $scope.invoice.medical_technical_expert);

                // Add exhibits charge only if job has exhibits and DI approval
                if ($scope.diaryData.exhibit_documents && $scope.diaryData.exhibit_documents.length > 0) {
                    if (($scope.invoice.attorneyFirms.rate_id != 0 && ($scope.rateCardRate && $scope.rateCardRate.deposition_insights == 1))
                        || ($scope.invoice.attorneyFirms.rate_id == 0 && $scope.invoice.attorneyFirms.di_job_summary == '1')) {
                        // if ($scope.invoice.exhibit_charge > 0)
                        $scope.addAdditionalChargeFromRateInfo('sync-MISU-19-14', $scope.invoice.exhibit_charge);

                        // if ($scope.invoice.color_exhibit_charge > 0)
                        $scope.addAdditionalChargeFromRateInfo('sync-MISU-26-14', $scope.invoice.color_exhibit_charge);

                        // if ($scope.invoice.exhibits_per_page > 0)
                        $scope.addAdditionalChargeFromRateInfo('E', $scope.invoice.exhibits_per_page);

                        // if ($scope.invoice.hyperlinked_exhibit_charge > 0)
                        $scope.addAdditionalChargeFromRateInfo('sync-MISU-276-14', $scope.invoice.hyperlinked_exhibit_charge);

                        // if ($scope.invoice.processing_fee > 0)
                        $scope.addAdditionalChargeFromRateInfo('PROCE', $scope.invoice.processing_fee);
                    }
                }

                // adding witness reminder charge if reminder sent by the system.
                if($scope.invoice.JOB_NO){
                    commonFactory.post('/api/getWitnessReminderByJob', {JOB_NO : $scope.invoice.JOB_NO})
                        .success(function (response) {
                            if (response && response.result && response.result.reminder && response.result.reminder.length) {
                                $scope.addAdditionalChargeFromRateInfo('AIF', $scope.invoice.reminder_charge);
                            }
                        });
                }
            }
        };

        /**
         * Function for updating the additional charge section if user enter the more than zero amount in rate section
         * @param alreadyHaveChargeCalculated which is used for letting know the code that we are populating the historical data and in which user directly override the calculated the charge.
         */
        $scope.addAdditionalChargeFromRateInfo = function (CHG_CODE, AMT,alreadyHaveChargeCalculated, invchgID, quantity=1) {

                // check the object is proper or not
                $scope.invoice.additional_charges = (typeof $scope.invoice.additional_charges === 'undefined') ? [{
                    QTY: 1,
                    AMT: 0
                }] : $scope.invoice.additional_charges;

                // First look into existing array of invoice additional charges
                if(invchgID){
                    var charge_code_object = $filter('filter')($scope.invoice.additional_charges, {invchgID: invchgID}, true);
                } else{
                    var charge_code_object = $filter('filter')($scope.invoice.additional_charges, {CHG_CODE: CHG_CODE}, true);
                }

                // get the Object from Additional Charge well
                var MASTER_CHG_CODE_object = $filter('filter')($scope.additional_charges, {CHG_CODE: CHG_CODE}, true);


                if (typeof charge_code_object == 'undefined') {
                    var charge_code_index = '-1';
                }
                else {
                    var charge_code_index = $scope.invoice.additional_charges.indexOf(charge_code_object[0]);
                }

                if (charge_code_index != '-1') {
                    if(parseFloat(AMT) != 0){

                        $scope.invoice.additional_charges[charge_code_index].AMT = (AMT || MASTER_CHG_CODE_object[0].AMT);
                    }
                    else{

                        // $scope.invoice.additional_charges.splice(charge_code_index, 1);
                    }
                } else {
                    if(parseFloat(AMT) != 0){
                        // get the object from additional charge drop down list
                        // var charge_code_object = $filter('filter')($scope.additional_charges, {CHG_CODE: CHG_CODE}, true)[0];

                        // If we don't have that charge code in our list then just return it without adding into additional charge list
                        if (typeof MASTER_CHG_CODE_object === 'undefined') {
                            return;
                        }

                        if($scope.invoice.additional_charges.length > 0){
                            if ($scope.invoice.additional_charges && $scope.invoice.additional_charges[0].CHG_CODE) {
                                charge_code_index = $scope.invoice.additional_charges.length;
                            } else {
                                charge_code_index = 0;
                            }
                        }else {
                            charge_code_index = 0;
                        }

                        $scope.invoice.additional_charges[charge_code_index] = angular.copy(MASTER_CHG_CODE_object[0]);
                        $scope.invoice.additional_charges[charge_code_index].QTY = Number(quantity);
                        $scope.invoice.additional_charges[charge_code_index].SPLIT = Number(1);
                        $scope.invoice.additional_charges[charge_code_index].AMT = (AMT || MASTER_CHG_CODE_object[0].AMT);
                    }
                }

                $scope.calculationAdditionalCharge(charge_code_index,alreadyHaveChargeCalculated);
        };

        $scope.refreshCalculation = function(){
            angular.forEach($scope.invoice.additional_charges, function ($item, index) {
                $scope.calculationAdditionalCharge(index);
            });
        }

        $scope.calculationAdditionalCharge = function ($index,alreadyHaveChargeCalculated) {
            var $item = $scope.invoice.additional_charges[$index];

            if($item){
                var AMT = $item.AMT;

                //amount = Number(($item.amount / 2).toFixed(2));


                if(!alreadyHaveChargeCalculated){
                    $item.charge = Number(($item.QTY * AMT / $item.SPLIT).toFixed($rootScope.round_to_decimal_number));
                }


                $scope.invoice.additional_charges[$index] = $item;

            }

            // Add the Charge of Complete additional Charge
            $scope.invoice.additional_charge_amount = $scope.invoice.additional_charges.reduce(function (pv, cv) {
                if(cv.charge){
                    return pv + parseFloat(cv.charge);
                }
                else{
                    return pv;
                }
            }, 0);



            $scope.calculateInvoiceAmount();
        };

        // Add More Additional Charge
        $scope.add_additional_charge = function () {
            $scope.invoice.additional_charges.push({});
        };

        //  Remove Additional Charge
        $scope.remove_additional_charge = function ($index) {
            $scope.invoice.additional_charges.splice($index, 1);
            // $scope.invoice.additional_charge_amount = $scope.invoice.additional_charges.reduce(function (pv, cv) {
            //     return pv + cv.charge;
            // }, 0);
            $scope.calculationAdditionalCharge($index);
        };

        $scope.calculateInvoiceAmount = function () {

            $scope.invoice.copy_fees = 0;

            angular.forEach($scope.invoice.witnessData, function ($item, index) {
                if ($item.is_add) {
                    $scope.invoice.copy_fees += eval($item.line_pay);
                }else{
                    $scope.invoice.witnessData[index].charge_code = 0.00;
                    $scope.invoice.witnessData[index].line_pay = 0.00;
                }
            });


            // $scope.invoice.total_invoice_amount = eval($scope.invoice.total_rep_pay_amt || 0) +
            //     eval($scope.invoice.copy_fees || 0) +
            //     eval($scope.invoice.additional_charge_amount || 0) +
            //     eval($scope.invoice.typ_pay_amt || 0);

            $scope.invoice.total_invoice_amount = eval($scope.invoice.copy_fees || 0) + eval($scope.invoice.additional_charge_amount || 0);

            $scope.invoice.total_job_invoices_amount = eval($scope.invoice.total_job_amount || 0) + eval($scope.invoice.total_invoice_amount || 0);
        };

        $scope.calculateAllWitnessCharges = function () {
            if ($scope.is_pulled_witness) {
                $timeout(function () {
                    angular.forEach($scope.invoice.witnessData, function ($item, $index) {
                        $scope.calculateRowWitnessCharge($index);
                        //$item.TOTAL_PAGES = $scope.calPaidPages($item.PAGES, $scope.invoice.job.STIPS);
                        //$scope.invoice.witnessData.splice( $index, 0, $item );
                        //$scope.invoice.witnessData[$index] = $item;
                    });
                }, 100);
            }

        };

        $scope.calculateRowWitnessCharge = function ($index, is_split_change) {


            var $item = $scope.invoice.witnessData[$index];


            // set default value for witness data
            $item.PAGE_START = parseFloat($item.PAGE_START || 0);
            $item.PAGE_END = parseFloat($item.PAGE_END || 0);
            $item.PAGES = parseFloat($item.PAGES || 0);
            $item.IndexPages = parseFloat($item.IndexPages || 0);
            $item.MiniScriptPages = parseFloat($item.MiniScriptPages || 0);
            $item.SPLIT = parseFloat($item.SPLIT || 0);
            $item.Enclosed = parseFloat($item.Enclosed || 0);
            $item.COPIES = parseFloat($item.COPIES || 0);
            $item.rate_type = $item.rate_type || 'rate';
            $item.addrate = $item.addrate || 'norm';
            $item.rate_flag = '-';

            if($scope.invoice.billing_type == 'NSB'){
                // override chages for non split billing
                if($item.nsb_type =='original'){
                    $item.SPLIT = '0';
                    $item.Enclosed = '1';
                    $item.COPIES = '1';
                }
                else if($item.nsb_type =='copy'){
                    $item.SPLIT = '1';
                    $item.Enclosed = '1';
                    $item.COPIES = '1';
                }
                else if($item.nsb_type =='o+1'){
                    $item.SPLIT = '1';
                    $item.Enclosed = '1';
                    $item.COPIES = '1';

                }
                else if($item.nsb_type =='o+2'){
                    $item.SPLIT = 1;
                    $item.Enclosed = 2;
                    $item.COPIES = 2;
                }
            }

            if($item.is_add){

                if (typeof is_split_change != "undefined" && is_split_change) {
                    $item.COPIES = eval($item.SPLIT) + 1;
                }


                var medical_charge = 0;
                var additional_rate_charge = 0;
                var rate_value = 0;

                // Calculate all Total Page while changing the Page number
                // consider Indexpage and MininScrirtPage only when MS Per Page and WI Per Page have value greater then zero
                $item.TOTAL_PAGES = eval(eval($item.PAGES || 0) + eval(($scope.invoice.WordIndexingPerPage > 0) ? $item.IndexPages : 0) + eval(($scope.invoice.MiniScriptPerPage > 0)?$item.MiniScriptPages : 0)) || 0;

                // check the medical charge is checked

                if ($item.medical_charge) {
                    medical_charge = round_to_number($scope.invoice.med_rate);
                }


                //Total = ((Copy-Rate* (split -1) + Rate) / split

                $item.rate_flag = ($scope.invoice.billing_type=='SB' && ($scope.invoice.STIPS == 'S' || $scope.invoice.STIPS == 'Y'))? ($item.Enclosed > 1) ? 'o+'+$item.Enclosed : $item.Enclosed+'cc' : 'o+'+$item.Enclosed;

                // Mark give the changes for STIP and Split, So detail please visit https://dtopinc.atlassian.net/browse/DEITZBO-209
                // We need to consider only copy rate instead of calculating according formula
                if(($scope.invoice.billing_type=='NSB' && $item.nsb_type=='copy') || ($scope.invoice.billing_type=='SB' && (($scope.invoice.STIPS=='Y' || $scope.invoice.STIPS=='S') && $item.SPLIT == 1 && $item.Enclosed == 1 && $item.COPIES == 1) ||
                        ($scope.invoice.STIPS=='F' && $item.SPLIT == 1 && $item.Enclosed == 1 && $item.COPIES == 0))){
                    $item.total = round_to_number(eval($scope.invoice.COPY_RATE || 0));
                    $item.rate_flag = '1cc';
                }else{
                    if($item.SPLIT > 0) {
                        $item.total = round_to_number((eval($scope.invoice.COPY_RATE || 0) * (eval($item.SPLIT || 0) - 1) + eval($scope.invoice.RATE || 0)) / eval($item.SPLIT || 0));
                    }
                    else{
                        // Total = Copies * rate;
                        $item.total = round_to_number((eval($item.COPIES || 0) *  eval($scope.invoice.RATE || 0)));
                    }
                }


                // As Mark Suggested https://dtopinc.atlassian.net/browse/DEITZBO-446
                if($item.SPLIT == 1 && $item.Enclosed == 1 && $item.COPIES > 1){
                    $item.total = round_to_number(eval($scope.invoice.COPY_RATE || 0) * $item.COPIES);
                    $item.rate_flag = $item.COPIES+'cc';
                }


                // As Mark Suggested https://dtopinc.atlassian.net/browse/DEITZBO-344
                if($item.SPLIT == 0 && $item.Enclosed == 1 && $item.COPIES == 1){
                    $item.rate_flag = 'o';
                }


                // If Additional Rate Type is selected
                if (typeof $item.addrate !== 'undefined') {

                    if ($item.addrate == 'exp_rate') {
                        additional_rate_charge = $scope.invoice.exp_rate;
                    }
                    else if ($item.addrate == 'daily_rate') {
                        additional_rate_charge = $scope.invoice.daily_rate;
                    }
                    else if ($item.addrate == 'immed_rate') {
                        additional_rate_charge = $scope.invoice.immed_rate;
                    }
                    else {
                        additional_rate_charge = 0.00;
                    }
                }
                

                // When user select bust in row then we need to remove all charge from line pay
                if ($item.rate_type && ($item.rate_type == 'bust' || $item.rate_type == 'sor' || $item.rate_type == 'invoice_min' || $item.rate_type == 'sync_data')) {
                    medical_charge = 0;
                    $item.medical_charge = false;
                    $item.addrate = 0;

                    $item.charge = 0;

                    if ($item.rate_type == 'bust') {
                        $item.line_pay = round_to_number($scope.invoice.INV_MIN);
                    }
                    if ($item.rate_type == 'sor') {
                        $item.line_pay = round_to_number($scope.invoice.SOR);
                    }

                    if ($item.rate_type == 'invoice_min') {
                        $item.line_pay = round_to_number($scope.invoice.INV_MINPG);
                    }
                    if ($item.rate_type == 'sync_data') {
                        $item.line_pay = 0;
                    }

                   
                }
                
                else {

                   
                    
                    //Charge = (Med or 0) + (Exp or Daily or immed) + (Total or 0)
                    $item.charge =  round_to_number(eval(medical_charge || 0) + eval(additional_rate_charge || 0) + eval($item.total || 0));
                  
                    //$item.charge = round_to_number(eval(medical_charge || 0) + eval(additional_rate_charge || 0) + eval($item.total || 0), $rootScope.round_to_decimal_number);
                    //Line charge = Charge * Total Page.
                    $item.line_pay = round_to_number($item.charge * $item.TOTAL_PAGES);
                    //$item.line_pay = round_to_number($item.charge * eval($item.TOTAL_PAGES), $rootScope.round_to_decimal_number);

                }

                 //add Deposition Insights
                 if($scope.checkDuplicateDepositionInsightsAditionalCharges($item)){
                       
                    $item.is_deposition_insights = true;
                }else{
                  
                    //$item.is_deposition_insights = false;
                    $scope.removeDepositionInsightsAditionalCharges($item)
                    // DI charges populate only on Client default Rate - ODP-1733 OR Rate card has YES in deposition_insights [ODP-166o]
                    // DI charges populate only on Client default Rate - ODP-1733 OR Rate card has YES in deposition_insights [ODP-166o]
                    if($scope.diaryData.businessunit.di_job_summary == '1' && $scope.diaryData.location_id != 61){
                        //Rate card has YES in deposition_insights then show DI charges.
                        if( $scope.invoconsole.logice.attorneyFirms.rate_id != 0 && ($scope.rateCardRate && $scope.rateCardRate.deposition_insights == 1)){
                            $item.is_deposition_insights = true;
                            $scope.addDepositionInsightsAditionalCharges($item)
                        }
                        if($scope.invoice.attorneyFirms.rate_id == 0 ){
                            if($scope.invoice.attorneyFirms && !$scope.invoice.attname_id){
                                if($scope.invoice.attorneyFirms.di_job_summary == '1'){
                                    $item.is_deposition_insights = true;
                                    $scope.addDepositionInsightsAditionalCharges($item)
                                }
                            }else if($scope.invoice.attorneyFirms && $scope.invoice.attname_id){
                                var attorney = $filter('filter')($scope.attorney, { id:$scope.invoice.attname_id }, true);
                                if(attorney.length){

                                    if($scope.invoice.attorneyFirms.di_job_summary == '1' && attorney[0].di_job_summary == '1'){   
                                        $item.is_deposition_insights = true;
                                        $scope.addDepositionInsightsAditionalCharges($item)
                                    }
                                }else{
                                    if($scope.invoice.attorneyFirms.di_job_summary == '1'){
                                        $item.is_deposition_insights = true;
                                        $scope.addDepositionInsightsAditionalCharges($item)
                                    }
                                }
                            }
                        }
                    }
                }
                //$scope.invoice.witnessData.splice( $index, 0, $item );
            }
            else {
                $scope.removeDepositionInsightsAditionalCharges($item)
                $item.is_deposition_insights = false;
                $item.charge = $filter('number')(0, 2);
                $item.line_pay = $filter('number')(0, 2);
            }


            $scope.invoice.witnessData[$index] = $item;

            // Calculate the Total Invoice Amount
            $scope.calculateInvoiceAmount();
        };

        $scope.showJobInvoiceHistory = function (JOB_NO) {

            if (JOB_NO) {
                $scope.invoice_table_grid = true;
                $scope.current_date = moment().format('M/YYYY');
                $scope.job_invoices_date = [];
                var data = {job_no: JOB_NO};
                commonFactory.post('/api/invoice/get-job-invoices', data).success(function (response) {
                    if (response.status == '200') {
                        $scope.invoice.total_job_amount = response.result.total_job_invoices_amount;

                        $scope.invoice.total_job_invoices_amount = response.result.total_job_invoices_amount;
  
                        $scope.invoice.job_gm =  round_to_number($scope.invoice.total_job_amount- $scope.invoice.job_grand_total);
                        $scope.invoice.job_gm_per = ($scope.invoice.total_job_amount !=0) ? round_to_number($scope.invoice.job_gm/ $scope.invoice.total_job_amount *100) :0;

                        $scope.job_invoices = $filter('filter')(response.result.invoices, function (item) {
                            return item.INV_NO != "" && (item.diary_data.attorney_firm && item.diary_data.attorney_firm.NAME != "") && (item.attorney_firms && item.attorney_firms.NAME);
                        });
                        if ($scope.job_invoices.length > 0) {
                            let pageTitle = $scope.job_invoices.length > 1 ? JOB_NO : $scope.invoice.original_invoice_no;
                            $state.$current.data.pageTitle = pageTitle;
                            $rootScope.$broadcast('changeTitle', $state);
                        }
                        $scope.isHaveJobPayroll= false;
                        angular.forEach($scope.job_invoices,function(value,key){
                            if(value.job_payroll.length !=0){
                                $scope.isHaveJobPayroll =true;
                                var inv_cost = value.job_payroll.reduce((total, payroll) => {
                                    return total + payroll.REP_PAYAMT +  payroll.TYP_PAYAMT ;
                                  }, 0);

                                $scope.job_invoices[key].inv_cost = round_to_number(inv_cost);
                                $scope.job_invoices[key].inv_gp = round_to_number(value.INV_AMT-inv_cost);
                                $scope.job_invoices[key].inv_gm =  round_to_number($scope.job_invoices[key].inv_gp/value.INV_AMT*100);
                            }
                             $scope.job_invoices_date[key] = moment(value.dte_invoice).format('M/YYYY');
                        });
                        $scope.invoice_table_grid = false;

                        // Manually forced to calculate Invoice Amount because we have total invoice amount here only So i put manual call here. In create controller we have the
                        // Total job amount as part of diary data
                        $scope.calculateInvoiceAmount();
                    }
                }).error(function (error) {
                    $scope.invoice_table_grid = false;
                });
            }
        };

        $scope.viewJobPayment = function () {
            var JOB_NO = $scope.invoice.JOB_NO;

            if (JOB_NO) {
                var jobModal = $uibModal.open({
                    templateUrl: "modules/payroll/payment_popup_view_resources.html",
                    controller: 'newEditpayrollController',
                    resolve: {
                        JOB_NO: function () {
                            return JOB_NO;
                        },
                        loadPlugin: function ($ocLazyLoad) {
                            return $ocLazyLoad.load([
                                {
                                    name: 'localytics.directives',
                                    files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                                }
                            ]);
                        },
                    },
                    
                    windowClass: 'full-width-model',
                    keyboard: false,
                    backdrop: false,
                    size: 'lg',
                    close: function () {

                    }
                });
            }
        };

        $scope.showDiaryPopupForEdit = function () {
            var diaryModal = $uibModal.open({
                templateUrl: "modules/resources/templates/diary_edit_popup.html",
                controller: 'editcaseController',
                resolve: {
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([{
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },{
                            name: 'ngTagsInput',
                            files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                        }]);
                    },
                    JOB_NO: function () {
                        return $scope.invoice.JOB_NO;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });
            diaryModal.closing = function () {
                preventDefault();
            };
            diaryModal.result.then(function (job_id) {
                $scope.editInvoiceJobFetchData($scope.invoice_id);
            }, function () {

            });
        };

        $scope.showInvoicePrint = function (invoice_id) {
            if (angular.isArray(invoice_id)) {
                var invoices = invoice_id;
            }
            else {
                var invoices = [{text: invoice_id}];
            }

            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/view_popup.html",
                controller: 'viewPrintInvoiceController',
                resolve: {
                    invoice_id: function () {
                        return invoices;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });


            invoiceModal.result.then(function (is_invoice_printed) {
                if (is_invoice_printed) {
                    // var data = {invoice_ids : invoices};
                    // need to update the flag for print
                    $scope.updateInvoiceFlagForPrinted(invoices);
                }
            }, function () {
            });

        }

        $scope.generateSingleInvoice = function(invoice_id){
            swal({
                    title: "Print with Proof of Order",
                    text: "You Are Going to Print Invoice with Proof of Order.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Print it.",
                    cancelButtonText: "No",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    // Generate invoice and open in new tab
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        invoiceFactory.post('/api/invoice/generateInvoice', {invoice_id}).success(function(res){
                            $rootScope.showLoader(false);
                            if(res.error){
                                $rootScope.showLoader(false);
                                Notification.error(res.result.message);
                            }else{
                                Notification.success(res.result.message.success);
                                $rootScope.showLoader(false);
                                window.open(res.result.pdfUrl,'_blank');
                            }
                        }).error(function(err){
                            $rootScope.showLoader(false);
                            Notification.error(err);
                        });
                    }
                });
        }

        //Refresh the Claim name rep if edited
        $scope.refreshOtherClaimRep = function (value) {
            $rootScope.showLoader(true);
            var data = {};
            data.id = value;
            commonFactory.get('/api/claimrepresentativename/' + value + '/edit')
                .success(function (response) {
                    $rootScope.showLoader(false);
                    if (response && response.result && response.result.claim_rep_name) {
                        $scope.invoice.billing_claim_representative = response.result.claim_rep_name || null;
                    }
                })
                .error(function (err) {
                    $rootScope.showLoader(false);
                });
        };

        /**
         *
         */
        $scope.clearBatchInvoices = function () {

            swal({
                    title: "Are you sure ?",
                    text: "Have you printed invoices from the batch?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, I have printed successfully",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    // Clear the Batch
                    if (isConfirm) {
                        $rootScope.batchInvoiceArray = [];
                        $rootScope.$broadcast('batchCountBroadcastEvent', $rootScope.batchInvoiceArray); // going down!
                    }
                });
        };

        $scope.viewInvoice = function (invoice_id) {
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/invoice_view.html",
                controller: 'viewInvoiceController',
                resolve: {
                    invoice_id: function () {
                        return invoice_id;
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            {
                                files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                            },
                            {
                                insertBefore: '#loadBefore',
                                name: 'localytics.directives',
                                files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                            },
                        ]);
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });


            invoiceModal.result.then(function (is_invoice_printed) {
                if (is_invoice_printed) {
                    // var data = {invoice_ids : invoices};
                    // need to update the flag for print
                    //$scope.updateInvoiceFlagForPrinted(invoices);
                }
            }, function () {
            });
        };

        //Refreshing Firm and get related firm information
        $scope.refreshFirm = function (id) {

            if (id) {
                $rootScope.showLoader(true);
                var data = {attorney_id: id}
                var rate_id =  $scope.invoice.attorneyFirms.rate_id
                async.series([
                        function(callback){
                            // do some stuff ...
                            commonFactory.post('/api/getAttorneyfirmdata', data)
                                .success(function (response) {
                                    $scope.invoice.attorneyFirms = response.result.attorney_data || '';
                                    $scope.invoice.purchase_order = $scope.invoice.attorneyFirms.default_purchase_order;
                                    $scope.invoice.billing_claim_representative = angular.copy($scope.invoice.attorneyFirms.claim_rep_name);

                                    $scope.invoice.invoice_and_transcript_handling_id = $scope.invoice.attorneyFirms.invoice_and_transcript_handling_id;
                                    $scope.invoice.attorneyFirms.rate_id = rate_id;
                                    //assigning status and beeper(autopartner) of firm.
                                    $scope.firm_status = $scope.invoice.attorneyFirms.STATUS;
                                    $scope.assigningAutoPartner($scope.invoice.attorneyFirms);

                                    // $scope.rateInformationAssignment();
                                   
                                    // $scope.getAttorneyRates(id,true);
                                    
                                    
                                    callback(null, 'one');
                                })
                                .error(function (err) {
                                    $rootScope.showLoader(false);
                                    callback(null, 'one');
                                });

                        },
                        function(callback){
                            var firmdata = {firm_id: id}
                            // do some more stuff ...
                            commonFactory.post('/api/attorneybyfirm', firmdata)
                                .success(function (response) {
                                    $rootScope.showLoader(false);
                                    if (response) {
                                        if (response && response.result && response.result.attorney_data) {
                                            $scope.attorney = response.result.attorney_data || '';
                                        }
                                    }
                                })
                                .error(function (err) {
                                    $rootScope.showLoader(false);
                                });

                            callback(null, 'two');
                        }
                    ],
                    // optional callback
                    function(err, results){
                        // results is now equal to ['one', 'two']
                    });
            } else {
                $rootScope.showLoader(false);
            }
        };

        /**
         * updateInvoiceFlagForPrinted
         * @param invoices
         */
        $scope.updateInvoiceFlagForPrinted = function (invoices) {
            var data = {invoice_ids: invoices};
            // need to update the flag for print
            commonFactory.post('/api/invoice/mark-as-printed', data).success(function (response) {
                if ($scope.invoice.JOB_NO && $scope.invoice.JOB_NO != '') {
                    $scope.showJobInvoiceHistory($scope.invoice.JOB_NO);
                }
            }).error(function () {

            });
        };

        $scope.redirectToInvoice = function(invoiceId){
            $state.go('invoice.edit',{invoice_id:invoiceId},{ reload: true });
        }

        $scope.saveJobInvoice = function(valid, is_print, is_pulled_data, make_fresh_job_form, redirect_to_payment=false){
            
            if (valid && $scope.isFirmSelected && $scope.isJobSelected) {

                var isWitnessSelected = false;
                angular.forEach($scope.invoice.witnessData, function(value, key){
                    if(value.is_add){
                        isWitnessSelected = true;
                    }
                });

                if(!isWitnessSelected){               
                    swal({
                        title: "Are you sure want to save?",
                        text: "You haven't selected any witnesses.",
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#104a7b",
                        confirmButtonText: "Save",
                        cancelButtonText: "Cancel"
                    },function(isConfirm){
                        if(!isConfirm){
                            return false;
                        }else{
                            $scope.saveJobInvoiceAfterConfirmation(valid, is_print, is_pulled_data, make_fresh_job_form, redirect_to_payment);
                        }
                    });
                }else{
                   $scope.saveJobInvoiceAfterConfirmation(valid, is_print, is_pulled_data, make_fresh_job_form, redirect_to_payment);
                }
            }else {
                swal("Oops", "Please fill out all required fields", "warning");
                $window.scrollTo(0, 0);
            }
        }

        $scope.saveJobInvoicehold = function(valid, is_print, is_pulled_data, make_fresh_job_form, redirect_to_payment=false){
            
            if (valid && $scope.isFirmSelected && $scope.isJobSelected) {

                var isWitnessSelected = false;
                angular.forEach($scope.invoice.witnessData, function(value, key){
                    if(value.is_add){
                        isWitnessSelected = true;
                    }
                });

                $scope.saveJobInvoiceAfterConfirmation(valid, is_print, is_pulled_data, make_fresh_job_form, redirect_to_payment);
                
            }else {
                swal("Oops", "Please fill out all required fields", "warning");
                $window.scrollTo(0, 0);
            }
        }       
        
        $scope.saveJobInvoiceAfterConfirmation = function (valid, is_print, is_pulled_data, make_fresh_job_form, redirect_to_payment) {
            
            var attnameObj = $scope.attorney.filter(function( obj ) {
                return obj.id == $scope.invoice.attname_id;
            });
            $scope.invoice.attnameObj = attnameObj[0] && attnameObj[0].id ? attnameObj[0]:null;

            if($scope.INV_NO > 0 && $scope.invoice.invoiceID < 1){
                $scope.invoice.INV_NO = $scope.INV_NO;
            }
 
            if($scope.invoice.billing_claim_representative && typeof $scope.invoice.billing_claim_representative != "object")
            {
                $scope.invoice.billing_claim_representative = '';
            }

            // just disabled the button to preventing the user to multiple clicks
            $scope.isSubmitting = true;

            $scope.invoice.INV_DT = moment($scope.invoice.INV_DT).format('MM/DD/YYYY');
            $rootScope.showLoader(true);
            // Update the invoice
            if ($scope.invoice.invoiceID) {

                commonFactory.put('/api/invoice/' + $scope.invoice.invoiceID, $scope.invoice)
                    .success(function (response) {

                    // just disabled the button to preventing the user to multiple clicks
                    $scope.isSubmitting = false;

                    $rootScope.showLoader(false);
                    anchorSmoothScroll.scrollTo('invoiceManuallyDiv');
                    if (response.status && response.status == 200) {

                        //GENERATE & NEW JOB
                        if(!make_fresh_job_form && !redirect_to_payment){
                            // Fetch the Job Invoices
                            $scope.showJobInvoiceHistory($scope.invoice.JOB_NO);
                        }

                        // If user press only save button then we need to disable attorney firm box
                        if(!make_fresh_job_form && !is_pulled_data && !is_print && !redirect_to_payment){
                            $scope.userWantToEdit = true;
                        }else{
                            $scope.userWantToEdit = false;
                        }
                        $scope.invoice.group_name_data = $scope.invoice.group_name;
                        $scope.invoice.INV_NO = response.result.INV_NO;
                        $scope.invoice.invoiceID = response.result.invoice_id;
                        Notification.success({ message : response.result.message, delay : 30000,  title: 'Invoice updated'});

                        // check whether invoice is already in batch or not
                        const invoiceObject = {text: response.result.INV_NO};
                        const index = $rootScope.batchInvoiceArray.filter(function (o) {
                            return o.text == response.result.INV_NO;
                        })[0];

                        if (typeof index == 'undefined') {
                            $rootScope.batchInvoiceArray.push(invoiceObject);
                            $rootScope.$broadcast('batchCountBroadcastEvent', $rootScope.batchInvoiceArray); // going down!
                        }

                        //GENERATE & NEXT INVOICE
                        if (is_pulled_data) {
                            if(!make_fresh_job_form && is_pulled_data && !is_print && !redirect_to_payment){
                                $state.go('invoice.create',{job_id:$scope.invoice.JOB_NO});
                            }
                            // Make invoice id to empty if user click on Generate & next invoice button because if we are not make it blank it again comes to update the existing invoice
                            $scope.invoice.invoiceID = '';
                            $scope.INV_NO = '';
                            $scope.invoice.INV_NO = '';
                            $scope.invoice.INV_NOTE = '';
                            $scope.invoice.is_pulled_data = false;
                            $scope.change_pulled_data();
                            $scope.makeBlankToBillingFirm();

                            // Need to make the invoice existing data to blank, because its make selected to witness after click on generate and next button
                            $scope.invoice.witness_data = {};

                            $scope.refreshWitnessSelection();

                            $scope.rateInformationAssignment();
                        }
                        //GENERATE & NEW JOB
                        if (make_fresh_job_form) {
                            // Make invoice id to empty if user click on Generate & new job button because if we are not make it blank it again comes to update the existing invoice
                            $scope.invoice.invoiceID = '';
                            $scope.INV_NO = '';
                            $scope.invoice.INV_NO = '';
                            $scope.invoice.INV_NOTE = '';
                            $scope.initializeInvoice();
                                
                        }
                        if(redirect_to_payment){
                            $state.go('payroll.add_payment');
                        }
                        // Generate & Print Invoice
                        if (is_print) {
                            var invoiceModal = $uibModal.open({
                                templateUrl: "modules/invoice/view_popup.html",
                                controller: 'viewPrintInvoiceController',
                                resolve: {
                                    invoice_id: function () {
                                        return $rootScope.invoiceBatchArray;
                                    }
                                },
                                windowClass: 'full-width-model',
                                keyboard: false,
                                backdrop: false,
                                size: 'lg',
                                close: function () {

                                }
                            });

                            invoiceModal.result.then(function (is_invoice_printed) {

                                if (is_invoice_printed) {

                                    // We just remove data only when the user say Yes I printed the invoice which is in the batch.
                                    $scope.initializeInvoice();
                                    // need to update the flag for print
                                    $scope.updateInvoiceFlagForPrinted($rootScope.invoiceBatchArray);
                                }
                            });
                        }
                    }
                    else {
                        Notification.error(response.result.message || 'Error while updating the invoice');
                    }

                })
                .error(function () {
                    $rootScope.showLoader(false);
                    Notification.error("Something went wrong!");

                    // just disabled the button to preventing the user to multiple clicks
                    $scope.isSubmitting = false;
                });
            }
            else {
                commonFactory.post('/api/invoice', $scope.invoice).success(function (response) {

                    // just disabled the button to preventing the user to multiple clicks
                    $scope.isSubmitting = false;

                    $rootScope.showLoader(false);
                    anchorSmoothScroll.scrollTo('invoiceManuallyDiv');
                    if (response.status && response.status == 200) {

                        // check whether invoice is already in batch or not
                        const invoiceObject = {text: response.result.INV_NO};
                        const index = $rootScope.batchInvoiceArray.filter(function (o) {
                            return o.text == response.result.INV_NO;
                        })[0];

                        if (typeof index == 'undefined') {
                            $rootScope.batchInvoiceArray.push(invoiceObject);
                            $rootScope.$broadcast('batchCountBroadcastEvent', $rootScope.batchInvoiceArray); // going down!
                        }

                        //GENERATE & NEW JOB
                        if(!make_fresh_job_form && !redirect_to_payment){
                            // Fetch the Job Invoices
                            $scope.showJobInvoiceHistory($scope.invoice.JOB_NO);
                        }

                        // If user press only save button then we need to disable attorney firm box
                        if(!make_fresh_job_form && !is_pulled_data && !is_print && !redirect_to_payment){
                            $scope.userWantToEdit = true;
                            Notification.success({ message : response.result.message, delay : 30000,  title: 'Invoice created.'});
                            $state.go('invoice.edit', { invoice_id : response.result.invoice_id});
                            return;
                        }else{
                            $scope.userWantToEdit = false;
                        }

                        $scope.invoice.INV_NO = response.result.INV_NO;
                        $scope.invoice.invoiceID = response.result.invoice_id;
                        Notification.success({ message : response.result.message, delay : 30000,  title: 'Invoice created.'});


                        if (is_pulled_data) {
                            // Make invoice id to empty if user click on Generate & next invoice button because if we are not make it blank it again comes to update the existing invoice
                            $scope.invoice.invoiceID = '';
                            $scope.INV_NO = '';
                            $scope.invoice.INV_NO = '';
                            $scope.invoice.INV_NOTE = '';
                            $scope.invoice.is_pulled_data = false;
                            $scope.change_pulled_data();
                            $scope.makeBlankToBillingFirm();

                            // Need to make the invoice existing data to blank, because its make selected to witness after click on generate and next button
                            $scope.invoice.witness_data = {};

                            // $scope.witnesslist($scope.invoice.JOB_NO);
                            $scope.refreshWitnessSelection();

                            $scope.rateInformationAssignment();
                        }
                        if (make_fresh_job_form) {
                            // Make invoice id to empty if user click on Generate & new job button because if we are not make it blank it again comes to update the existing invoice
                            $scope.invoice.invoiceID = '';
                            $scope.INV_NO = '';
                            $scope.invoice.INV_NO = '';
                            $scope.invoice.INV_NOTE = '';
                            $scope.initializeInvoice();
                        }
                        if(redirect_to_payment){
                            $state.go('payroll.add_payment');
                        }
                        if (is_print) {
                            var invoiceModal = $uibModal.open({
                                templateUrl: "modules/invoice/view_popup.html",
                                controller: 'viewPrintInvoiceController',
                                resolve: {
                                    invoice_id: function () {
                                        return $rootScope.invoiceBatchArray;
                                    }
                                },
                                windowClass: 'full-width-model',
                                keyboard: false,
                                backdrop: false,
                                size: 'lg',
                                close: function () {

                                }
                            });

                            invoiceModal.result.then(function (is_invoice_printed) {
                                $scope.initializeInvoice();
                                if (is_invoice_printed) {
                                    // need to update the flag for print
                                    $scope.updateInvoiceFlagForPrinted($rootScope.invoiceBatchArray);
                                }
                            });
                        }
                    }
                    else {
                        Notification.success(response.result.message);
                    }

                }).error(function () {
                    // just disabled the button to preventing the user to multiple clicks
                    $scope.isSubmitting = false;
                });
            }
        };

        $scope.editListInvoice = function(invoice_id){
            swal({
                    title: "Are you sure ?",
                    text: "Have you saved data from the current invoice?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, I have saved",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    // Clear the Batch
                    if (isConfirm) {
                        $state.go('invoice.edit',{invoice_id:invoice_id});
                    }
                });
        };

        $scope.payInvoice = function(inv_no){
            if(inv_no){
                $http.get(apiUrl + '/api/pay-invoice-url', {
                    params: {
                        inv_no: inv_no
                    }
                }).then(function(response) {
                    if(!response.data.error){
                        window.open(response.data.result,'_blank');
                    }else{
                        Notification.error(response.data.message);
                    }
                });
            }
        }

        $scope.markAsCODInvoice = function (invoice_id, callback, clonedInvoiceId = '', INV_NO) {
            $rootScope.showLoader(true);
            commonFactory.post('/api/invoice/is-collector-exists', {'INV_NO' : INV_NO}).success(function (response) {
                if(!response.result.exists){
                    swal({
                        title: "Error!",
                        type: "error",
                        text: 'Collector does not exists, Please add a collector!',
                        html: true,
                    }); 
                }else{
                    $scope.markCODInvoice(invoice_id, callback, clonedInvoiceId = '', INV_NO);
                }
                $rootScope.showLoader(false);
            }).error(function (error) {
                $rootScope.showLoader(false);
            });
        }

        $scope.markCODInvoice = function (invoice_id, callback, clonedInvoiceId = '', INV_NO) {
            $rootScope.showLoader(true);
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/cod_invoice_popup.html",
                controller: ['$scope','$rootScope','invoice_id', 'users', '$uibModalInstance','commonFactory','Notification', 'clonedInvoiceId', function ($scope, $rootScope ,invoice_id, users, $uibModalInstance,commonFactory,Notification) {
                    $rootScope.showLoader(false);
                    $scope.users = [{'id': null, name: "Please select the user"}]
                    $scope.users = $scope.users.concat(users.data);
                    $scope.cod_invoice = {approved_by: $rootScope.auth_user.id, activity_type: 'cod', invoice_id: invoice_id, clonedInvoiceId:clonedInvoiceId};
                    $scope.uibModalInstance = $uibModalInstance;

                    $scope.codFormSubmit = function ($valid) {
                        if ($valid) {
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/invoice/mark-invoice-as-cod', $scope.cod_invoice).success(function (response) {
                                $rootScope.showLoader(false);
                                Notification.success(response.message);
                                $uibModalInstance.close();
                                $rootScope.$broadcast('reloadInvoiceBrowseBroadcastEvent','');

                                //Remove invoice from print batch
                                $rootScope.batchInvoiceArray = $rootScope.batchInvoiceArray.filter(function (item) {
                                    return item.text != INV_NO;
                                });

                                $rootScope.$broadcast('batchCountBroadcastEvent', $rootScope.batchInvoiceArray);
                                
                            }).error(function (error) {
                                $rootScope.showLoader(false);
                                Notification.error(error.message);
                            });
                        }
                    }

                }],
                resolve: {
                    invoice_id : function () {
                        return invoice_id;
                    },
                    users : ['UsersFactory', function (UsersFactory) {
                        return UsersFactory.get('/api/users').success(function (response) {
                            return response.data;
                        });
                    }],
                    clonedInvoiceId : function(){
                        return clonedInvoiceId;
                    }
                },
                keyboard: true,
                backdrop: false,
                size: 'md',
                windowClass: 'middle-width-model',
            });

            invoiceModal.result.then(function () {
                if(typeof callback =='function')
                callback();
            }, function () {
                if(typeof callback =='function')
                callback();
            });
        };

        $scope.markAsRecoginzeInvoice = function (invoice_id, clonedInvoiceId = '') {
            $rootScope.showLoader(true);

            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/cod_invoice_popup.html",
                controller: ['$scope','$rootScope','invoice_id', 'users', '$uibModalInstance','invoiceFactory','Notification', function ($scope, $rootScope ,invoice_id, users, $uibModalInstance,invoiceFactory,Notification) {
                    $rootScope.showLoader(false);
                    $scope.users = [{'id': null, name: "Please select the user"}]
                    $scope.users = $scope.users.concat(users.data);
                    $scope.cod_invoice = {approved_by: $rootScope.auth_user.id, activity_type: 'recognize', invoice_id: invoice_id, clonedInvoiceId:clonedInvoiceId};
                    $scope.uibModalInstance = $uibModalInstance;

                    $scope.codFormSubmit = function ($valid) {
                        if ($valid) {
                            $rootScope.showLoader(true);
                            invoiceFactory.post('/api/invoice/mark-invoice-as-cod-undo', $scope.cod_invoice).success(function (response) {
                                $rootScope.showLoader(false);
                                Notification.success(response.message);
                                $uibModalInstance.close();
                                $rootScope.$broadcast('reloadInvoiceBrowseBroadcastEvent','');
                            }).error(function (error) {
                                $rootScope.showLoader(false);
                                Notification.error(error.message);
                            });
                        }
                    }

                }],
                resolve: {
                    invoice_id: function () {
                        return invoice_id;
                    },
                    users: ['UsersFactory', function (UsersFactory) {
                        return UsersFactory.get('/api/users').success(function (response) {
                            return response.data;
                        });
                    }],
                    clonedInvoiceId : function(){
                        return clonedInvoiceId;
                    }
                },
                keyboard: true,
                backdrop: true,
                size: 'md',
                windowClass: 'middle-width-model',
                close: function () {

                }
            });

            invoiceModal.result.then(function () {
                $scope.reloadData();
            });             
        }

        $scope.markAsVoidInvoice = function (invoice_id, callback, clonedInvoiceId = '', INV_NO) {

            $rootScope.showLoader(true);
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/void_invoice_popup.html",
                controller: ['$scope','$rootScope','invoice_id', 'users', '$uibModalInstance','commonFactory','Notification','clonedInvoiceId', function ($scope, $rootScope ,invoice_id, users, $uibModalInstance,commonFactory,Notification, clonedInvoiceId) {
                    $rootScope.showLoader(false);
                    $scope.users = [{'id': null, name: "Please select the user"}]
                    $scope.users = $scope.users.concat(users.data);
                    $scope.void_invoice = {approved_by: $rootScope.auth_user.id, activity_type: 'void', invoice_id: invoice_id, clonedInvoiceId: clonedInvoiceId};
                    $scope.uibModalInstance = $uibModalInstance;

                    $scope.voidFormSubmit = function ($valid) {
                        if ($valid) {
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/invoice/mark-invoice-as-void', $scope.void_invoice).success(function (response) {
                                $rootScope.showLoader(false);
                                Notification.success(response.message);
                                $uibModalInstance.close();
                                $rootScope.$broadcast('reloadInvoiceBrowseBroadcastEvent','');

                                //Remove invoice from print batch
                                $rootScope.batchInvoiceArray = $rootScope.batchInvoiceArray.filter(function (item) {
                                    return item.text != INV_NO;
                                });

                                $rootScope.$broadcast('batchCountBroadcastEvent', $rootScope.batchInvoiceArray);
                                
                            }).error(function (error) {
                                $rootScope.showLoader(false);
                                Notification.error(error.message);
                            });
                        }
                    }

                }],
                resolve: {
                    invoice_id: function () {
                        return invoice_id;
                    },
                    users: ['UsersFactory', function (UsersFactory) {
                        return UsersFactory.get('/api/users').success(function (response) {
                            return response.data;
                        });
                    }],
                    clonedInvoiceId : function(){
                        return clonedInvoiceId;
                    }
                },
                keyboard: true,
                backdrop: true,
                size: 'md',
                windowClass: 'middle-width-model',
            });

            invoiceModal.result.then(function () {
                if(typeof callback =='function'){
                    callback();
                }else{
                    $scope.showJobInvoiceHistory($scope.invoice.JOB_NO);
                }
            }, function () {
                if(typeof callback =='function'){                    
                    callback();
                }else{
                    $scope.showJobInvoiceHistory($scope.invoice.JOB_NO);
                }
            });

        };

        $scope.markAsValidInvoice = function(invoice_id){

            $rootScope.showLoader(true);

            var invoiceModal = $uibModal.open({
                templateUrl: "modules/invoice/void_invoice_popup.html",
                controller: ['$scope','$rootScope','invoice_id', 'users', '$uibModalInstance','commonFactory','Notification', function ($scope, $rootScope ,invoice_id, users, $uibModalInstance,commonFactory,Notification) {
                    $rootScope.showLoader(false);
                    $scope.users = [{'id': null, name: "Please select the user"}];
                    $scope.users = $scope.users.concat(users.data);
                    $scope.void_invoice = {approved_by: $rootScope.auth_user.id, activity_type: 'valid', invoice_id: invoice_id};
                    $scope.uibModalInstance = $uibModalInstance;

                    $scope.voidFormSubmit = function ($valid) {
                        if ($valid) {
                            $rootScope.showLoader(true);
                            commonFactory.post('/api/invoice/mark-invoice-as-valid', $scope.void_invoice).success(function (response) {
                                $rootScope.showLoader(false);
                                Notification.success(response.message);
                                $uibModalInstance.close();
                                $rootScope.$broadcast('reloadInvoiceBrowseBroadcastEvent','');
                            }).error(function (error) {
                                $rootScope.showLoader(false);
                                Notification.error(error.message);
                            });
                        }
                    }

                }],
                resolve: {
                    invoice_id: function () {
                        return invoice_id;
                    },
                    users: ['UsersFactory', function (UsersFactory) {
                        return UsersFactory.get('/api/users').success(function (response) {
                            return response.data;
                        });
                    }]
                },
                keyboard: true,
                backdrop: true,
                size: 'md',
                windowClass: 'middle-width-model',
                close: function () {

                }
            });

            invoiceModal.result.then(function () {
               
                $scope.showJobInvoiceHistory($scope.invoice.JOB_NO);
             
            });
        }


        /**
         * function updateAttorneyFirmNotes
         * @description = for updating the notes of attorney firm
         * @param notes_key
         */
        $scope.updateAttorneyFirmNotes = function($data,notes_key){
            if($scope.invoice.attorneyFirms.id){
                var data = {attorney_firm_id : $scope.invoice.attorneyFirms.id, notes_type : notes_key, data : $data};
                return commonFactory.post('/api/attorneys/save-notes',data);
            }
            else{
                return "Opps! you not select any billing firm yet";
            }
        };

        $scope.$watch('invoice.attorneyFirms',function(newValue,oldValue){
            // set flag notes_editable
            if(newValue && newValue.id > 0){
                $scope.notesEditable = true;
            }
            else{
                $scope.notesEditable = false;
            }
        });

        $(window).bind('keydown', function(event) {
            if($rootScope.havePermission('invoice','update')){
                if (event.ctrlKey || event.metaKey) {
                    switch (String.fromCharCode(event.which).toLowerCase()) {
                        case 's':
                            event.preventDefault();

                            // $scope.invoiceGenerateForm.$setDirty();

                            $scope.invoiceGenerateForm.$setSubmitted();
                            $scope.$apply();
                            $scope.saveJobInvoice($scope.invoiceGenerateForm.$valid,false,false,false);
                            break;
                        case 'i':
                            event.preventDefault();
                            $scope.invoiceGenerateForm.$setSubmitted();
                            $scope.$apply();
                            $scope.saveJobInvoice($scope.invoiceGenerateForm.$valid,false,true,false);
                            break;
                        case 'j':
                            event.preventDefault();
                            $scope.invoiceGenerateForm.$setSubmitted();
                            $scope.$apply();
                            $scope.saveJobInvoice($scope.invoiceGenerateForm.$valid,false,true,true);
                            break;
                    }
                }
            }    
        });

        $scope.checkInvoiceNumberExist = function(data,invoiceForm){

            invoiceForm.$setPristine();
            invoiceForm.$setUntouched();
            // $scope.$apply();

            var d = $q.defer();
            if(data){
                $http.get(apiUrl + '/api/invoice/get-by-invoice-number/'+data).then(function (response) {
                    if (response.data.status == '200') {
                        $scope.manuallyChangeInvoiceNumber = false;
                        d.reject("Sorry, this invoice number already generated");
                    }
                    else{
                        $scope.manuallyChangeInvoiceNumber = true;
                        $scope.INV_NO = data;
                        d.resolve();
                    }
                }).then(function(){
                    d.reject('Server error!');
                });
            }
            else{
                $scope.manuallyChangeInvoiceNumber = false;
                d.reject('Please enter the invoice number');
            }
            return d.promise;
        };

        /**
         *@function addAttorneyFirm
         */
        $scope.addAttorneyFirm = function(elawId){

            $rootScope.showLoader(true);
            var findAttorneyFirmModal = $uibModal.open({
                templateUrl: "modules/invoice/find_firm.html",
                data: {pageTitle: 'Add Firm'},
                controller: 'findFirmsController',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {
                    console.log("function called");
                },
                resolve : {
                    $parent : function(){
                        return $scope;
                    },
                    elawId : function(){
                        return elawId || null;
                    }
                }
            });
            findAttorneyFirmModal.opened.then(function () {
                $rootScope.showLoader(false);
            });

        };

        /**
         *@function editAttorneyFirm
         */
        $scope.editAttorneyFirm = function(attorneyFirmId){

            $rootScope.showLoader(true);
            var editAttorneyModal = $uibModal.open({
                templateUrl: "modules/firms/add_attorney_firm_modal.html",
                data: {pageTitle: 'Edit Firm'},
                controller: 'firmsController',
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {
                    console.log("function called");
                },
                resolve : {
                    attorneyFirmId : function(){
                        return attorneyFirmId;
                    }
                }
            });


            editAttorneyModal.result.then(function (firmData) {

                if (!angular.equals({}, firmData)) {
                    $scope.refreshFirm(firmData.id);
                }
            }, function () {
                console.log("Modal Dismissed!!!");
            });

        };

        /**
         *@function addAttorney
         */
        $scope.addAttorney = function(firmId){

            $rootScope.showLoader(true);
            var addAttorneyModal = $uibModal.open({
                templateUrl: "modules/addattorneytofirm/add_attorney_modal.html",
                data: {pageTitle: 'Add Attorney'},
                controller: 'addattorneytofirmController',
                keyboard: false,
                backdrop: false,
                size: 'md',
                close: function () {
                    console.log("function called");
                },
                resolve : {
                    attorneyFirmId : function(){
                        return firmId || null;
                    },
                    email : function(){
                        return null;
                    }
                }
            });


            addAttorneyModal.result.then(function (attorneyData) {

                if (!angular.equals({}, attorneyData)) {
                    var attorney_id =angular.isObject(attorneyData.attorney_id)? attorneyData.attorney_id.id : attorneyData.attorney_id;
                    $scope.getAttorneys(attorney_id, function (attorneysData) {

                        $scope.attorney = [{id: null, NAME: "NOT PRESENT"}];
                        $scope.attorney = $scope.attorney.concat(attorneysData || {});
                        //$scope.mainAttorney = angular.copy(attorneyData || {});
                        $scope.invoice.attname_id = attorneyData.id || null;
                    });
                }
            }, function () {
                console.log("Modal Dismissed!!!");
            });

        };

        /**
         *@function editAttorney
         */
        $scope.editAttorney = function(attorneyId){

            $rootScope.showLoader(true);
            var editAttorneyModal = $uibModal.open({
                templateUrl: "modules/addattorneytofirm/add_attorney_modal.html",
                data: {pageTitle: 'edit Attorney'},
                controller: 'editattorneytofirmController',
                keyboard: false,
                backdrop: false,
                size: 'md',
                close: function () {
                    console.log("function called");
                },
                resolve : {
                    attorneyId : function(){
                        return attorneyId || null;
                    }
                }
            });


            editAttorneyModal.result.then(function (attorneyData) {

                if (!angular.equals({}, attorneyData)) {
                    var attorney_id =angular.isObject(attorneyData.attorney_id)? attorneyData.attorney_id.id : attorneyData.attorney_id;
                    $scope.getAttorneys_invoice(attorney_id, function (attorneysData) {

                        $scope.attorney = [{id: null, NAME: "NOT PRESENT"}];
                        $scope.attorney = $scope.attorney.concat(attorneysData || {});
                        //$scope.mainAttorney = angular.copy(attorneyData || {});
                        $scope.invoice.attname_id = attorneyId || null;
                    });
                }
            }, function () {
                console.log("Modal Dismissed!!!");
            });

        };

        $scope.checkRecordIsOfCurrentMonth = function(dte_invoice){
            var check = moment(dte_invoice, 'YYYY-MM-DD');

            var month = check.format('M');
            var year  = check.format('YYYY');
            return $scope.current_date.format('M')== month && $scope.current_date.format('YYYY')== year;
        };

        $scope.cloneInvoice = function (invoice_id, INV_NO) {


            swal({
                    title: "Are you sure ?",
                    text: "Are you sure want to clone that invoice?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        // Delete the invoice
                        commonFactory.post('/api/invoice/clone-invoice',{invoice_id : invoice_id}).success(function (response) {
                            $rootScope.showLoader(false);
                            Notification.success(response.message);

                            var clonedInvoiceId = response.cloned_invoice.invoiceID;


                            swal({
                                    title: "Are you sure ?",
                                    text: "Do you want to void the original invoice?",
                                    type: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#09375f",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "No",
                                    closeOnConfirm: true,
                                    closeOnCancel: true
                                },
                                function (isConfirm) {
                                    if (isConfirm) {

                                        $scope.markAsVoidInvoice(invoice_id,function(){

                                            swal({
                                                    title: "Are you sure ?",
                                                    text: "Do you want to edit the New Invoice?",
                                                    type: "warning",
                                                    showCancelButton: true,
                                                    confirmButtonColor: "#09375f",
                                                    confirmButtonText: "Yes",
                                                    cancelButtonText: "No",
                                                    closeOnConfirm: true,
                                                    closeOnCancel: true
                                                },
                                                function (isConfirm) {
                                                    if(isConfirm) {
                                                        $state.go('invoice.edit', {invoice_id: clonedInvoiceId});
                                                    }else{
                                                        $scope.showJobInvoiceHistory($scope.invoice.JOB_NO);
                                                    }
                                                });


                                        }, clonedInvoiceId, INV_NO);


                                    }else{
                                        $scope.showJobInvoiceHistory($scope.invoice.JOB_NO);
                                    }

                                });

                        }).error(function (error) {
                            $rootScope.showLoader(false);
                            Notification.error(error.message);
                        });
                    }
                });



        };

        $scope.changedSource = function(){

            swal("", "This source will be also updated to the calendar if you save the invoice");

        };

        $scope.assigningAutoPartner = function(item){
            angular.forEach($scope.auto_partners,function(value, key){
                if(value.code == item.BEEPER){                
                    $scope.firm_beeper = value.text;
                }
            });
        }

        //Unlock salesperson field.
        $scope.unlockSalesPersonAndSource = function(){
            
            if($rootScope.havePermission('allow_access_without_password','allow.diary.account.executive.lock.popup')){
                return;
            }else if($scope.salesperson_source_locked){
                swal({
                    title: "PLEASE ENTER PASSWORD TO UNLOCK",
                    type: "input",
                    inputType: "password",
                    showCancelButton: true,
                    closeOnConfirm: true
                }, function(inputValue) { 
                    if (inputValue === false){
                        return false;
                    } 
                    if (inputValue === "") {
                        Notification.error("You need to write something!");
                        return false
                    }
                    else {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/auth-salesperson-source-pass', { 'password': inputValue })
                            .success(function(response) {
                                if (response['result']) {
                                    if (response['result']['auth']) {

                                        $rootScope.showLoader(false);
                                        $scope.salesperson_source_locked = false;
                                        
                                    }else {
                                        $rootScope.showLoader(false);
                                        Notification.error("Error ! Wrong Password");
                                    }
                                }else{
                                    $rootScope.showLoader(false);
                                    Notification.error("Oops ! Something went wrong");
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                Notification.error("Oops ! Something went wrong");
                            });

                    }
                });        
            }else{
                $scope.salesperson_source_locked = true;
            }
        }

        $scope.unlockInvoiceFirm = function(){
            
            if($scope.invoice_firm_locked){
                swal({
                    title: "PLEASE ENTER PASSWORD TO UNLOCK",
                    type: "input",
                    inputType: "password",
                    showCancelButton: true,
                    closeOnConfirm: true
                }, function(inputValue) { 
                    if (inputValue === false){
                        return false;
                    } 
                    if (inputValue === "") {
                        Notification.error("You need to write something!");
                        return false
                    }
                    else {
                        $rootScope.showLoader(true);
                        commonFactory.post('/api/authPrivateInfoPass', { 'password': inputValue ,'password_key':'invoice_firm_password' })
                            .success(function(response) {
                                if (response['result']) {
                                    if (response['result']['auth']) {

                                        $rootScope.showLoader(false);
                                        $scope.invoice_firm_locked = false;
                                        
                                    }else {
                                        $rootScope.showLoader(false);
                                        Notification.error("Error ! Wrong Password");
                                    }
                                }else{
                                    $rootScope.showLoader(false);
                                    Notification.error("Oops ! Something went wrong");
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                                Notification.error("Oops ! Something went wrong");
                            });

                    }
                });        
            }else{
                $scope.invoice_firm_locked = true;
            }
        }


        $scope.missingDataEmailPop= function (invoice) {

            invoice = $scope.invoice;
            console.log(invoice);
            invoice.isEmail = true
            $uibModal.open({
                templateUrl: "modules/invoice/email_popup_geico.html",
                controller: ['$scope','invoice', '$uibModalInstance', function ($scope,invoice, $uibModalInstance) {
                    $scope.invoice= invoice;
                    $scope.toEmail
                    $scope.is_bad_claim_number;
                    $scope.emailSubject
                    if($scope.invoice && $scope.invoice.isEmail){
                        if(!$scope.invoice.ClaimNumber && !$scope.invoice.FILE_NO){
                            $scope.emailSubject = "Missing Information Claim Number And File Number Empty For "+$scope.invoice.INV_NO+" Invoice"
                        }else{
                            if(!$scope.invoice.ClaimNumber || !$scope.invoice.FILE_NO){
                                if(!$scope.invoice.ClaimNumber){
                                    $scope.emailSubject = "Missing Information Claim Number Empty For "+$scope.invoice.INV_NO+" Invoice"
                                }  
                                if(!$scope.invoice.FILE_NO){
                                    $scope.emailSubject = "Missing Information File Number Empty For "+$scope.invoice.INV_NO+" Invoice"
                                }   
                            }
                            else{
                                $scope.emailSubject = "Missing Information For "+$scope.invoice.INV_NO+" Invoice"
                            }
                        }
        
                        if($scope.invoice.invoice_submission_email_log){
                            $scope.toEmail = $scope.invoice.invoice_submission_email_log.to_email
                        }                   
                    }
                    $scope.closePopupWindowSimple = function(){
                        $uibModalInstance.close(true);
                    };
                    $scope.reloadSubject = function(){
                        if(!$scope.is_bad_claim_number){
                            $scope.is_bad_claim_number = true
                        }else{
                            $scope.is_bad_claim_number=false
                        }
                        if($scope.is_bad_claim_number){
                            if(!$scope.invoice.FILE_NO){
                                $scope.emailSubject = "Missing Information File Number Empty And Invalid Claim Number For "+$scope.invoice.INV_NO+" Invoice"
                            }   
                            else{
                                $scope.emailSubject = "Missing Information Invalid Claim Number For "+$scope.invoice.INV_NO+" Invoice"
                            }
        
                        }else{
                            if(!$scope.invoice.FILE_NO){
                                $scope.emailSubject = "Missing Information File Number Empty For "+$scope.invoice.INV_NO+" Invoice"
                            }   
                            else{
                                $scope.emailSubject = "Missing Information For "+$scope.invoice.INV_NO+" Invoice"
                            }
                        }
                    };

                    $scope.ValidateEmail=function (mail) 
                    {
                        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
                        {
                            return (true)
                        }
                            return (false)
                    }
        
                    $scope.submit_invoice_send_mail = function(){
                    if( $scope.ValidateEmail($scope.toEmail)){
                        $rootScope.showLoader(true);
                        //$close(JOB_NO);
                        var wintness = ''
                       
                        if($scope.invoice.witness_data){
                            var i =0 
                            $scope.invoice.witness_data.forEach(element => {

                                console.log(i); //return false;
                                wintness =  wintness + element.witness.NAME
                                if(i!=0){
                                    wintness =  wintness + ' ,'
                                }
                                i++
                            });
                        }
                       // console.log(wintness);return false;
                        var req_data = {
                        'toEmail':$scope.toEmail,
                        'INV_NO':$scope.invoice.INV_NO,
                        'is_bad_claim_number':$scope.is_bad_claim_number,
                        'subject':$scope.emailSubject,
                        'ClaimNumber':$scope.invoice.ClaimNumber,
                        'FILE_NO':$scope.invoice.FILE_NO,
                        'dte_invoice':$scope.invoice.dte_invoice,
                        'INV_AMT':$scope.invoice.INV_AMT,
                        'FirmName':$scope.invoice.attorney_firms.NAME,
                        'IndexNumber':$scope.invoice.IndexNumber,
                        'CaseName':$scope.invoice.job.CAPTION,
                        'Witness' :wintness,
                        'DateOfDepo':$scope.invoice.job.DATE_TAKEN
                    }
                        commonFactory.post('/api/send-email-geico-invoce-missing-data',req_data).success(function (res) {
                            $rootScope.showLoader(false);
                            if (res.error)
                            {
                                Notification.error(res.message || 'Something went wrong!');
                            }
                            else{ 
                                $scope.invoice.invoice_submission_email_log=res.result.data;
                                $scope.closePopupWindowSimple();
                                Notification.success(res.result.message);
                            }
                        }).error(function (err) {
                            Notification.error(err.message);
                         $rootScope.showLoader(false);
                        }
                            
                        );
                    }
                    };
        
                }],
                resolve: {
                    invoice: function () {
                        return invoice;
                    }
                },
                windowClass: 'full-width-model',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

        }

        $scope.getResourceWiseJobPayments = function(resources){
            
            $scope.invoice.total_resource_wise_amt={};
            $scope.invoice.total_rep_pay_amt=0;
            $scope.invoice.typ_pay_amt=0;
            angular.forEach( $scope.additionalResourceTypes, function (value, key) {
               
               $scope.invoice.total_resource_wise_amt[value.id] ={
                  name:value.name,
                  payment:0
               };
           });
           angular.forEach(resources, function (value, key) {
            if(value.resource_type=='R'){
               if($scope.invoice.total_resource_wise_amt && $scope.invoice.total_resource_wise_amt[value.resource_type_id] )
               {
                $scope.invoice.total_resource_wise_amt[value.resource_type_id].payment+= value.REP_PAYAMT;
               }

               $scope.invoice.total_rep_pay_amt+= value.REP_PAYAMT;
               $scope.invoice.typ_pay_amt+=value.TYP_PAYAMT;
            }
            else{
                $scope.invoice.typ_pay_amt+=value.TYP_PAYAMT;
            }
           });
           angular.forEach($scope.invoice.total_resource_wise_amt, function (value, key) {
               $scope.invoice.total_resource_wise_amt[key].payment = round_to_number(value.payment);
           });
           $scope.invoice.typ_pay_amt= round_to_number($scope.invoice.typ_pay_amt);
           $scope.invoice.total_rep_pay_amt= round_to_number($scope.invoice.total_rep_pay_amt);
       }

       $scope.onChangeWitnessDepositionInsights = function ($index,is_deposition_insights ) {
       
            var $item = $scope.invoice.witnessData[$index];
            if ($item.is_add) {
                if( $scope.invoice.attorneyFirms.rate_id != 0  && ( $scope.rateCardRate && $scope.rateCardRate.deposition_insights == 1)){
                    if(is_deposition_insights){
                        $scope.addDepositionInsightsAditionalCharges($item)
                    }else{
                        $scope.removeDepositionInsightsAditionalCharges($item)
                    }
                }
                if($scope.invoice.attorneyFirms.rate_id == 0 ){
                    if(is_deposition_insights && $scope.invoice.attorneyFirms.di_job_summary == '1'){
                        $scope.addDepositionInsightsAditionalCharges($item)
                    }else{
                        $scope.removeDepositionInsightsAditionalCharges($item)
                    }      
                }
                $scope.invoice.witnessData[$index].is_deposition_insights = is_deposition_insights
            }
        }
        
        $scope.addDepositionInsightsAditionalCharges = function(witness){
            
            var amount = 0
            if(witness.PAGES > 24){
                var CHG_CODE
                if (witness.PAGES > 24 && witness.PAGES <= 74) {
                    CHG_CODE = 'DI1'
                    if($scope.invoice.attorneyFirms.rate_id == 0){
                        amount = $scope.invoice.attorneyFirms.deposition_insights_charge1
                    }else{
                        amount = $scope.rateCardRate.In_deposition_insights_charge1
                    }
                }

                if (witness.PAGES >= 75 && witness.PAGES <= 150) {
                    CHG_CODE = 'DI2'
                    if($scope.invoice.attorneyFirms.rate_id == 0){
                        amount = $scope.invoice.attorneyFirms.deposition_insights_charge2
                    }else{
                        amount = $scope.rateCardRate.In_deposition_insights_charge2
                    }
                }
                if (witness.PAGES > 150) {
                    CHG_CODE = 'DI3'
                    if($scope.invoice.attorneyFirms.rate_id == 0){
                        amount = $scope.invoice.attorneyFirms.deposition_insights_charge3
                    }else{
                        amount = $scope.rateCardRate.In_deposition_insights_charge3
                    }
                }
            // get the Object from Additional Charge well
                var MASTER_CHG_CODE_object = $filter('filter')($scope.additional_charges, { CHG_CODE: CHG_CODE }, true);
                if (typeof MASTER_CHG_CODE_object === 'undefined') {
                    return;
                }

                if ($scope.invoice.additional_charges.length > 0) {
                    if ($scope.invoice.additional_charges && $scope.invoice.additional_charges[0].CHG_CODE) {
                        CHG_CODE_index = $scope.invoice.additional_charges.length;
                    } else {
                        CHG_CODE_index = 0;
                    }
                } else {
                    CHG_CODE_index = 0;
                }

                var CHECK_CHG_CODE_DUPLICATE = $filter('filter')($scope.invoice.additional_charges, { CHG_CODE: CHG_CODE,witness_id:witness.witnessID }, true);
               
                if(!CHECK_CHG_CODE_DUPLICATE.length){
                    $scope.invoice.additional_charges[CHG_CODE_index] = angular.copy(MASTER_CHG_CODE_object[0]);
                    $scope.invoice.additional_charges[CHG_CODE_index].DESC = witness.NAME+" Deposition Insights"
                    $scope.invoice.additional_charges[CHG_CODE_index].TOTAL_PAGES = Number(witness.PAGES);
                    $scope.invoice.additional_charges[CHG_CODE_index].QTY = Number(1);
                    $scope.invoice.additional_charges[CHG_CODE_index].SPLIT = Number(1);
                    $scope.invoice.additional_charges[CHG_CODE_index].AMT = amount;
                    $scope.invoice.additional_charges[CHG_CODE_index].witness_id = witness.witnessID
                    $scope.calculationAdditionalCharge(CHG_CODE_index);
                }
            }
        }

        $scope.checkDuplicateDepositionInsightsAditionalCharges = function(witness){
            if(witness.PAGES > 24){
                var CHG_CODE
                if(witness.PAGES > 24 && witness.PAGES <= 74)
                 CHG_CODE = 'DI1'

                if(witness.PAGES >= 75 && witness.PAGES <= 150)
                 CHG_CODE = 'DI2'

                 if(witness.PAGES > 150)
                 CHG_CODE = 'DI3'

                var CHECK_CHG_CODE_DUPLICATE = $filter('filter')($scope.invoice.additional_charges, { CHG_CODE: CHG_CODE,witness_id:witness.witnessID }, true);
               
                if(!CHECK_CHG_CODE_DUPLICATE.length){
                   return false
                }else{
                    return true
                }
            }
        }

        $scope.changeConnectionMethodRate = function(){
            if($scope.rateCardRate && $scope.invoice.attorneyFirms.rate_id > 0){
                if($scope.invoice.rate_connection_method == 1){
                    $scope.invoice.RATE = parseFloat($scope.rateCardRate.In_RATE || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.rateCardRate.In_COPY_RATE || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.rateCardRate.In_APP_RATE || 0.00);
                    $scope.invoice.cancellation = parseFloat($scope.rateCardRate.In_cancellation || 0.00);
                    $scope.invoice.medical_technical_expert = parseFloat($scope.rateCardRate.In_medical_technical_expert || 0.00);
                   
                }else if($scope.invoice.rate_connection_method == 2){
                    $scope.invoice.RATE = parseFloat($scope.rateCardRate.In_rate_remote || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.rateCardRate.In_copy_rate_remote || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.rateCardRate.In_appear_rate_remote || 0.00);
                   
                }else if($scope.invoice.rate_connection_method == 3){
                    $scope.invoice.RATE = parseFloat($scope.rateCardRate.In_rate_nonsteno || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.rateCardRate.In_copy_rate_nonsteno || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.rateCardRate.In_appear_rate_nonsteno || 0.00);
                    $scope.invoice.cancellation = parseFloat($scope.rateCardRate.In_cancellation_nonsteno || 0.00);
                    $scope.invoice.medical_technical_expert = parseFloat($scope.rateCardRate.In_medical_technical_expert_nonsteno || 0.00);
                   
                }else if($scope.invoice.rate_connection_method == 4){
                    $scope.invoice.RATE = parseFloat($scope.rateCardRate.In_rate_arbitration || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.rateCardRate.In_copy_rate_arbitration || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.rateCardRate.In_appear_rate_arbitration || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.rateCardRate.In_word_indexing_page_rate_arbitration || 0.00);
                }else if($scope.invoice.rate_connection_method == 5){
                    $scope.invoice.RATE = parseFloat($scope.rateCardRate.In_rate_euo || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.rateCardRate.In_copy_rate_euo || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.rateCardRate.In_appear_rate_euo || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.rateCardRate.In_word_indexing_page_rate_euo || 0.00);
                }else{
                    $scope.invoice.RATE = parseFloat($scope.rateCardRate.In_RATE || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.rateCardRate.In_COPY_RATE || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.rateCardRate.In_APP_RATE || 0.00);
                    $scope.invoice.cancellation = parseFloat($scope.rateCardRate.In_cancellation || 0.00);
                    $scope.invoice.medical_technical_expert = parseFloat($scope.rateCardRate.In_medical_technical_expert || 0.00);
                   
                }
            }
            else if($scope.invoice.attorneyFirms){
                if($scope.invoice.rate_connection_method == 1){
                    $scope.invoice.RATE = parseFloat($scope.invoice.attorneyFirms.RATE || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.invoice.attorneyFirms.COPY_RATE || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.invoice.attorneyFirms.APP_RATE || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.attorneyFirms.WordIndexingPerPage || 0.00);
                    $scope.invoice.cancellation = parseFloat($scope.invoice.attorneyFirms.cancellation || 0.00);
                    $scope.invoice.medical_technical_expert = parseFloat($scope.invoice.attorneyFirms.medical_technical_expert || 0.00);
                }else if($scope.invoice.rate_connection_method == 2){
                    $scope.invoice.RATE = parseFloat($scope.invoice.attorneyFirms.rate_remote || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.invoice.attorneyFirms.copy_rate_remote || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.invoice.attorneyFirms.appear_rate_remote || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.attorneyFirms.word_indexing_page_rate_remote || 0.00);
                }else if($scope.invoice.rate_connection_method == 3){
                    $scope.invoice.RATE = parseFloat($scope.invoice.attorneyFirms.rate_nonsteno || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.invoice.attorneyFirms.copy_rate_nonsteno || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.invoice.attorneyFirms.appear_rate_nonsteno || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.attorneyFirms.word_indexing_page_rate_nonsteno || 0.00);
                    $scope.invoice.cancellation = parseFloat($scope.invoice.attorneyFirms.cancellation_nonsteno || 0.00);
                    $scope.invoice.medical_technical_expert = parseFloat($scope.invoice.attorneyFirms.medical_technical_expert_nonsteno || 0.00);
                }else if($scope.invoice.rate_connection_method == 4){
                    $scope.invoice.RATE = parseFloat($scope.invoice.attorneyFirms.rate_arbitration || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.invoice.attorneyFirms.copy_rate_arbitration || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.invoice.attorneyFirms.appear_rate_arbitration || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.attorneyFirms.word_indexing_page_rate_arbitration || 0.00);
                }else if($scope.invoice.rate_connection_method == 5){
                    $scope.invoice.RATE = parseFloat($scope.invoice.attorneyFirms.rate_euo || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.invoice.attorneyFirms.copy_rate_euo || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.invoice.attorneyFirms.appear_rate_euo || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.attorneyFirms.word_indexing_page_rate_euo || 0.00);
                }else{
                    $scope.invoice.RATE = parseFloat($scope.invoice.attorneyFirms.RATE || 0.00);
                    $scope.invoice.COPY_RATE = parseFloat($scope.invoice.attorneyFirms.COPY_RATE || 0.00);
                    $scope.invoice.APP_RATE = parseFloat($scope.invoice.attorneyFirms.APP_RATE || 0.00);
                    $scope.invoice.WordIndexingPerPage = parseFloat($scope.invoice.attorneyFirms.WordIndexingPerPage || 0.00);
                    $scope.invoice.cancellation = parseFloat($scope.invoice.attorneyFirms.cancellation || 0.00);
                    $scope.invoice.medical_technical_expert = parseFloat($scope.invoice.attorneyFirms.medical_technical_expert || 0.00);
                }
            }
            
            $scope.addAdditionalChargeFromRateInfo('A', $scope.invoice.APP_RATE);
            $scope.addAdditionalChargeFromRateInfo('APP', $scope.invoice.WordIndexingFlatRate); 
            $scope.addAdditionalChargeFromRateInfo('CANCEL-FEE', $scope.invoice.cancellation);

            $scope.addAdditionalChargeFromRateInfo('EXPERT-FEE', $scope.invoice.medical_technical_expert);
            
            $scope.is_pulled_witness = true;
            $scope.calculateAllWitnessCharges();

            $scope.calculateInvoiceAmount();
           
            // $scope.populateAdditionalChargeFromAttorney();
           
        }

        $scope.deleteInvoice = function (invoice_id) {
            swal({
                    title: "Are you sure ?",
                    text: "Are you sure want to delete that invoice?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No, It's clicked by mistake",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        // Delete the invoice
                        commonFactory.delete('/api/invoice/' + invoice_id).success(function (response) {
                            Notification.success(response.result.message);
                            $scope.showJobInvoiceHistory($scope.invoice.JOB_NO);
                        }).error(function (error) {
                            Notification.error(error.result.message);
                        });
                    }
                });
        };
        
        $scope.removeDepositionInsightsAditionalCharges = function(witness){
           
            if(witness.PAGES > 24){
                var CHG_CODE
                if(witness.PAGES > 24 && witness.PAGES <= 74)
                 CHG_CODE = 'DI1'

                if(witness.PAGES >= 75 && witness.PAGES <= 150)
                 CHG_CODE = 'DI2'

                 if(witness.PAGES > 150)
                 CHG_CODE = 'DI3'
            // get the Object from Additional Charge well
                if(CHG_CODE){
                var MASTER_CHG_CODE_object = $filter('filter')($scope.invoice.additional_charges, { CHG_CODE: CHG_CODE,witness_id:witness.witnessID }, true);
                if (typeof MASTER_CHG_CODE_object === 'undefined') {
                    return;
                }
               
                Array.prototype.findIndexBy = function(key, value) {
                    return this.findIndex(item => item[key] === value)
                }
                if(MASTER_CHG_CODE_object && MASTER_CHG_CODE_object[0] && MASTER_CHG_CODE_object[0].ChargeID){
                    var removeIndex = $scope.invoice.additional_charges.findIndexBy('ChargeID', MASTER_CHG_CODE_object[0].ChargeID)
                    
                    if((removeIndex || removeIndex == 0) && removeIndex != -1){
                        $scope.invoice.additional_charges.splice(removeIndex, 1);
                        $scope.calculationAdditionalCharge(removeIndex);
                    }
                }
            }
            }
        }
        $scope.extra_invoice_notes =[];

        $scope.getInvoiceNotes = function(){
            data= {};
            data.inv_no = $scope.invoice.INV_NO;
            commonFactory.getWithParams('/api/extra-job-note', data).success(function (res) {
                if (res.error) {
                   Notification.error(res.error);
                }
                else {
                    $scope.extra_invoice_notes = res.result.notes;
                }
            }).error(function (err) {
                if (err.error !== "token_not_provided") {
                    console.log(err);
                }
            });
        }

        $scope.deleteNote = function(id){

            swal({
                    title: "Are you sure ?",
                    text: "You are going to delete the note.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#09375f",
                    confirmButtonText: "Yes, Do it",
                    cancelButtonText: "No!",
                    closeOnConfirm: true,
                    closeOnCancel: true
                },
                function (isConfirm) {
                    if (isConfirm) {
                        $rootScope.showLoader(true);
                        commonFactory.delete('/api/extra-job-note/'+id)
                            .success(function(response) {
                                if (response && response.result) {
                                    Notification.success(response.result.message || 'Note deleted successfully.');
                                    $scope.getInvoiceNotes();
                                    $rootScope.showLoader(false);
                                }else{
                                    Notification.error(response.result.message || 'Something went wrong!');
                                    $rootScope.showLoader(false);
                                }
                            })
                            .error(function(err) {
                                $rootScope.showLoader(false);
                            });
                    }
                });
        };


        $scope.addExtraNote =function(id=null){
            var invoiceModal = $uibModal.open({
                templateUrl: "modules/diary/addcase/add_extra_notes_modal.html",
                controller: 'addExtraNotesController',
                resolve: {
                    JOB_NO: function () {
                        return null;
                    },
                    id: function(){
                        return id
                    },
                    inv_no: function(){
                        return $scope.invoice.INV_NO;
                    }
                },
                windowClass: '',
                keyboard: false,
                backdrop: false,
                size: 'lg',
                close: function () {

                }
            });

            invoiceModal.result.then(function (cbData) {
            }, function (cbData) {

                if(cbData.isChanged){
                    $scope.getInvoiceNotes();
                    $rootScope.showLoader(false);
                }

            });
        }

    }]);
