angular.module('deitz')
    .factory('diaryFactory', ['$http', 'apiUrl', function($http, apiUrl) {

        var diaryFactory = {};

        var vh = validation_helper();

        diaryFactory.get = function(url) {
            return $http.get(apiUrl + url);
        };

        diaryFactory.post = function(url, data) {
            return $http.post(apiUrl + url, data);
        };

        diaryFactory.put = function(url, data) {
            return $http.put(apiUrl + url, data);
        };

        diaryFactory.validations = {
            attorney_id: {
                required: true
            },
            selectedValue: {
                required: true,
            },
            searchValue: {
                maxlength: 254,
                required: true,
            },
            FirstName: {
                maxlength: 254,
                required: true
            },
            LastName: {
                maxlength: 254,
                required: true
            },
            WitnessType: {
                required: true
            },
            WitnessResponse: {
                required: true
            },
            job_type_id: {
                required: true
            },
            job_category_id: {
                required: true
            },
            case_type_id: {
                required: true
            },
            TIME_TAKEN: {
                required: true
            },
            TIME_ST: {
                required: false
            },
            TIME_END: {
                required: false
            },
            translator_id: {
                required: true
            },
            witness_type_id: {
                required: true
            },
            ebt_contact_id: {
                required: false
            },
            turn_around_info: {
                required: true
            },
            IndexNumber: {
                required: false
            },
            CAPTION: {
                required: true
            },
            DATE_TAKEN: {
                required: true
            },
            HELD_AT1:{
                required: true
            },
            HELD_AT2:{
                required: true
            },
            HELD_AT3:{
                required: true
            },
            held_at_flag: {
                required: true
            },
            location_action: {
                required: true
            }, 
            business_unit_id: {
                required: true
            },
            location_id: {
                required: true
            },
            job_called_in_by: {
                required: true
            },
            send_job_confirmation_on: {
                required: true
            },
            email: {
                patterns: vh.email.patterns,
                maxlength: vh.email.maxlength,
                required: vh.email.required,
            },
            zipcode: {
                required: vh.zipcode.required,
                patterns : vh.zipcode.patterns,
                patternRestrict : vh.zipcode.patternRestrict,
                maxlength: vh.zipcode.maxlength,
                minlength: vh.zipcode.minlength
            },
        };

        diaryFactory.messages = {
            attorney_id: {
                required: "Scheduling Firm is required.",
            },
            selectedValue: {
                required: "Select option to find a case.",
            },
            searchValue: {
                required: "Value is required.",
                maxlength: "Value Field not be upto 254 characters."
            },
            FirstName: {
                maxlength: "Firstname Field not be upto 254 characters.",
                required: "Firstname is required."
            },
            LastName: {
                maxlength: "Lastname Field not be upto 254 characters.",
                required: "Lastname is required."
            },
            WitnessType: {
                required: "Witnesstype is required.",
            },
            WitnessResponse: {
                required: "Witness response is required.",
            },
            job_type_id: {
                required: "Jobtype is required."
            },
            job_category_id: {
                required: "Jobcategory is required."
            },
            case_type_id: {
                required: "Casetype is required."
            },
            witness_type_id: {
                required: "Plaintiff/Defendant is required."
            },
            TIME_TAKEN: {
                required: "Time is required."
            },
            TIME_ST: {
                required: "Time start is required."
            },
            TIME_END: {
                required: "Time end is required."
            },
            translator_id: {
                required: "Translator is required."
            },
            translator_time: {
                required: "Translator time is required."
            },
            ebt_contact_id: {
                required: "EBT clerk is required."
            },
            turn_around_info: {
                required: "Turn around info is required."
            },
            IndexNumber: {
                required: "IndexNumber is required."
            },
            CAPTION: {
                required: "Caption is required."
            },
            DATE_TAKEN: {
                required: "Date is required."
            },
            HELD_AT1:{
                required: "Job Location is required."
            },
            HELD_AT2:{
                required: "Job Location Address 1 is required."
            },
            HELD_AT3:{
                required: "Job Location Address 2 is required."
            },
            held_at_flag:{
                required: "Connection method is required."
            },
            location_action:{
                required : "Action is required."
            },
            location_id: {
                required: "Venue is required."
            },
            business_unit_id: {
                required: "Business Unit is required."
            },
            job_called_in_by: {
                required: "Schedule By is required."
            },
            send_job_confirmation_on: {
                required: "Send Job Confirmation To is required."
            },
            email: {
                patterns: "Please enter valid email.",
                required: "Email is required.",
                maxlength: "Email Field not be upto 254 characters."
            },
            zipcode: {
                maxlength: "Zipcode not be upto 10 characters.",
                required: "Zipcode is required.",
                patterns : "Zipcode is invalid"
            },
        };
        
        diaryFactory.sharedData = [];

        //browse all witness for diary
        diaryFactory.getwitnessinfo = function(url, params) {
            return $http({ "url": apiUrl + url + params, "method": "GET" });
        };

        diaryFactory.searchFields = [
            { key : "LAST_JOB", value: "Job Number"},
            { key : "legacy_job_no", value: "Legacy Job Number"},
            { key : "INV_NO", value: "Invoice Number"},
            { key : "FILE_NO", value: "File Number" },
            { key : "IndexNumber", value: "Court Assigned / Index Number" },
            { key : "ClaimNumber", value: "Claim Number" },
            { key : "CAPTION", value: "Case Name"},
            { key : "venue", value: "Venue"},
            { key : "INV_DT", value: "Invoice Date"},
            { key : "CASE_tbl.attorney_id", value: "Attorney Firm"},
            { key : "attname_id", value: "Attorney"},
            { key : "witness_name", value: "Witness Name"},
            { key : "created_at", value: "Job Date Scheduled"},
            { key : "all_open_jobs", value: "All Open Jobs"},
        ];

        return diaryFactory;
    }]);
